import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { graphql } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import 'react-quill/dist/quill.snow.css';
import { registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import { TextField, FormControlLabel, Switch } from '@material-ui/core';
import { CenteredPaper, PaperTitle, SubmitButton, Notification } from 'shared';
import DateFormattedInput from 'shared/FormattedInput';
import { GET_GROUP_UPDATES, CREATE_EVENT } from 'queries';

registerPlugin(FilePondPluginImagePreview);

const styles = () => ({
  root: {
    flexGrow: 1,
    paddingTop: '14px',
  },
  textField: {
    padding: 0,
    display: 'flex',
    maxWidth: 540,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
});

class CreateEvent extends Component {
  state = {
    description: '',
    postcode: '',
    address: '',
    date: '',
    endDate: '',
    time: '',
    endTime: '',
    title: '',
    sending: false,
    noAuthor: true,
  };

  submitHandler = event => {
    event.preventDefault();
    const { match, mutate, history } = this.props;
    const { horseId, groupId, size } = match.params;
    mutate({
      variables: {
        ...this.state,
        horseId,
      },
      refetchQueries: [
        {
          query: GET_GROUP_UPDATES,
          variables: { id: groupId, size: parseInt(size, 10) },
        },
      ],
    })
      .then(() => history.push(`/groups/${groupId}/view/updates`))
      .catch(() => {
        this.setState({
          error: localStorage.getItem('error'),
          sending: false,
        });
      })
      .then(setTimeout(() => this.setState({ error: null })), 2000);
  };

  handleChange = input => e => {
    this.setState({ [input]: e });
  };

  handleChangeText = input => e => {
    this.setState({ [input]: e.target.value });
  };

  handleSwitch = input => e => {
    this.setState({ [input]: e.target.checked });
  };

  render() {
    const { classes } = this.props;
    const {
      error,
      description,
      postcode,
      address,
      date,
      endDate,
      time,
      endTime,
      title,
      noAuthor,
    } = this.state;
    return (
      <form onSubmit={this.submitHandler}>
        <CenteredPaper>
          <PaperTitle>Create Event</PaperTitle>
          <Grid container className={classes.root} spacing={24}>
            <Grid item xs={12}>
              <TextField
                label="Title"
                value={title}
                onChange={this.handleChangeText('title')}
                className={classes.textField}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Description"
                multiline
                value={description}
                onChange={this.handleChangeText('description')}
                className={classes.textField}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <ReactQuill
                value={description}
                onChange={this.handleChange("description")}
                placeholder="Description"
              />
            </Grid> */}
            <Grid item xs={12}>
              <TextField
                label="Address"
                value={address}
                onChange={this.handleChangeText('address')}
                className={classes.textField}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Post code"
                value={postcode}
                onChange={this.handleChangeText('postcode')}
                className={classes.textField}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Start Date (DD/MM/YYYY)"
                value={date}
                onChange={this.handleChangeText('date')}
                className={classes.textField}
                InputProps={{
                  inputComponent: DateFormattedInput,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="End Date (DD/MM/YYYY)"
                value={endDate}
                onChange={this.handleChangeText('endDate')}
                className={classes.textField}
                InputProps={{
                  inputComponent: DateFormattedInput,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Time HH:MM"
                name="time"
                value={time}
                onChange={this.handleChangeText('time')}
                className={classes.textField}
                InputProps={{
                  inputComponent: DateFormattedInput,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="End time HH:MM"
                name="time"
                value={endTime}
                onChange={this.handleChangeText('endTime')}
                className={classes.textField}
                InputProps={{
                  inputComponent: DateFormattedInput,
                }}
              />
            </Grid>
            <FormControlLabel
              style={{ marginLeft: 0 }}
              control={
                <Switch
                  value="noAuthor"
                  checked={noAuthor}
                  onChange={this.handleSwitch('noAuthor')}
                />
              }
              label="Send from the TRM account"
            />
            <Grid item xs={12} style={{ margin: '-25px -8px' }}>
              <SubmitButton>CREATE</SubmitButton>
            </Grid>
          </Grid>
        </CenteredPaper>
        {error ? <Notification message={error} variant="error" /> : null}
      </form>
    );
  }
}

CreateEvent.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  classes: PropTypes.object,
  mutate: PropTypes.func,
};

export default graphql(CREATE_EVENT)(withRouter(withStyles(styles)(CreateEvent)));
