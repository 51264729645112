import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { graphql } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';
import { ADD_ORG_MANAGER, GET_USERS_SELECTOR } from 'queries';
import { Notification, CenteredPaper, PaperTitle, Loader, Autosuggest, SubmitButton } from 'shared';
import queryString from 'query-string';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    display: 'flex',
    width: 540,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  formControl: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: theme.spacing(1),
    minWidth: 225,
  },
  button: {
    display: 'flex',
    margin: '10px auto',
    color: '#fff',
    width: '100%',
    maxWidth: '300px',
    background: '#f18a9b',
    borderColor: '#f18a9b',
    '&:hover': {
      background: '#fff',
      color: '#142339',
      borderColor: '#142339',
    },
  },
});

class Create extends Component {
  state = {
    userId: '',
  };

  submitHander = toNext => {
    const { match, mutate, history, location } = this.props;
    const { id } = match.params;
    const { userId } = this.state;
    mutate({
      variables: {
        userId,
        orgId: id,
      },
    })
      .then(() => {
        if (toNext) {
          history.push(`/organizations/${id}/view/permissions?flow=true`);
        } else {
          history.push(`/organizations/${id}/view/managers`);
        }
      })
      .catch(() => {
        this.setState({ error: localStorage.getItem('error') });
      })
      .then(setTimeout(() => this.setState({ error: null })), 2000);
  };

  submitAndReset = () => {
    const { match, mutate, history } = this.props;
    const { id } = match.params;
    const { userId } = this.state;
    mutate({
      variables: {
        userId,
        orgId: id,
      },
    })
      .then(() => {
        this.setState({
          userId: '',
        });
      })
      .catch(() => {
        this.setState({ error: localStorage.getItem('error') });
      })
      .then(setTimeout(() => this.setState({ error: null })), 2000);
  };

  handleChangeAutosuggest = input => e => {
    this.setState({ [input]: e ? e.value : '' });
  };

  handleSwitch = input => e => {
    this.setState({ [input]: e.target.checked });
  };

  render() {
    const { classes, data, location } = this.props;
    const { loading, error } = data;
    const { error: stateError } = this.state;

    if (loading) return <Loader />;
    if (error) return <Notification message={error.message} variant="error" />;
    const userOptions = data.getAllUsers.map(item => ({
      value: item.id,
      label: item.email,
    }));
    const parsedLocation = queryString.parse(location.search);
    const isFlow = !!parsedLocation.flow;
    return (
      <form>
        <CenteredPaper>
          <PaperTitle>Add Manager to Organisation</PaperTitle>
          <Grid container className={classes.root}>
            <br />
            <Grid item xs={12} sm={12}>
              <Autosuggest
                options={userOptions}
                handleChange={this.handleChangeAutosuggest('userId')}
                placeholder="Search a user..."
              />
            </Grid>
          </Grid>
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={() => this.submitHander(isFlow)}
          >Add Manager and continue</Button>
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={() => this.submitAndReset()}
          >Add another Organisation Manager</Button>
        </CenteredPaper>
        {stateError ? <Notification message={stateError} variant="error" /> : null}
      </form>
    );
  }
}

Create.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  classes: PropTypes.object,
  data: PropTypes.object,
  mutate: PropTypes.func,
};

export const AddManager = graphql(ADD_ORG_MANAGER)(
  graphql(GET_USERS_SELECTOR)(withRouter(withStyles(styles)(Create))),
);
