export default () => ({
  info: {
    zIndex: -3,
    marginTop: 20,
    marginBottom: 20,
    paddingBottom: 10,
    margin: 35,
    fontSize: '16px',
  },
});
