import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import HomePage from 'HomePage/index';
import Login from 'LoginPage';
import CustomAppBar from 'Layout/AppBar';
import GroupView from 'Group/GroupView';
import AddHorse from 'Group/GroupView/GroupHorses/AddHorse';
import AddMember from 'Group/GroupView/GroupMembers/AddMember';
import BulkUpload from 'Group/GroupView/GroupMembers/BulkUpload';
import CreateEvent from 'Group/GroupView/GroupUpdates/CreateEvent';
import CreatePoll from 'Group/GroupView/GroupUpdates/CreatePoll';
import CreateUpdate from 'Group/GroupView/GroupUpdates/CreateUpdate';
import EditComment from 'Group/GroupView/GroupUpdates/EditComment';
import GroupList from 'Group/GroupList';
import GroupCreate from 'Group/GroupCreate';
import GroupEdit from 'Group/GroupEdit';
import HorseList from 'Horse/HorseList';
import HorseEdit from 'Horse/HorseEdit';
import HorseView from 'Horse/HorseView';
import Users from 'User';
import UserCreate from 'User/UserCreate';
import UserEdit from 'User/UserEdit';
import UserNotifications from 'User/UserNotifications';
import UserView from 'User/UserView';
import HorseHistory from 'HorseHistory';
import DeletedHorsesList from 'DeletedHorses';
import { AllNotes } from 'AllNotes';
import Performance from 'Performance';
import {
  AddGroup,
  AddManager,
  CreateOrganizationUpdate,
  OrganizationCreate,
  OrganizationEdit,
  OrganizationList,
  OrganizationView,
} from '../models';
import Accounting from 'Accounting';

function router() {
  const token = localStorage.getItem('token');
  return (
    <>
      {!token && <Redirect to="/login" />}
      <Switch>
        <Route exact path="/login" component={Login} />
        <CustomAppBar>
          <Route exact path="/" component={HomePage} />
          <Route path="/users" component={Users} />
          <Route exact path="/users/create" component={UserCreate} />
          <Route path="/users/:id/view/" component={UserView} />
          <Route exact path="/users/:id/edit" component={UserEdit} />
          <Route exact path="/users/:id/edit/notifications" component={UserNotifications} />

          <Route exact path="/groups" component={GroupList} />
          <Route exact path="/groups/create" component={GroupCreate} />
          <Route path="/groups/:id/view/" component={GroupView} />
          <Route exact path="/groups/:id/edit" component={GroupEdit} />
          <Route exact path="/groups/:id/addHorse" component={AddHorse} />
          <Route exact path="/groups/:id/addMember" component={AddMember} />
          <Route path="/groups/:id/bulk-upload" component={BulkUpload} />

          <Route exact path="/edit/:type/:groupId/:id" component={EditComment} />
          <Route exact path="/create-update/:horseId/:groupId/:size" component={CreateUpdate} />
          <Route exact path="/create-poll/:horseId/:groupId/:size" component={CreatePoll} />
          <Route exact path="/create-event/:horseId/:groupId/:size" component={CreateEvent} />

          <Route exact path="/horses" component={HorseList} />
          <Route exact path="/horses/:id/edit" component={HorseEdit} />
          <Route path="/horses/:id/view/" component={HorseView} />

          <Route exact path="/organizations" component={OrganizationList} />
          <Route path="/organizations/:id/view/" component={OrganizationView} />
          <Route exact path="/organizations/create" component={OrganizationCreate} />
          <Route exact path="/organizations/:id/edit" component={OrganizationEdit} />
          <Route exact path="/organizations/:id/addGroup" component={AddGroup} />
          <Route exact path="/organizations/:id/addManager" component={AddManager} />
          <Route
            exact
            path="/create-organization-update/:organizationId/:size"
            component={CreateOrganizationUpdate}
          />

          <Route path="/horse-history" component={HorseHistory} />

          <Route path="/deleted-horses" component={DeletedHorsesList} />

          <Route path="/notes" component={AllNotes} />

          <Route path="/performance" component={Performance} />
          <Route path="/accounting" component={Accounting} />
        </CustomAppBar>
      </Switch>
    </>
  );
}

export default router;
