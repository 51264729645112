import React, { Component } from 'react';

import { CREATE_USER, GET_ALL_USERS } from 'queries';

import { withRouter } from 'react-router-dom';
import { graphql } from 'react-apollo';

import { withStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';

import { Notification, SubmitButton, CenteredPaper, PaperTitle } from 'shared';
import { FormControlLabel, Switch } from '@material-ui/core';

const styles = theme => ({
  paper: {
    padding: 30,
    paddingTop: 5,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    display: 'flex',
    width: 530,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  button: {
    marginTop: 30,
    margin: theme.spacing(1),
    display: 'flex',
  },
});

class UserCreate extends Component {
  state = {
    firstName: '',
    surname: '',
    email: '',
    userName: '',
    phoneNumber: '',
    password: '',
    isContentManager: false,
  };

  submitHander = event => {
    event.preventDefault();
    this.props
      .mutate({
        variables: {
          ...this.state,
        },
        refetchQueries: [{ query: GET_ALL_USERS }],
      })
      .then(() => this.props.history.push('/users'))
      .catch(res => {
        this.setState({ error: localStorage.getItem('error') });
      })
      .then(setTimeout(() => this.setState({ error: null })), 2000);
  };

  handleSwitch = input => e => {
    this.setState({ [input]: e.target.checked });
  };

  render() {
    const { error, firstName, surname, email, userName, password, isContentManager } = this.state;
    const { classes } = this.props;
    return (
      <>
        <CenteredPaper>
          <PaperTitle>Create User</PaperTitle>
          <form onSubmit={this.submitHander}>
            <TextField
              required
              label="First Name"
              value={firstName}
              onChange={event => this.setState({ firstName: event.target.value })}
              className={classes.textField}
              margin="normal"
            />
            <TextField
              required
              label="Last Name"
              value={surname}
              onChange={event => this.setState({ surname: event.target.value })}
              className={classes.textField}
              margin="normal"
            />
            <TextField
              required
              label="Email"
              value={email}
              onChange={event => this.setState({ email: event.target.value })}
              className={classes.textField}
              margin="normal"
            />
            <TextField
              required
              label="User Name"
              value={userName}
              onChange={event => this.setState({ userName: event.target.value })}
              className={classes.textField}
              margin="normal"
            />
            <TextField
              required
              type="password"
              label="Password"
              value={password}
              onChange={event => this.setState({ password: event.target.value })}
              className={classes.textField}
              margin="normal"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={isContentManager}
                  value="isContentManager"
                  onChange={this.handleSwitch('isContentManager')}
                />
              }
              label="Is Content Manager"
            />
            <SubmitButton>Create User</SubmitButton>
          </form>
        </CenteredPaper>
        {error ? <Notification message={error} variant="error" /> : null}
      </>
    );
  }
}

export default graphql(CREATE_USER)(withRouter(withStyles(styles)(UserCreate)));
