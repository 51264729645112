import { GET_HORSE_TIMEFORM_RANKS } from 'queries';
import React from 'react';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { Notification, RankCards, TableLoader } from 'shared';

function HorseRanks(props) {
  const { loading, error } = props.data;
  if (loading) return <TableLoader />;
  if (error) return <Notification message={error.message} variant="error" />;
  const { ranks } = props.data.getHorseById;
  return <RankCards data={ranks} />;
}

export default graphql(GET_HORSE_TIMEFORM_RANKS, {
  options: props => {
    return {
      variables: { id: props.id },
    };
  },
})(withRouter(HorseRanks));
