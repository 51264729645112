import { Typography } from '@material-ui/core';
import MaterialTable from 'material-table';

import { GET_HORSE_TIMEFORM_FUTURE_RACES_DETAILS } from 'queries';
import React from 'react';
import { Query } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { Notification, TableLoader } from 'shared';

function HorseFutureRaceDetails(props) {
  const { id, course, date, race } = props.match.params;
  return (
    <Query
      query={GET_HORSE_TIMEFORM_FUTURE_RACES_DETAILS}
      variables={{
        id,
        courseId: parseInt(course),
        meetingDate: date,
        raceNumber: parseInt(race),
      }}
    >
      {({ loading, error, data }) => {
        if (loading) return <TableLoader />;
        if (error) return <Notification message={error.message} variant="error" />;
        const { futureRaceDetails } = data.getHorseById;

        return (
          <MaterialTable
            options={{
              columnsButton: true,
              pageSize: 10,
              actionsColumnIndex: -1,
              showEmptyDataSourceMessage: true,
              exportButton: true,
            }}
            columns={[
              {
                title: 'Horse name',
                field: 'horseName',
                cellStyle: data => {
                  if (data === props.name) {
                    return {
                      color: 'green',
                      fontWeight: 'bold',
                    };
                  }
                },
              },
              {
                title: 'Jockey Name',
                field: 'jockeyName',
              },
              {
                title: 'Trainer Name',
                field: 'trainerName',
              },
              {
                title: 'Rating',
                field: 'tfr',
              },
              {
                title: 'Entry Number',
                field: 'entryNumber',
              },
              {
                title: 'Age',
                field: 'horseAge',
              },
              {
                title: 'Weight carried',
                field: 'wgt',
              },
              {
                title: 'Draw',
                field: 'draw',
              },
              {
                title: 'Equipment',
                field: 'eq',
              },
            ]}
            detailPanel={rowData => {
              return (
                <>
                  <Typography variant="subtitle2" style={{ margin: '10px 45px', width: 800 }}>
                    <b>Production Comment:</b> <br /> {rowData.productionComment}
                  </Typography>
                  <Typography variant="subtitle2" style={{ margin: '10px 45px', width: 800 }}>
                    <b>Analysts Comment:</b> <br /> {rowData.analystsComment}
                  </Typography>
                </>
              );
            }}
            data={futureRaceDetails}
            title="Future Race Details"
          />
        );
      }}
    </Query>
  );
}

export default withRouter(HorseFutureRaceDetails);
