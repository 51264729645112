import React from 'react';
import * as PropTypes from 'prop-types';
import { useQuery, useMutation } from 'react-apollo';
import MaterialTable from 'material-table';
import { TextField } from '@material-ui/core';
import {
  GET_ORGANIZATION_NOTES,
  ADD_ORGANIZATION_NOTE,
  REMOVE_NOTE,
  EDIT_NOTE,
  GET_ALL_NOTES,
} from 'queries';

const NotesTab = ({ id }) => {
  const [tableData, setTableData] = React.useState([]);
  const [edit] = useMutation(EDIT_NOTE);
  const [add] = useMutation(ADD_ORGANIZATION_NOTE, {
    refetchQueries: [
      { query: GET_ORGANIZATION_NOTES, variables: { orgId: id } },
      { query: GET_ALL_NOTES },
    ],
  });
  const [remove] = useMutation(REMOVE_NOTE, {
    refetchQueries: [
      { query: GET_ORGANIZATION_NOTES, variables: { orgId: id } },
      { query: GET_ALL_NOTES },
    ],
  });
  const { data } = useQuery(GET_ORGANIZATION_NOTES, { variables: { orgId: id } });

  React.useEffect(() => {
    if (data && data.getOrganizationById && data.getOrganizationById.notes) {
      const dataToSet = data.getOrganizationById.notes.map(note => {
        return {
          ...note,
          createdBy: note.createdBy ? `${note.createdBy.firstName} ${note.createdBy.surname}` : '?',
        };
      });
      setTableData(dataToSet);
    }
  }, [data]);

  return (
    <MaterialTable
      columns={[
        {
          title: 'Created at',
          field: 'createdAt',
          editable: 'never',
        },
        {
          title: 'Updated at',
          field: 'updatedAt',
          editable: 'never',
        },
        {
          title: 'Author',
          field: 'createdBy',
          editable: 'never',
        },
        {
          title: 'Comment',
          field: 'text',
          editComponent: editProps => (
            <TextField
              onChange={e => {
                editProps.onChange(e.target.value);
              }}
              value={editProps.value}
              multiline
              placeholder="Comment"
            />
          ),
          cellStyle: { maxWidth: 600 },
        },
      ]}
      editable={{
        onRowUpdate: (newData, oldData) =>
          edit({
            variables: {
              noteId: oldData.id,
              text: newData.text,
            },
          }),
        onRowDelete: oldData =>
          remove({
            variables: { noteId: oldData.id },
          }),
        onRowAdd: newData => add({ variables: { id, text: newData.text } }),
      }}
      options={{
        paging: false,
        loadingType: 'linear',
        header: true,
        actionsColumnIndex: -1,
        exportButton: true,
        exportAllData: true,
        headerStyle: {
          color: 'black',
        },
      }}
      title="Notes"
      data={tableData}
    />
  );
};

NotesTab.propTypes = {
  id: PropTypes.string,
};

export default NotesTab;
