import React from 'react';
import { Query } from 'react-apollo';
import MaterialTable from 'material-table';
import { GET_HORSE_MEMBERS } from 'queries';

const HorseMembers = ({ id }) => {
  return (
    <Query query={GET_HORSE_MEMBERS} variables={{ id }}>
      {({ data }) => {
        const newData = data && data.getHorseById ? data.getHorseById : {};
        const horseMembers = newData.members || [];
        const horsePendingMembers = newData.pendingMembers || [];
        const tableData = [];
        horseMembers.forEach(member => {
          tableData.push({
            id: member.userGroupId,
            email: member.userGroup.user.email,
            firstName: member.userGroup.user.firstName,
            surname: member.userGroup.user.surname,
            role: member.role,
            shares: member.shares,
          });
        });
        horsePendingMembers.forEach(invHorse => {
          tableData.push({
            id: invHorse.invitation.id,
            email: invHorse.invitation.email,
            firstName: invHorse.invitation.firstName,
            surname: invHorse.invitation.surname,
            role: `PENDING (${invHorse.role})`,
            shares: invHorse.shares,
          });
        });
        return (
          <MaterialTable
            columns={[
              {
                title: 'User Group Id',
                field: 'id',
                grouping: false,
              },
              {
                title: 'User Email',
                field: 'email',
                grouping: false,
              },
              {
                title: 'User First Name',
                field: 'firstName',
                grouping: false,
              },
              {
                title: 'User Surname',
                field: 'surname',
                grouping: false,
              },
              {
                title: 'Role',
                field: 'role',
                cellStyle: cellData => {
                  if (cellData === 'manager' || cellData === 'org-manager') {
                    return {
                      fontWeight: 'bold',
                      textTransform: 'capitalize',
                    };
                  }
                  return {
                    textTransform: 'capitalize',
                  };
                },
              },
              {
                title: 'Shares',
                field: 'shares',
              },
            ]}
            data={tableData}
            options={{
              columnsButton: true,
              pageSize: 10,
              actionsColumnIndex: -1,
              showEmptyDataSourceMessage: true,
              grouping: true,
              exportButton: true,
              exportAllData: true,
            }}
            title="Members"
          />
        );
      }}
    </Query>
  );
};

export default HorseMembers;
