import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import {
  GET_ALL_GROUPS,
  UPDATE_GROUP,
  GET_GROUP,
  DELETE_GROUP,
  GET_ORGANIZATIONS_SELECTOR,
} from 'queries';
import {
  Loader,
  Notification,
  SubmitButton,
  CenteredPaper,
  PaperTitle,
  ConfirmationDialog,
  Autosuggest,
} from 'shared';
import styles from './styles';

class GroupEdit extends Component {
  state = {
    name: '',
    bhaApproved: false,
    existing: false,
    type: '',
    period: '',
    address1: '',
    address2: '',
    country: '',
    district: '',
    town: '',
    postCode: '',
    membershipPayedByUser: false,
    maxOwners: '',
    owners: '',
    managers: '',
    trainers: '',
    guests: '',
    trial: false,
    horsesCount: 0
  };

  submitHandler = event => {
    const { id } = this.props.match.params;

    event.preventDefault();
    this.props
      .mutate({
        variables: {
          ...this.state,
          id,
          maxOwners: parseInt(this.state.maxOwners),
          owners: parseInt(this.state.owners),
          managers: parseInt(this.state.managers),
          trainers: parseInt(this.state.trainers),
          guests: parseInt(this.state.guests),
          horsesCount: parseInt(this.state.horsesCount)
        },
        refetchQueries: [{ query: GET_ALL_GROUPS }, { query: GET_GROUP, variables: { id } }],
      })
      // .then(this.props.data.refetch())
      .then(() => this.props.history.push('/groups'))
      .catch(res => {
        this.setState({ error: localStorage.getItem('error') });
      })
      .then(setTimeout(() => this.setState({ error: null })), 2000);
  };

  handleChange = input => e => {
    this.setState({ [input]: e.target.value });
  };

  handleSwitch = input => e => {
    this.setState({ [input]: e.target.checked });
  };

  componentDidMount() {
    if (this.props.data.getGroupById) {
      const { organization } = this.props.data.getGroupById;
      this.setState({
        ...this.props.data.getGroupById,
        organizationId: organization.id,
        selectedOption: {
          value: organization.id,
          label: organization.name,
        },
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data.getGroupById !== this.props.data.getGroupById) {
      const { organization } = this.props.data.getGroupById;

      this.setState({
        ...this.props.data.getGroupById,
        organizationId: organization.id,
        selectedOption: { value: organization.id, label: organization.name },
      });
    }
  }

  handleDelete = () => {
    this.setState({ open: false });
  };

  handleChangeAutosuggest = input => e => {
    this.setState({ [input]: e ? e.value : '', selectedOption: e });
  };

  render() {
    const { classes, first } = this.props;
    const { loading, error } = this.props.data;
    if (loading) return <Loader />;
    if (error) return <Notification message={error.message} variant="error" />;
    const {
      name,
      bhaApproved,
      existing,
      type,
      period,
      address1,
      address2,
      country,
      district,
      town,
      organizationId,
      postCode,
      membershipPayedByUser,
      maxOwners,
      owners,
      managers,
      trainers,
      guests,
      selectedOption,
      trial,
      horsesCount
    } = this.state;

    const organizationOptions = first.getAllOrganizations
      ? first.getAllOrganizations.map(item => ({
          value: item.id,
          label: item.name,
        }))
      : [];
    return (
      <form onSubmit={this.submitHandler}>
        <CenteredPaper>
          <PaperTitle>{`Edit Group: ${name}`}</PaperTitle>
          <Grid container className={classes.root} spacing={24}>
            <Grid item xs={12} sm={12}>
              <Autosuggest
                selectedOption={selectedOption}
                options={organizationOptions}
                handleChange={this.handleChangeAutosuggest('organizationId')}
                placeholder="Search an organization...*"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="standard-select-period"
                select
                label="Period"
                value={period}
                className={classes.textField}
                onChange={this.handleChange('period')}
                margin="none"
              >
                <MenuItem value="Open Ended">Open Ended</MenuItem>
                <MenuItem value="Fixed Period">Fixed Period</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="standard-select-type"
                select
                label="Type"
                value={type}
                className={classes.textField}
                onChange={this.handleChange('type')}
                margin="none"
              >
                <MenuItem value="Syndicate">Syndicate</MenuItem>
                <MenuItem value="Partnership">Partnership</MenuItem>
                <MenuItem value="Sole owner">Sole owner</MenuItem>
                <MenuItem value="Company">Company</MenuItem>
                <MenuItem value="Racing club">Racing club</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                label="Name"
                value={name}
                onChange={this.handleChange('name')}
                className={classes.textField}
                margin="none"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                label="Address 1"
                value={address1}
                onChange={this.handleChange('address1')}
                className={classes.textField}
                margin="none"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                label="Address 2"
                value={address2}
                onChange={this.handleChange('address2')}
                className={classes.textField}
                margin="none"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                label="Country"
                value={country}
                onChange={this.handleChange('country')}
                className={classes.textField}
                margin="none"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                label="District"
                value={district}
                onChange={this.handleChange('district')}
                className={classes.textField}
                margin="none"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                label="Town"
                value={town}
                onChange={this.handleChange('town')}
                className={classes.textField}
                margin="none"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                label="Post Code"
                value={postCode}
                onChange={this.handleChange('postCode')}
                className={classes.textField}
                margin="none"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                type="number"
                label="Max Owners"
                value={maxOwners}
                onChange={this.handleChange('maxOwners')}
                className={classes.textField}
                margin="none"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                type="number"
                label="Owners"
                value={owners}
                onChange={this.handleChange('owners')}
                className={classes.textField}
                margin="none"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                type="number"
                label="Managers"
                value={managers}
                onChange={this.handleChange('managers')}
                className={classes.textField}
                margin="none"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                type="number"
                label="Trainers"
                value={trainers}
                onChange={this.handleChange('trainers')}
                className={classes.textField}
                margin="none"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                type="number"
                label="Guests"
                value={guests}
                onChange={this.handleChange('guests')}
                className={classes.textField}
                margin="none"
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Grid container direction="row" justify="space-around" alignItems="center">
                <FormControlLabel
                  control={
                    <Switch
                      checked={bhaApproved}
                      value="bhaApproved"
                      onChange={this.handleSwitch('bhaApproved')}
                    />
                  }
                  label="Approved"
                />
                <FormControlLabel
                  control={
                    <Switch
                      value="existing"
                      checked={existing}
                      onChange={this.handleSwitch('existing')}
                    />
                  }
                  label="Existing"
                />
                <FormControlLabel
                  control={
                    <Switch value="trial" checked={trial} onChange={this.handleSwitch('trial')} />
                  }
                  label="Trial"
                />
                <FormControlLabel
                  control={
                    <Switch
                      value="membershipPayedByUser"
                      checked={membershipPayedByUser}
                      onChange={this.handleSwitch('membershipPayedByUser')}
                    />
                  }
                  label="Membership Paid By User"
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={9}>
              <SubmitButton disabled={!organizationId}>Update Group</SubmitButton>
            </Grid>
            <Grid item xs={12} sm={3}>
              <ConfirmationDialog
                title="Do you really want to delete this group?"
                mutationQuery={DELETE_GROUP}
                refetchQ={GET_ALL_GROUPS}
                disableButton={(horsesCount>0)?true:false}
                id={this.props.match.params.id}
                redirectPath="/groups"
              />
            </Grid>
          </Grid>
        </CenteredPaper>
        {this.state.error ? <Notification message={this.state.error} variant="error" /> : null}
      </form>
    );
  }
}

export default graphql(UPDATE_GROUP)(
  compose(
    graphql(GET_ORGANIZATIONS_SELECTOR, { name: 'first' }),
    graphql(GET_GROUP, {
      options: props => {
        return {
          variables: { id: props.match.params.id },
        };
      },
    }),
  )(withRouter(withStyles(styles)(GroupEdit))),
);
