import React, { Component } from 'react';
import { withStyles, Paper, Card, Grid, Typography } from '@material-ui/core';

const styles = {
  container: {
    paddingTop: 5,
  },
  paper: {
    width: 900,
    minHeight: 400,
    padding: 16,
  },
  card: {
    height: 180,
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    padding: 10,
  },
};

class Cards extends Component {
  render() {
    const { data, classes } = this.props;
    return (
      <Paper className={classes.paper}>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center"
          spacing={16}
          className={classes.container}
        >
          {data.map((card, i) => (
            <Grid key={i} item xs={4}>
              <Card className={classes.card}>
                <Typography variant="subtitle2">Race type:</Typography>
                <Typography variant="h5">{card.raceType}</Typography>
                <hr />
                <Typography variant="body1">Best TimeForm Rating: {card.bestTFR}</Typography>
                <Typography variant="body1">Total runs: {card.runs}</Typography>
                <Typography variant="body1">Total wins: {card.wins}</Typography>
                <Typography variant="body1">Total places: {card.places}</Typography>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Paper>
    );
  }
}

export const RankCards = withStyles(styles)(Cards);
