export default {
  gif: {
    width: "100%",
    animation: "fadeInFromNone 3s ease-out",
  },
  typography: {
    textAlign: "center",
    marginBottom: 16,
  },
  statisticsTypography: {
    maxWidth: 230,
    textAlign: "center",
    color: "white",
    backgroundColor: "#142339",
    borderRadius: 100,
    cursor: "pointer",
    textDecoration: "none",
    lineHeight: "45px",
    display: "block",
    margin: "auto",
    "&:hover": {
      color: "#ec5c74",
    },
  },
  box: {
    maxWidth: 230,
    textAlign: "center",
    color: "black",
    border: "3px solid #142339",
    borderRadius: 100,
    textDecoration: "none",
    lineHeight: "45px",
    display: "block",
    margin: "auto",
    "&:hover": {
      color: "#ec5c74",
    },
  },
  "@keyframes fadeInFromNone": {
    "0%": {
      opacity: 0,
    },
    "100%": {
      opacity: 1,
    },
  },
  statistics: {
    opacity: 1,
    animation: "fadeInFromNone 3s ease-out",
  },
  divider: {
    margin: "10px 0",
    width: "100%",
    animation: "fadeInFromNone 1.5s ease-out",
  },
  createOrgContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  createOrgButton: {
    width: 300,
    marginTop: 30,
    marginBottom: 30,
    display: "flex",
    color: "#fff",
    background: "#f18a9b",
    borderColor: "#f18a9b",
    "&:hover": {
      background: "#fff",
      color: "#142339",
      borderColor: "#142339",
    },
  },
};
