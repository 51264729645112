import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import ReactPlayer from 'react-player';
import ReactAudioPlayer from 'react-audio-player';
import { Message } from 'shared/Message';

function Details(props) {
  const { attachments, message } = props;
  return (
    <>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        style={{ padding: 16 }}
        spacing={16}
      >
        <Grid item xs={12}>
          <Message message={message} />
        </Grid>
        {attachments.map((link, index) => {
          const parse = link.split('.');
          const extension = parse[5];
          const videoExt = ['avi', 'wmv', 'mp4', 'mpg', 'mpeg', 'm4v', 'flv', 'f4v'];
          const imgExt = ['jpeg', 'jpg', 'gif', 'bmp', 'png'];
          const audioExt = ['mp3', 'wav', 'wma', 'm4a', 'x-m4a'];
          if (videoExt.includes(extension)) {
            return (
              <Grid key={index} item xs={6}>
                <ReactPlayer url={link} controls width="100%" height="67%" />
              </Grid>
            );
          }
          if (imgExt.includes(extension)) {
            return (
              <Grid key={index} item xs={4}>
                <img width="100%" src={link} alt={link} />
              </Grid>
            );
          }
          if (audioExt.includes(extension)) {
            return (
              <Grid key={index} item xs={4}>
                <ReactAudioPlayer
                  src={link}
                  controls
                  style={{
                    border: '1px solid black',
                    borderRadius: '100px',
                    width: '100%',
                  }}
                />
              </Grid>
            );
          }
          return <Typography variant="subtitle2">File format is not supported: {link}</Typography>;
        })}
      </Grid>
    </>
  );
}

export const DetailsMultiple = Details;
