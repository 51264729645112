import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import differenceBy from 'lodash.differenceby';
import { graphql, withApollo } from 'react-apollo';
import {
  withStyles,
  Paper,
  Grid,
  MenuItem,
  TextField,
  InputAdornment,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Clear';
import SaveIcon from '@material-ui/icons/CloudUpload';
import AddIcon from '@material-ui/icons/Add';
import {
  GET_GROUP_HORSES_SELECTOR,
  ADD_MEMBERS_BULK,
  GET_GROUP_MEMBERS,
  ADD_MEMBERS_BULK_FAST,
} from 'queries';
import { Autosuggest, Notification, PaperTitle } from 'shared';
import styles from './styles';

class HorsesBulkSelector extends Component {
  state = {
    errors: null,
    horses: [
      {
        horseId: '',
        role: '',
        shares: '',
        maxShares: '0',
        error: '',
        selectedOption: null,
      },
    ],
  };

  //   handleChange = input => e => {
  //     this.setState({ [input]: e.target.value });
  //   };
  //   handleChangeAutosuggest = input => e => {
  //     this.setState({ [input]: e ? e.value : "" });
  //   };

  submitHander = event => {
    const { id } = this.props.match.params;
    const { managerId, users, client } = this.props;
    event.preventDefault();

    const horses = this.state.horses.map(horse => ({
      horseId: horse.horseId,
      shares: horse.role === 'guest' ? 0 : parseFloat(horse.shares) || 0,
      role: horse.role,
    }));

    const members = users.map(member => ({
      email: member.email,
      firstName: member.name.trim(),
      surname: member.surname.trim(),
      phoneNumber: member.phoneNumber,
    }));
    const v = managerId
      ? {
          horses,
          members,
          id,
          managerId,
        }
      : {
          horses,
          members,
          id,
        };
    client
      .mutate({
        mutation: managerId ? ADD_MEMBERS_BULK_FAST : ADD_MEMBERS_BULK,
        variables: v,
        refetchQueries: [{ query: GET_GROUP_MEMBERS, variables: { id } }],
      })
      .then(() => this.props.history.push(`/groups/${id}/view/members`))
      .catch(res => {
        this.setState({ errors: localStorage.getItem('error') });
      })
      .then(setTimeout(() => this.setState({ errors: null })), 2000);
  };

  handleChange = (name, index) => event => {
    const { value } = event.target;
    this.setState(prevState => {
      const newHorses = [...prevState.horses];
      newHorses[index][name] = value;
      return { horses: newHorses };
    });
  };

  handleChangeAutosuggest = (name, index) => event => {
    const value = event ? event.value : '';
    const n = event ? (event.maxShares / this.props.users.length).toString().slice(0, 4) : 0;
    this.setState(prevState => {
      const newHorses = [...prevState.horses];
      newHorses[index][name] = value;
      newHorses[index].maxShares = n;
      newHorses[index].selectedOption = event;
      return { horses: newHorses };
    });
  };

  handleAddHorse = () => {
    this.setState(state => ({
      horses: state.horses.concat({
        horseId: '',
        role: '',
        shares: '',
        maxShares: '0',
        selectedOption: null,
      }),
    }));
  };

  handleRemoveElement = index => {
    this.setState(state => ({
      horses: [...state.horses.slice(0, index), ...state.horses.slice(index + 1)],
    }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.horses.length < this.state.horses.length) {
      const objDiv = document.getElementById('scroll');
      objDiv.scrollTop = objDiv.scrollHeight;
    }
  }

  render() {
    const { classes, data, users } = this.props;
    const { horses } = this.state;
    const { loading, error } = data;
    const allHorseOptions = !loading
      ? data.getGroupById.horses.map(item => ({
          value: item.id,
          horseId: item.id,
          label: `${item.name} - ${item.maxShares}%`,
          maxShares: item.maxShares,
        }))
      : [];
    const horseOptions = differenceBy(allHorseOptions, horses, 'horseId');
    const horsesLimit = allHorseOptions.length === horses.length;
    const noOptions = horseOptions.length === 0;
    if (users.length === 0) return <Redirect to="../bulk-upload" />;
    return (
      <div className={classes.backgroundWrapper}>
        <form onSubmit={this.submitHander}>
          <Paper className={classes.paper}>
            <PaperTitle>
              {`Choose the horses for these ${users.length} members`}
              <Tooltip title="Exit back to the list">
                <IconButton
                  className={classes.button}
                  color="secondary"
                  aria-label="Return to the list"
                  onClick={() => this.props.history.goBack()}
                  style={{ float: 'right', marginTop: -8 }}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Finish upload">
                <IconButton
                  className={classes.button}
                  color="primary"
                  aria-label="Save"
                  type="submit"
                  style={{ float: 'right', marginTop: -8, color: 'white' }}
                >
                  <SaveIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Add another horse to the list">
                <IconButton
                  disabled={noOptions || horsesLimit}
                  className={classes.button}
                  color="primary"
                  aria-label="Add horse"
                  onClick={this.handleAddHorse}
                  style={{ float: 'right', marginTop: -8, color: 'white' }}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </PaperTitle>
            <Grid container className={classes.root} id="scroll">
              {horses.map((horse, index) => {
                const { role, shares, maxShares, selectedOption } = horse;
                return (
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="flex-end"
                    key={index}
                  >
                    <Grid item xs={5} sm={5}>
                      <Autosuggest
                        selectedOption={selectedOption}
                        options={horseOptions}
                        handleChange={this.handleChangeAutosuggest('horseId', index)}
                        placeholder="Search a horse..."
                      />
                    </Grid>
                    <Grid item xs={3} sm={3} style={{ paddingLeft: 10 }}>
                      <TextField
                        id="Role"
                        select
                        label="Role"
                        className={classes.textField}
                        value={role}
                        onChange={this.handleChange('role', index)}
                        SelectProps={{
                          MenuProps: {
                            className: classes.menu,
                          },
                        }}
                      >
                        <MenuItem value="owner">Owner</MenuItem>
                        <MenuItem value="trainer">Trainer</MenuItem>
                        <MenuItem value="guest">Guest</MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={3} sm={3} style={{ paddingLeft: 15 }}>
                      <TextField
                        disabled={role === 'guest'}
                        label="Horse Shares"
                        type="number"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">{maxShares}%</InputAdornment>
                          ),
                        }}
                        placeholder="Maximum shares:"
                        error={parseFloat(shares) > parseFloat(maxShares)}
                        helperText={error ? 'Too big value!' : null}
                        value={shares}
                        onChange={this.handleChange('shares', index)}
                        className={classes.textField}
                      />
                    </Grid>

                    <IconButton
                      className={classes.button}
                      aria-label="Delete"
                      style={{ marginTop: 30, marginLeft: 15 }}
                      onClick={() => this.handleRemoveElement(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                );
              })}
            </Grid>
          </Paper>
          {this.state.errors ? <Notification message={this.state.errors} variant="error" /> : null}
        </form>
      </div>
    );
  }
}

export default graphql(ADD_MEMBERS_BULK)(
  graphql(GET_GROUP_HORSES_SELECTOR, {
    options: props => {
      const { id } = props.match.params;
      return {
        variables: { id },
      };
    },
  })(withApollo(withRouter(withStyles(styles)(HorsesBulkSelector)))),
);
