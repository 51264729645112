import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles(theme => ({
  formControl: {
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: 225,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const Selector = ({ children, label, classes: propClasses }) => {
  const classes = useStyles();
  return (
    <FormControl className={propClasses ? propClasses.formControl : classes.formControl}>
      <InputLabel shrink htmlFor={label}>
        {label}
      </InputLabel>
      {children}
    </FormControl>
  );
};

Selector.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.node,
  label: PropTypes.string,
};

export default Selector;
