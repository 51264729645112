import React, { Component } from 'react';
import { GET_USER } from 'queries';
import { withRouter, Link, Route } from 'react-router-dom';
import { graphql } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import { Loader, Notification } from 'shared';
import { styles } from 'shared/ViewStyles';
import UserInfo from './UserInfo';
import UserHorsesList from './UserHorsesList';
import UserGroupsList from './UserGroupsList';
import UserNotes from './UserNotes';
import UserNotifications from '../UserNotifications';

class UserView extends Component {
  state = {
    value: '',
  };

  componentDidMount() {
    this.setState({
      value: this.props.history.location.pathname,
    });
  }

  componentDidUpdate() {
    if (this.state.value !== this.props.history.location.pathname)
      this.setState({
        value: this.props.history.location.pathname,
      });
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  editHandler = () => {
    this.props.history.push(`users/${this.props.data.getUserById.id}/view`);
  };

  render() {
    const { classes } = this.props;
    const { value } = this.state;
    const { loading, error, getUserById } = this.props.data;
    if (loading) return <Loader />;
    if (error) return <Notification message={error.message} variant="error" />;

    const { id, firstName, surname } = getUserById;
    return (
      <form onSubmit={this.submitHandler} className={classes.root}>
        <Paper className={classes.paper}>
          <Tabs
            value={value}
            onChange={this.handleChange}
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
            centered
          >
            <Tab
              label={`User: ${firstName} ${surname}`}
              disableRipple
              component={Link}
              to="../edit"
              // value={`/groups/${id}/view/info`}
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            <Tab
              label="Info"
              disableRipple
              component={Link}
              to={`/users/${id}/view/info`}
              value={`/users/${id}/view/info`}
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            <Tab
              label="Horses"
              disableRipple
              component={Link}
              to={`/users/${id}/view/horses`}
              value={`/users/${id}/view/horses`}
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            <Tab
              label="Groups"
              disableRipple
              component={Link}
              to={`/users/${id}/view/groups`}
              value={`/users/${id}/view/groups`}
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            <Tab
              label="Notes"
              disableRipple
              component={Link}
              to={`/users/${id}/view/notes`}
              value={`/users/${id}/view/notes`}
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            <Tab
              label="Notifications"
              disableRipple
              component={Link}
              to="../edit/notifications"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
          </Tabs>
          

          <Route exact path="/users/:id/view/info" render={() => <UserInfo data={getUserById} />} />
          <Route
            exact
            path="/users/:id/view/horses"
            render={() => (
              <div className={classes.table}>
                <UserHorsesList id={id} />
              </div>
            )}
          />
          <Route
            exact
            path="/users/:id/view/groups"
            render={() => (
              <div className={classes.table}>
                <UserGroupsList id={id} />
              </div>
            )}
          />
          <Route
            exact
            path="/users/:id/view/notes"
            render={() => (
              <div className={classes.table}>
                <UserNotes id={id} />
              </div>
            )}
          />
          <Route
            exact
            path="/users/:id/view/notifications"
            render={() => (
              <div className={classes.table}>
                <UserNotifications id={id} />
              </div>
            )}
          />
        </Paper>
        {/* {this.state.errors ? (
          <Notification message={this.state.errors} variant={"error"} />
        ) : null} */}
      </form>
    );
  }
}

export default graphql(GET_USER, {
  options: props => {
    return {
      variables: { id: props.match.params.id },
    };
  },
})(withRouter(withStyles(styles)(UserView)));
