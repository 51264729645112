import { Paper } from "@material-ui/core";
import React from "react";

export const Selection = ({
  data,
  setSearchTerm,
  setShowBox,
  showBox,
  setSearchTermValue
}) => {
  const handleItemSelect = value => {
    setShowBox(false);
    setSearchTerm(value.name);
    //Will be used for queries
    setSearchTermValue(value);
  };

  return (
    <>
      {showBox && (
        <Paper
          style={{
            width: 200,
            overflow: "hidden",
            overflowY: "scroll",
            maxHeight: 200, // added scroll
            position: "absolute",
            top: "60px",
            zIndex: 100
          }}
        >
          <ul
            style={{
              listStyleType: "none",
              paddingLeft: "5px",
              fontFamily: "Gill sans"
            }}
          >
            {data.length > 0
              ? data.map(item => (
                  <li
                    key={item.id}
                    style={{
                      marginBottom: "10px",
                      cursor: "pointer",
                      lineHeight: "19px",
                      fontWeight: 400
                    }}
                    onClick={() => handleItemSelect(item)}
                  >
                    {item.name}
                  </li>
                ))
              : "Nothing was found"}
          </ul>
        </Paper>
      )}
    </>
  );
};
