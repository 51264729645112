import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { graphql } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import {
  Loader,
  Notification,
  SubmitButton,
  CenteredPaper,
  PaperTitle,
  ConfirmationDialog,
} from 'shared';
import DateFormattedInput from 'shared/FormattedInput';
import { GET_ALL_USERS, GET_USER, UPDATE_USER, DELETE_USER } from 'queries';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    // display: "flex",
    width: 225,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
});

class UserEdit extends Component {
  state = {
    firstName: '',
    surname: '',
    email: '',
    userName: '',
    phoneNumber: '',
    birthDate: '',
    isContentManager: false,
    address1: '',
    address2: '',
    country: '',
    district: '',
    town: '',
    postCode: '',
    deviceToken: '',
    // marketing: false
  };

  submitHandler = event => {
    const { id } = this.props.match.params;

    event.preventDefault();
    this.props
      .mutate({
        variables: {
          ...this.state,
          id,
          // birthDate: birthDate ? moment(birthDate).format("DD/MM/YYYY") : null
        },
        refetchQueries: [{ query: GET_ALL_USERS }],
      })
      .then(this.props.data.refetch())
      .then(() => this.props.history.push('/users'))
      .catch(res => {
        this.setState({ error: localStorage.getItem('error') });
      })
      .then(setTimeout(() => this.setState({ error: null })), 2000);
  };

  handleChange = input => e => {
    this.setState({ [input]: e.target.value });
  };

  handleSwitch = input => e => {
    this.setState({ [input]: e.target.checked });
  };

  componentDidMount() {
    this.setState(this.props.data.getUserById);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data.getUserById !== this.props.data.getUserById) {
      this.setState(this.props.data.getUserById);
    }
  }

  handleDelete = () => {
    this.setState({ open: false });
  };

  handleCopy = text => {
    // Create the textarea input to hold our text.
    // this.setState({copied: "Token copied to the clipboard"})
    const element = document.createElement('textarea');
    element.value = text;
    // Add it to the document so that it can be focused.
    document.body.appendChild(element);
    // Focus on the element so that it can be copied.
    element.focus();
    element.setSelectionRange(0, element.value.length);
    // Execute the copy command.
    document.execCommand('copy');
    // Remove the element to keep the document clear.
    document.body.removeChild(element);
    this.setState({ copied: 'Device Token copied to the clipboard' });
  };

  render() {
    const { classes } = this.props;
    const { loading, error } = this.props.data;
    if (loading) return <Loader />;
    if (error) return <Notification message={error.message} variant="error" />;
    const {
      firstName,
      surname,
      email,
      userName,
      phoneNumber,
      birthDate,
      address1,
      address2,
      country,
      district,
      town,
      postCode,
      isContentManager,
      deviceToken,
    } = this.state;

    return (
      <form onSubmit={this.submitHandler}>
        <CenteredPaper>
          <PaperTitle>{`Edit User: ${firstName} ${surname}`}</PaperTitle>
          <Grid
            container
            // className={classes.root}
            direction="row"
            justify="space-between"
            alignItems="center"
            spacing={24}
          >
            <Grid item xs={12} sm={6}>
              <TextField
                required
                label="Email"
                value={email}
                onChange={this.handleChange('email')}
                className={classes.textField}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Phone Number"
                value={phoneNumber}
                onChange={this.handleChange('phoneNumber')}
                className={classes.textField}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                label="First Name"
                value={firstName}
                onChange={this.handleChange('firstName')}
                className={classes.textField}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                label="Last Name"
                value={surname}
                onChange={this.handleChange('surname')}
                className={classes.textField}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                label="User Name"
                value={userName}
                onChange={this.handleChange('userName')}
                className={classes.textField}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Birth Date (DD/MM/YYYY)"
                value={birthDate}
                onChange={this.handleChange('birthDate')}
                className={classes.textField}
                InputProps={{
                  inputComponent: DateFormattedInput,
                }}
                margin="normal"
              />
            </Grid> 
            <Grid item xs={12} sm={6}>
              <TextField
                label="Address 1"
                value={address1}
                onChange={this.handleChange('address1')}
                className={classes.textField}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Address 2"
                value={address2}
                onChange={this.handleChange('address2')}
                className={classes.textField}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Country"
                value={country}
                onChange={this.handleChange('country')}
                className={classes.textField}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="District"
                value={district}
                onChange={this.handleChange('district')}
                className={classes.textField}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Town"
                value={town}
                onChange={this.handleChange('town')}
                className={classes.textField}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Post Code"
                value={postCode}
                onChange={this.handleChange('postCode')}
                className={classes.textField}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                disabled
                label="Device Token"
                value={deviceToken}
                onChange={this.handleChange('deviceToken')}
                onClick={() => this.handleCopy(deviceToken)}
                className={classes.textField}
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Switch
                    value="isContentManager"
                    checked={isContentManager}
                    onChange={this.handleSwitch('isContentManager')}
                  />
                }
                label="Is Content Manager"
              />
            </Grid>
            <Grid item xs={12} sm={9}>
              <SubmitButton>Update User</SubmitButton>
            </Grid>
            <Grid item xs={12} sm={3}>
              <ConfirmationDialog
                title="Do you really want to delete this user?"
                mutationQuery={DELETE_USER}
                refetchQ={GET_ALL_USERS}
                id={this.props.match.params.id}
                redirectPath="/users"
              />
            </Grid>
          </Grid>
        </CenteredPaper>
        {this.state.error ? <Notification message={this.state.error} variant="error" /> : null}
        {this.state.copied ? <Notification message={this.state.copied} variant="success" /> : null}
      </form>
    );
  }
}

export default graphql(UPDATE_USER)(
  graphql(GET_USER, {
    options: props => {
      return {
        variables: { id: props.match.params.id },
      };
    },
  })(withRouter(withStyles(styles)(UserEdit))),
);
