import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { graphql } from 'react-apollo';
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import { FormControlLabel, Switch } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import { CenteredPaper, PaperTitle, SubmitButton, Notification } from 'shared';
import { CREATE_ORGANIZATION_UPDATE, GET_ORGANIZATION_UPDATES } from 'queries';

registerPlugin(FilePondPluginImagePreview);

const token = localStorage.getItem('token');

const styles = theme => ({
  root: {
    flexGrow: 1,
    paddingTop: '28px',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
});

class Create extends Component {
  state = {
    message: '',
    attachments: [],
    files: [],
    sending: false,
    noAuthor: true,
  };

  submitHandler = event => {
    event.preventDefault();
    const { organizationId, size } = this.props.match.params;

    const formData = new FormData();
    this.state.files.map((file, index) => formData.append(index, file));
    this.setState({ sending: true });
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/upload`,
      headers: {
        Authorization: `JWT ${token}`,
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    })
      .then(response => {
        this.props
          .mutate({
            variables: {
              id: organizationId,
              message: this.state.message,
              attachments: response.data,
              noAuthor: this.state.noAuthor,
            },
            refetchQueries: [
              {
                query: GET_ORGANIZATION_UPDATES,
                variables: { id: organizationId, size: parseInt(size) },
              },
            ],
          })
          .then(() => this.props.history.push(`/organizations/${organizationId}/view/updates`))
          .catch(res => {
            this.setState({
              error: localStorage.getItem('error'),
              sending: false,
            });
          })
          .then(setTimeout(() => this.setState({ error: null })), 2000);
      })
      .catch(() => this.setState({ sending: false }));
  };

  handleChange = input => e => {
    this.setState({ [input]: e });
  };

  handleSwitch = input => e => {
    this.setState({ [input]: e.target.checked });
  };

  render() {
    const { classes } = this.props;
    const { message, error, sending, noAuthor } = this.state;
    return (
      <form onSubmit={this.submitHandler}>
        <CenteredPaper>
          <PaperTitle>Create Update</PaperTitle>
          <Grid container className={classes.root} spacing={24}>
            <Grid item xs={12}>
              <ReactQuill value={message} onChange={this.handleChange('message')} />
            </Grid>
            <Grid item xs={12}>
              <FilePond
                allowMultiple
                files={this.state.files}
                onupdatefiles={fileItems => {
                  this.setState({
                    files: fileItems.map(fileItem => fileItem.file),
                  });
                }}
              />
            </Grid>
            <FormControlLabel
              style={{ marginLeft: 0 }}
              control={
                <Switch
                  value="noAuthor"
                  checked={noAuthor}
                  onChange={this.handleSwitch('noAuthor')}
                />
              }
              label="Send from the TRM account"
            />
            <Grid item xs={12} style={{ margin: '-25px -8px' }}>
              <SubmitButton disabled={sending}>{!sending ? 'Create' : 'Sending'}</SubmitButton>
            </Grid>
          </Grid>
        </CenteredPaper>
        {error ? <Notification message={error} variant="error" /> : null}
      </form>
    );
  }
}

export const CreateOrganizationUpdate = graphql(CREATE_ORGANIZATION_UPDATE)(
  withRouter(withStyles(styles)(Create)),
);
