export default theme => ({
  paper: {
    padding: 30,
    paddingTop: 5,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    width: '100%',
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  buttonsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '10px 0',
  },
  button: {
    display: 'flex',
    margin: '10px 20px',
    color: '#fff',
    width: '100%',
    maxWidth: '300px',
    background: '#f18a9b',
    borderColor: '#f18a9b',
    '&:hover': {
      background: '#fff',
      color: '#142339',
      borderColor: '#142339',
    },
  },
});
