import React from 'react';
import MaterialTable from 'material-table';
import { Query } from 'react-apollo';
import { GET_ORGANIZATION_HORSES } from 'queries';
import HorseMembers from 'shared/HorseMembers';

const OrganizationHorses = ({ id }) => {
  return (
    <Query query={GET_ORGANIZATION_HORSES} variables={{ id }}>
      {({ data }) => {
        const safeData = data && data.getOrganizationById ? data.getOrganizationById.horses : [];
        const tableData = safeData.map(horse => {
          return {
            id: horse.id,
            name: horse.name,
            group: horse.group ? horse.group.name : '-',
            membersNum: horse.members ? horse.members.length : 0,
            members: horse.members,
            pendingMembers: horse.pendingMembers,
            silks: (
              <img
                alt={horse.name}
                src={`https://images.timeform.com/silks/${horse.silkCode ||
                  (horse.timeformHorse ? horse.timeformHorse.silkCode : 111111)}.png`}
                style={{ outline: horse.silkCode ? '2px solid black' : 'unset' }}
              />
            ),
            silkCode: horse.silkCode || (horse.timeformHorse ? horse.timeformHorse.silkCode : 111111),
          };
        });
        return (
          <MaterialTable
            columns={[
              { title: 'ID', field: 'id', grouping: false },
              { title: 'Name', field: 'name', grouping: false },
              { title: 'Group', field: 'group' },
              { title: 'Members', field: 'membersNum' },
              { title: 'Silks', field: 'silks', grouping: false, export: false },
              {
                title: 'silkCode',
                field: 'silkCode',
                headerStyle: { display: 'none' },
                cellStyle: { display: 'none' },
              },
            ]}
            data={tableData}
            options={{
              columnsButton: true,
              pageSize: 10,
              pageSizeOptions: [5, 10, 20, tableData.length].sort((a, b) => a - b),
              actionsColumnIndex: -1,
              showEmptyDataSourceMessage: true,
              grouping: true,
              exportButton: true,
              exportAllData: true,
            }}
            detailPanel={rowData => {
              const members = [];
              rowData.members.forEach(member =>
                members.push({
                  id: member.userGroupId,
                  user: member.userGroup.user || {},
                  role: member.role,
                  shares: member.shares,
                }),
              );
              rowData.pendingMembers.forEach(invHorse =>
                members.push({
                  id: invHorse.invitation.id,
                  user: invHorse.invitation,
                  role: `PENDING (${invHorse.role})`,
                  shares: invHorse.shares,
                }),
              );
              return members.map(member => {
                return (
                  <HorseMembers
                    key={member.id}
                    user={member.user}
                    role={member.role}
                    shares={member.shares}
                  />
                );
              });
            }}
            title="Horses"
          />
        );
      }}
    </Query>
  );
};

export default OrganizationHorses;
