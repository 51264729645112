import React from 'react';
import { Grid, Typography } from '@material-ui/core';

export function HorseResultInfo(props) {
  const {
    raceTitle,
    raceType,
    btn,
    eq,
    jockeyName,
    sp,
    ips,
    tfig,
    tfr,
    timeformVerdict,
    raceNumber,
    courseId,
    surfaceName,
  } = props.data;
  return (
    <Grid container direction="column" justify="flex-start" style={{ margin: '16px', width: 700 }}>
      <Typography variant="subtitle2">Race Title: {raceTitle}</Typography>
      <Typography variant="subtitle2">Race type: {raceType}</Typography>
      <Typography variant="subtitle2">Surface type: {surfaceName}</Typography>
      <Typography variant="subtitle2">Jockey Name: {jockeyName}</Typography>
      <Typography variant="subtitle2">Starting price: {sp}</Typography>
      <Typography variant="subtitle2">Timeform Rating: {tfr}</Typography>
      <Typography variant="subtitle2">Beaten: {btn}</Typography>
      <Typography variant="subtitle2">Timefigures: {tfig}</Typography>
      <Typography variant="subtitle2">In play symbols: {ips}</Typography>
      <Typography variant="subtitle2">Equipment: {eq}</Typography>
      <Typography variant="subtitle2">Course ID: {courseId}</Typography>
      <Typography variant="subtitle2">Race number: {raceNumber}</Typography>
      <Typography variant="subtitle2">Timeform Verdict: {timeformVerdict}</Typography>
    </Grid>
  );
}
