import React from 'react';
import { Grid, Typography } from '@material-ui/core';

export function DetailsEntriesAndDeclarations(props) {
  const {
    courseName,
    draw,
    weightCarried,
    jockeyName,
    apprenticeClaim,
    entryNumber,
    horseName,
    startTimeLocalScheduled,
    distanceText,
    trainerName,
    raceTitle,
    numberOfRunners,
    meetingDate,
    raceNumber,
    courseId,
  } = props.data;
  return (
    <Grid container direction="column" justify="flex-start" style={{ margin: '16px', width: 700 }}>
      <Typography variant="subtitle2">Race Title: {courseName}</Typography>
      <Typography variant="subtitle2">Race type: {draw}</Typography>
      <Typography variant="subtitle2">Surface type: {weightCarried}</Typography>
      <Typography variant="subtitle2">Jockey Name: {jockeyName}</Typography>
      <Typography variant="subtitle2">Starting price: {apprenticeClaim}</Typography>
      <Typography variant="subtitle2">Timeform Rating: {entryNumber}</Typography>
      <Typography variant="subtitle2">Beaten: {horseName}</Typography>
      <Typography variant="subtitle2">Timefigures: {startTimeLocalScheduled}</Typography>
      <Typography variant="subtitle2">In play symbols: {distanceText}</Typography>
      <Typography variant="subtitle2">Equipment: {trainerName}</Typography>
      <Typography variant="subtitle2">Course ID: {raceTitle}</Typography>
      <Typography variant="subtitle2">Race number: {numberOfRunners}</Typography>
      <Typography variant="subtitle2">Timeform Verdict: {meetingDate}</Typography>
      <Typography variant="subtitle2">Timeform Verdict: {raceNumber}</Typography>
      <Typography variant="subtitle2">Timeform Verdict: {courseId}</Typography>
    </Grid>
  );
}
