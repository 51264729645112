import React from 'react';
import { Grid, Typography } from '@material-ui/core';

function Details(props) {
  const { data } = props;
  const oldValue = data.oldValue ? data.oldValue : 0;
  const difference = parseInt(data.newValue) - parseInt(oldValue);
  return (
    <Grid container direction="row" justify="center" alignItems="center" style={{ padding: 16 }}>
      {/* newValue: "140"
    oldValue: "139"
    ratingType: "chase" */}
      <Grid item xs={3}>
        <Typography style={{ textTransform: 'capitalize' }} variant="subtitle2">
          Type: {data.ratingType}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="subtitle2">Old: {oldValue}</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="subtitle2">New: {data.newValue}</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="subtitle2">Difference: {difference}</Typography>
      </Grid>
    </Grid>
  );
}

export const DetailsMasterRating = Details;
