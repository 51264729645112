import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Message } from 'shared/Message';

function Details(props) {
  const { attachments, message, poll } = props;
  const { options, answers } = poll || {};
  const answerNum = i => answers && answers.filter(a => a.option === i).length;
  return (
    <>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        style={{ padding: 16 }}
      >
        <Grid item xs={12}>
          <Message message={message} />
        </Grid>
        {options.map((o, i) => {
          const score = `${(answerNum(i) / answers.length) * 100 || 0}%`;
          const color = ((answerNum(i) / answers.length) * 100 || 0) > 50 ? '#4CAF50' : '#f44336';
          return (
            <Grid container style={{ marginBottom: 8 }}>
              <Grid item xs={2} style={{ borderBottom: '1px solid black' }}>
                <span style={{ textAlign: 'right' }}>{o}</span>
              </Grid>
              <Grid item xs={10}>
                <div key={o} style={{ width: '100%', backgroundColor: '#ddd', height: 20 }}>
                  <div
                    style={{
                      textAlign: 'left',
                      padding: 1,
                      color: 'black',
                      width: score,
                      backgroundColor: color,
                      height: 20,
                    }}
                  >
                    {score}
                  </div>
                </div>
              </Grid>
            </Grid>
          );
        })}
        {attachments.map((link, index) => {
          const parse = link.split('.');
          const extension = parse[5];
          const imgExt = ['jpeg', 'jpg', 'gif', 'bmp', 'png'];
          if (imgExt.includes(extension)) {
            return (
              <Grid key={index} item xs={4}>
                <img width="100%" src={link} alt={link} />
              </Grid>
            );
          }
          return <Typography variant="subtitle2">File format is not supported: {link}</Typography>;
        })}
      </Grid>
    </>
  );
}

export const DetailsPoll = Details;
