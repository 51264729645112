import OrganizationHorses from 'Organization/OrganizationView/OrganizationHorses';
import React, { Component } from 'react';
import { withRouter, Link, Route } from 'react-router-dom';
import { graphql } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import { Loader, Notification } from 'shared';
import { styles } from 'shared/ViewStyles';
import { OrganizationManagers } from 'Organization/OrganizationView/OrganizationManagers';
import { OrganizationGroups } from 'Organization/OrganizationView/OrganizationGroups';
import OrganizationPermissions from 'Organization/OrganizationView/OrganizationPermissions';
import { OrganizationUpdates } from 'Organization/OrganizationView/OrganizationUpdates';
import { GET_ORGANIZATION } from 'queries';
import OrganizationNotes from './OrganizationNotes';

class View extends Component {
  state = {
    value: '',
  };

  componentDidMount() {
    this.setState({
      value: this.props.history.location.pathname,
    });
  }

  componentDidUpdate() {
    if (this.state.value !== this.props.history.location.pathname)
      this.setState({
        value: this.props.history.location.pathname,
      });
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  editHandler = () => {
    this.props.history.push(`organization/${this.props.data.getUserById.id}/view`);
  };

  render() {
    const { classes } = this.props;
    const { value } = this.state;
    const { loading, error, getOrganizationById } = this.props.data;
    if (loading) return <Loader />;
    if (error) return <Notification message={error.message} variant="error" />;

    const { id, name, updates } = getOrganizationById;
    const size = updates.size * updates.totalPages;
    return (
      <form onSubmit={this.submitHandler} className={classes.root}>
        <Paper className={classes.paper}>
          <Tabs
            value={value}
            onChange={this.handleChange}
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
            centered
          >
            <Tab
              label={`Organization: ${name}`}
              disableRipple
              component={Link}
              to="../edit"
              // value={`/groups/${id}/view/info`}
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            <Tab
              label="Groups"
              disableRipple
              component={Link}
              to={`/organizations/${id}/view/groups`}
              value={`/organizations/${id}/view/groups`}
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            <Tab
              label="Horses"
              disableRipple
              component={Link}
              to={`/organizations/${id}/view/horses`}
              value={`/organizations/${id}/view/horses`}
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            <Tab
              label="Managers"
              disableRipple
              component={Link}
              to={`/organizations/${id}/view/managers`}
              value={`/organizations/${id}/view/managers`}
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            <Tab
              label="Updates"
              disableRipple
              component={Link}
              to={`/organizations/${id}/view/updates`}
              value={`/organizations/${id}/view/updates`}
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            <Tab
              label="Permissions"
              disableRipple
              component={Link}
              to={`/organizations/${id}/view/permissions`}
              value={`/organizations/${id}/view/permissions`}
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            <Tab
              label="Notes"
              disableRipple
              component={Link}
              to={`/organizations/${id}/view/notes`}
              value={`/organizations/${id}/view/notes`}
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
          </Tabs>

          <Route
            exact
            path="/organizations/:id/view/managers"
            render={() => (
              <div className={classes.table}>
                <OrganizationManagers id={id} />
              </div>
            )}
          />
          <Route
            exact
            path="/organizations/:id/view/groups"
            render={() => (
              <div className={classes.table}>
                <OrganizationGroups id={id} />
              </div>
            )}
          />
          <Route
            exact
            path="/organizations/:id/view/horses"
            render={() => (
              <div className={classes.table}>
                <OrganizationHorses id={id} />
              </div>
            )}
          />
          <Route
            exact
            path="/organizations/:id/view/updates"
            render={() => (
              <div className={classes.table}>
                <OrganizationUpdates id={id} size={size} />
              </div>
            )}
          />
          <Route
            exact
            path="/organizations/:id/view/permissions"
            render={() => (
              <div className={classes.table}>
                <OrganizationPermissions id={id} />
              </div>
            )}
          />
          <Route
            exact
            path="/organizations/:id/view/notes"
            render={() => (
              <div className={classes.table}>
                <OrganizationNotes id={id} />
              </div>
            )}
          />
        </Paper>
        {/* {this.state.errors ? (
          <Notification message={this.state.errors} variant={"error"} />
        ) : null} */}
      </form>
    );
  }
}

export const OrganizationView = graphql(GET_ORGANIZATION, {
  options: props => {
    return {
      variables: { id: props.match.params.id },
    };
  },
})(withRouter(withStyles(styles)(View)));
