import React from "react";
import { Grid, InputAdornment } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import "../styles.css";

const useStyles = makeStyles({
  inputField: {
    fontFamily: "Gill Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "18px"
  }
});

export const HorseCost = ({
  horseCost,
  setHorseCost,
  agreedHorseDays,
  setAgreedHorseDays,
  totalHorseDays,
  avgHorsesPerDay,
  horseDifferenceAmount,
  agreedCost
}) => {
  const classes = useStyles();
  return (
    <Grid container className="panelItemSpacing">
      <Grid item xs={10} sm={4} md={4} className={`text gridItem`}>
        Total horse days during period
      </Grid>
      <Grid item xs={1} sm={1} md={2} className={`textHighlight gridItem`}>
        {totalHorseDays}
      </Grid>
      <Grid item xs={9} sm={4} md={4} className={`text gridItem`}>
        Annual cost per horse
      </Grid>
      <Grid item xs={1} sm={1} md={2}>
        <TextField
          type="number"
          value={horseCost}
          onChange={event => setHorseCost(event.target.value)}
          placeholder="Amount"
          InputProps={{
            startAdornment: (
              <InputAdornment position={"start"}>
                <span className={classes.inputField}>£</span>
              </InputAdornment>
            )
          }}
          className={classes.inputField}
        />
      </Grid>
      {/* Next row */}
      <Grid item xs={10} sm={4} md={4} className={`text gridItem`}>
        Average number of horse p/day in period
      </Grid>
      <Grid item xs={1} sm={1} md={2} className={`textHighlight gridItem`}>
        {parseInt(avgHorsesPerDay) ? avgHorsesPerDay : 0}
      </Grid>
      <Grid item xs={9} sm={4} md={4} className={`text gridItem`}>
        Agreed number of horses p/day
      </Grid>
      <Grid item xs={1} sm={1} md={2}>
        <TextField
          type="number"
          value={agreedHorseDays}
          onChange={event => setAgreedHorseDays(event.target.value)}
          className={classes.inputField}
        />
      </Grid>
      <Grid item xs={10} sm={4} md={4} className={`text gridItem`}>
        Agreed Horse cost
      </Grid>
      <Grid item xs={10} sm={4} md={2} className={`textHighlight gridItem`}>
        £ {agreedCost}
      </Grid>
      <Grid item xs={10} sm={4} md={4} className={`text gridItem`}>
        Difference in period cost
      </Grid>
      <Grid item xs={1} sm={1} md={2} className={`textHighlight gridItem`}>
        £ {parseInt(horseDifferenceAmount) ? horseDifferenceAmount : 0}
      </Grid>
    </Grid>
  );
};

export default HorseCost;
