import React from 'react';
import { Grid, Typography, Avatar } from '@material-ui/core';
import MaterialTable from 'material-table';

export function DetailsEvent(props) {
  const { attenders, address, date, description, postcode, time, title } = props.data;
  return (
    <Grid container direction="column" justify="flex-start" style={{ padding: '16px', width: 700 }}>
      {attenders.length > 0 ? (
        <Grid item style={{ marginLeft: -16, marginTop: -16, marginBottom: 16 }}>
          <MaterialTable
            options={{
              pageSize: 10,
              exportButton: true,
              paging: false,
              pageSizeOptions: [attenders.length],
            }}
            columns={[
              {
                title: 'Avatar',
                field: 'avatarImage',
                render: rowData =>
                  rowData.avatarImage ? (
                    <Avatar alt={rowData.id} src={rowData.avatarImage} styles={{ margin: 10 }} />
                  ) : (
                    <Avatar
                      styles={{
                        margin: 10,
                      }}
                    >
                      TRM
                    </Avatar>
                  ),
              },
              { title: 'Name', field: 'firstName' },
              { title: 'Surname', field: 'surname' },
              { title: 'Email', field: 'email' },
              { title: 'Nickname', field: 'userName' },
            ]}
            data={attenders}
            title={`Event: ${title}`}
          />
        </Grid>
      ) : (
        <Typography variant="h5">Title: {title}</Typography>
      )}
      <Typography variant="subtitle2">Date: {date}</Typography>
      <Typography variant="subtitle2">Time: {time}</Typography>
      <Typography variant="subtitle2">Address: {address}</Typography>
      <Typography variant="subtitle2">Postcode: {postcode}</Typography>
      <Typography variant="subtitle2">Description: {description}</Typography>
    </Grid>
  );
}
