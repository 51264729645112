import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';

import { withRouter } from 'react-router-dom';
import { Mutation } from 'react-apollo';
import Delete from '@material-ui/icons/DeleteForeverOutlined';

const styles = theme => ({
  button: {
    marginTop: 30,
    margin: theme.spacing(1),
    display: 'flex',
    color: '#fff',
    background: '#142339',
    borderColor: '#142339',
    '&:hover': {
      background: '#fff',
      color: '#f18a9b',
      borderColor: '#f18a9b',
    },
  },
  dialog: {
    marginLeft: 73,
  },
});

class AlertDialog extends React.Component {
  state = {
    open: false,
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleDelete = () => {
    this.setState({ open: false });
  };

  render() {
    const {
      classes,
      history,
      title,
      mutationQuery,
      updateId,
      id,
      size,
      refetchQ,
      groupId,
      redirectPath,
    } = this.props;
    return (
      <>
        <Button onClick={this.handleClickOpen}>
          <Delete />
        </Button>
        <Dialog
          className={classes.dialog}
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You won't be able to undo it.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} autoFocus>
              Cancel
            </Button>
            <Mutation mutation={mutationQuery}>
              {(remove, { loading, error }) => (
                <Button
                  onClick={e => {
                    e.preventDefault();
                    remove({
                      variables: {
                        updateId,
                        id,
                      },
                      refetchQueries: [
                        {
                          query: refetchQ,
                          variables: { id: groupId, size },
                        },
                      ],
                    });
                    this.setState({ open: false });
                    if (!loading && !error) {
                      history.push(redirectPath);
                    }
                  }}
                  color="secondary"
                >
                  Delete
                </Button>
              )}
            </Mutation>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export const CDforComment = withRouter(withStyles(styles)(AlertDialog));
