import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import { Query, Mutation } from 'react-apollo';
import { Autosuggest } from 'shared';
import { GET_ALL_GROUPS, HORSE_CHANGE_GROUP } from 'queries';

class ChangeGroup extends React.Component {
  state = {
    newGroupId: '',
  };

  handleChangeAutosuggest = e => {
    this.setState({ newGroupId: e ? e.value : '' });
  };

  handleChangeGroup = (e, changeGroupFunc) => {
    const { horseId, history } = this.props;
    const { newGroupId } = this.state;
    changeGroupFunc({ variables: { horseId, groupId: newGroupId } })
      .then(() => history.push('/horses'))
      .catch(() => {
        this.setState({ error: localStorage.getItem('error') });
      })
      .then(setTimeout(() => this.setState({ error: null }), 2000));
  };

  render() {
    return (
      <Mutation mutation={HORSE_CHANGE_GROUP}>
        {mutate => (
          <Query query={GET_ALL_GROUPS}>
            {({ data, loading }) => {
              if (loading) {
                return 'Loading...';
              }
              if (data && data.getAllGroups) {
                const groupOptions = data.getAllGroups.map(item => ({
                  value: item.id,
                  label: item.name,
                }));
                return (
                  <Grid item container alignItems="flex-end">
                    <Grid item xs={8}>
                      <Autosuggest
                        options={groupOptions}
                        handleChange={this.handleChangeAutosuggest}
                        placeholder="Switch horse to group...*"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={e => this.handleChangeGroup(e, mutate)}
                      >
                        Change group
                      </Button>
                    </Grid>
                  </Grid>
                );
              }
            }}
          </Query>
        )}
      </Mutation>
    );
  }
}

export default withRouter(ChangeGroup);
