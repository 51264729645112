import React, { useState, useEffect } from "react";
import { Input } from "@material-ui/core";
import { Box } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { InputAdornment } from "@material-ui/core";
import { Tooltip } from "@material-ui/core";

import { Selection } from "./Selection";

export const Autoselect = ({
  options,
  searchTermValue,
  setSearchTermValue,
  externalShowBoxInput,
  id,
  placeholder,
  disable
}) => {
  const [data, setData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [searchTerm, setSearchTerm] = useState(
    searchTermValue?.name ? searchTermValue.name : ""
  );
  const [showBox, setShowBox] = useState(false);

  const handleChange = event => {
    setShowBox(true);
    setSearchTerm(event.target.value);

    if (event.target.value === "") {
      setShowBox(false);
      setSearchData(data);
      setSearchTermValue("");
    }
  };

  useEffect(() => {
    if (searchTerm) {
      const searchTermRemoved = searchTerm.replace(/[^a-zA-Z0-9 ]/g, "");
      setSearchData(
        data.filter(item => {
          const regex = new RegExp(`${searchTermRemoved}`, "gi");
          if (item.name.match(regex)) return item;
        })
      );
    }
  }, [searchTerm, data, setSearchData]);

  useEffect(() => {
    if (options.length > 0) {
      setData(options);
      setSearchData(options);
    }
  }, [options]);

  useEffect(() => {
    if (searchTermValue === "") {
      setSearchTerm("");
    }
  }, [searchTermValue]);

  //Track external interaction to open the box
  useEffect(() => {
    setShowBox(externalShowBoxInput);
  }, [externalShowBoxInput]);

  return (
    <Box
      style={{
        position: "relative"
      }}
    >
      <Tooltip title={searchTerm}>
        <Input
          placeholder={placeholder ? placeholder : "Search"}
          autoComplete="off"
          value={searchTerm}
          onChange={event => handleChange(event)}
          onClick={() => {
            setSearchTerm("");
          }}
          style={{
            fontFamily: "Gill sans",
            width: "170px"
          }}
          overflow="hidden"
          inputProps={{
            style: {
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: " ellipsis"
            }
          }}
          id={id}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon
                style={{
                  color: "#9E9E9E"
                }}
              />
            </InputAdornment>
          }
          disabled={disable}
        />
      </Tooltip>
      {showBox && (
        <Selection
          data={searchData}
          setSearchTerm={setSearchTerm}
          showBox={showBox}
          setShowBox={setShowBox}
          setSearchTermValue={setSearchTermValue}
        />
      )}
    </Box>
  );
};
