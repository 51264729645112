import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';

const styles = theme => ({
  info: {
    zIndex: -3,
    marginTop: 20,
    marginBottom: 20,
    paddingBottom: 10,
    margin: 35,
    fontSize: '16px',
  },
});

function UserInfo(props) {
  const {
    id,
    address1,
    address2,
    pushNotification,
    deviceToken,
    birthDate,
    country,
    district,
    email,
    emailNotification,
    firstName,
    phoneNumber,
    postCode,
    surname,
    textNotification,
    town,
    userName,
  } = props.data;
  const { classes } = props;
  return (
    <div className={classes.info}>
      <Typography variant="body2" gutterBottom>
        ID: {id}
      </Typography>
      <hr />
      <Typography variant="body2" gutterBottom>
        Name: {firstName} {surname} | {userName}
      </Typography>
      <hr />
      <Typography variant="body2" gutterBottom>
        Email: {email}
      </Typography>
      <hr />
      <Typography variant="body2" gutterBottom>
        Phone: {phoneNumber}
      </Typography>
      <hr />
      <Typography variant="body2" gutterBottom>
        Country: {country}
      </Typography>
      <hr />
      <Typography variant="body2" gutterBottom>
        District: {district}
      </Typography>
      <hr />
      <Typography variant="body2" gutterBottom>
        Town: {town}
      </Typography>
      <hr />
      <Typography variant="body2" gutterBottom>
        Address 1: {address1}
      </Typography>
      <hr />
      <Typography variant="body2" gutterBottom>
        Address 2: {address2}
      </Typography>
      <hr />
      <Typography variant="body2" gutterBottom>
        Post Code: {postCode}
      </Typography>
      <hr />
      <Typography variant="body2" gutterBottom>
        Birth Date: {birthDate}
      </Typography>
      <hr />
      <Typography variant="body2" gutterBottom>
        {emailNotification
          ? 'Email notifications are turned on'
          : 'Email notifications are turned off'}
      </Typography>
      <hr />
      <Typography variant="body2" gutterBottom>
        {pushNotification
          ? 'Push notifications are turned on'
          : 'Push notifications are turned off'}
      </Typography>
      <hr />
      <Typography
        variant="body2"
        gutterBottom
        style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
      >
        Device Token: {deviceToken}
      </Typography>
      <hr />
      <Typography variant="body2" gutterBottom>
        {textNotification
          ? 'Text notifications are turned on'
          : 'Text notifications are turned off'}
      </Typography>
    </div>
  );
}

export default withStyles(styles)(UserInfo);
