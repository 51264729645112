import { horseColours, horseGenders } from './data'

export const getCountryFromName = value => {
  if (!value || typeof value !== 'string') return '';
  return value.includes('(') ? value.split('(')[1].split(')')[0] : '';
};

export const parseUSTHorseColors = value => {
  let parsedColors = [];
  if (value) {
    const trimmedValue = value.trim().toLowerCase();
    parsedColors = trimmedValue.split(/[\s\/]+/).filter(c => c !== '');
  }
  return parsedColors.map(c => horseColours[c] ? c : null);
};

export const parseUSTHorseGender = value => {
  if (value && horseGenders[value.toLowerCase()]) return value.toLowerCase()
  return null
};