import React from "react";
import { Grid, TextField } from "@material-ui/core";
import { InputAdornment } from "@material-ui/core";

export const UserCost = ({
  annualCostUser,
  setAnnualCostUsers,
  agreedNumberUser,
  setAgreedNumberUser,
  totalUserSubs,
  avgUserSubsPerDay,
  userDifferenceAmount,
  agreedUserCost
}) => {
  return (
    <Grid container className="panelItemSpacing">
      <Grid item xs={10} sm={4} md={4} className={`text gridItem`}>
        Total user days
      </Grid>
      <Grid item xs={10} sm={4} md={2} className={`textHighlight gridItem`}>
        {totalUserSubs}
      </Grid>
      <Grid item xs={10} sm={4} md={4} className={`text gridItem`}>
        Annual cost per user subs
      </Grid>
      <Grid item xs={1} sm={1} md={2}>
        <TextField
          type="number"
          placeholder="Amount"
          value={annualCostUser}
          onChange={event => setAnnualCostUsers(event.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position={"start"}>
                <span
                  style={{
                    fontFamily: "Gill Sans",
                    fontSize: "16px",
                    lineHeight: "19px"
                  }}
                >
                  £
                </span>
              </InputAdornment>
            )
          }}
        />
      </Grid>
      <Grid item xs={10} sm={4} md={4} className={`text gridItem`}>
        Average number of user subs p/day
      </Grid>
      <Grid item xs={10} sm={4} md={2} className={`textHighlight gridItem`}>
        {parseInt(avgUserSubsPerDay) ? avgUserSubsPerDay : 0}
      </Grid>
      <Grid item xs={10} sm={4} md={4} className={`text gridItem`}>
        Agreed number of user subs p/day
      </Grid>
      <Grid item xs={10} sm={4} md={2}>
        <TextField
          type="number"
          value={agreedNumberUser}
          onChange={event => setAgreedNumberUser(event.target.value)}
        />
      </Grid>
      <Grid item xs={10} sm={4} md={4} className={`text gridItem`}>
        Agreed User Cost
      </Grid>
      <Grid item xs={10} sm={4} md={2} className={`textHighlight gridItem`}>
        £ {agreedUserCost}
      </Grid>
      <Grid item xs={10} sm={4} md={4} className={`text gridItem`}>
        Difference in period cost
      </Grid>
      <Grid item xs={10} sm={4} md={2} className={`textHighlight gridItem`}>
        £ {parseInt(userDifferenceAmount) ? userDifferenceAmount : 0}
      </Grid>
    </Grid>
  );
};

export default UserCost;
