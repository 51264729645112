import Avatar from '@material-ui/core/Avatar';
import MaterialTable from 'material-table';
import moment from 'moment';

import { DELETE_USER, GET_ALL_USERS } from 'queries';
import React from 'react';
import * as PropTypes from 'prop-types';
import { useQuery } from 'react-apollo';
import { withRouter } from 'react-router-dom';

import { ConfirmationDialog, TableLoader, Notification } from 'shared';

function UserList(props) {
  const { data, loading, error } = useQuery(GET_ALL_USERS);
  const { getAllUsers } = data || {};
  if (loading) return <TableLoader />;
  if (error) return <Notification message={error.message} variant="error" />;
  const tableData = [];
  getAllUsers.forEach(p => {
    const isManager = p.ownerships.some(q => q.role === 'org-manager' || q.role === 'manager');
    const isTrainer = p.ownerships.flatMap(o => o.horses.map(h => h.role)).includes('trainer');
    tableData.push({
      ...p,
      manager: isManager,
      trainer: isTrainer,
    });
  });
  return (
    <MaterialTable
      actions={[
        {
          icon: 'edit_circle',
          tooltip: 'Edit User Info',
          onClick: (event, rowData) => {
            props.history.push(`users/${rowData.id}/edit`);
          },
        },
        {
          icon: 'visibility_circle',
          tooltip: 'Show User',
          onClick: (event, rowData) => {
            props.history.push(`/users/${rowData.id}/view/info`);
          },
        },
        {
          isFreeAction: true,
          icon: 'add_circle',
          tooltip: 'Add User',
          onClick: () => {
            props.history.push('/users/create');
          },
        },
      ]}
      options={{
        columnsButton: true,
        pageSize: 10,
        exportButton: true,
        exportAllData: true, 
        actionsColumnIndex: -1,
        showEmptyDataSourceMessage: true,
        grouping: true,
        pageSizeOptions: [5, 10, 20, getAllUsers.length],
      }}
      columns={[
        {
          title: 'Avatar',
          field: 'avatarImage',
          render: rowData =>
            rowData.avatarImage ? (
              <Avatar alt={rowData.id} src={rowData.avatarImage} styles={{ margin: 10 }} />
            ) : (
              <Avatar
                styles={{
                  margin: 10,
                }}
              >
                TRM
              </Avatar>
            ),
        },
        {
          title: 'ID',
          field: 'id',
          type: 'numeric',
          defaultSort: 'desc',
        },
        { title: 'Name', field: 'firstName' },
        { title: 'Surname', field: 'surname' },
        { title: 'Email', field: 'email' },
        { title: 'Nickname', field: 'userName' },
        {
          title: 'Manager',
          field: 'manager',
          type: 'boolean',
        },
        {
          title: 'Trainer',
          field: 'trainer',
          type: 'boolean',
        },
        {
          title: 'Horses',
          field: 'totalHorses',
          type: 'numeric',
        },
        {
          title: 'App User',
          field: 'deviceToken',
          type: 'boolean',
        },
        {
          title: 'Last Login',
          field: 'lastLogin',
          render: rowData =>
            rowData.lastLogin ? (
              <p>{moment(parseInt(rowData.lastLogin, 10)).format('DD/MM/YYYY HH:mm')}</p>
            ) : (
              <p>Never</p>
          ),
        },
        {
          title: 'Last Seen By',
          field: 'lastSeenBy',
          render: rowData =>
            rowData.lastSeenBy ? (
              <p>{moment(parseInt(rowData.lastSeenBy, 10)).format('DD/MM/YYYY HH:mm')}</p>
            ) : (
              <p>NEVER</p>
          ),
        },
        {
          title: '',
          field: '',
          render: rowData => (
            <ConfirmationDialog
              iconButton="Delete User"
              title="Do you really want to delete this user?"
              mutationQuery={DELETE_USER}
              refetchQ={GET_ALL_USERS}
              id={rowData.id}
              redirectPath="/users"
            />
          ),
        },
      ]}
      data={tableData}
      title="Existing Users"
    />
  );
}

UserList.propTypes = {
  history: PropTypes.object,
};

export default withRouter(UserList);
