import React from 'react';
import { Grid } from '@material-ui/core';
import { Message } from 'shared/Message';

function Details(props) {
  const { message } = props;
  return (
    <Grid container direction="row" justify="center" alignItems="center" style={{ padding: 16 }}>
      <Grid item xs={12}>
        <Message message={message} />
      </Grid>
    </Grid>
  );
}

export const DetailsMessage = Details;
