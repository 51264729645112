import React from 'react';
import * as PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import MaterialTable from 'material-table';
import { graphql, useQuery, useMutation } from 'react-apollo';
import moment from 'moment';
import { CsvBuilder } from 'filefy';
import {
  GET_ALL_INVITATIONS,
  CANCEL_INVITATION,
  RESEND_INVITATION,
  CONFIRM_INVITATION,
  GET_GROUP_MEMBERS,
} from 'queries';
import { TableLoader, Notification, CDforList } from 'shared';
import UserGroupHorses from 'Group/GroupView/GroupMembers/UserGroupHorses';

function PendingUsers(props) {
  const [successResendInvitation, setSuccessResendInvitation] = React.useState(false);
  const [errorResendInvitation, setErrorResendInvitation] = React.useState(false);
  const [cancelInvitationOpen, setCancelInvitationOpen] = React.useState(false);
  const [cancelData, setCancelData] = React.useState({});
  const {
    data: { loading, error },
  } = props;
  const { data } = useQuery(GET_ALL_INVITATIONS, {
    fetchPolicy: 'network-only',
  });
  // const [cancelInvite] = useMutation(CANCEL_INVITATION);
  const [resendInvite] = useMutation(RESEND_INVITATION);
  const [confirmInvitation] = useMutation(CONFIRM_INVITATION);
  if (loading) return <TableLoader />;
  if (error) return <Notification message={error.message} variant="error" />;
  const tableData = [];
  if (data && data.getPendingInvitations) {
    data.getPendingInvitations.forEach(p => {
      tableData.push({
        ...p,
        groupName: p.group ? p.group.name : '-',
        role: 'Pending Invitation',
        sentAt: p.sentAt ? moment(p.sentAt).format('HH:mm DD/MM/YY') : '',
      });
    });
  }

  const handleCsvExport = (columns, data) => {
    const rowsArr = [];
    for (let i = 0; i < data.length; i += 1) {
      const member = data[i];
      member.horses.forEach(h => {
        rowsArr.push({ ...member, horseId: h.horse.id, horseName: h.horse.name });
      });
    }
    const csvColumns = columns.map((columnDef) => columnDef.title);
    const csvData = rowsArr.map(rowData => columns.map(columnDef => rowData[columnDef.field]));
    const builder = new CsvBuilder(`PendingMembers_${moment().format('YYYY-MM-DDTHHmm')}.csv`);
    builder.setDelimeter(',').setColumns(csvColumns).addRows(csvData).exportFile();
  };

  const invitationMessage = () => {
    if(successResendInvitation) {
      return {
        message: "Invitation successfully sent",
        variant: "success"
      }
    } else if (errorResendInvitation) {
        return {
          message: "Error",
          variant: "error"
        }
      } else return false
  }

  return (
    <>
      <CDforList
        open={cancelInvitationOpen}
        title={`Are you sure you want to delete the invitation for ${cancelData.name} ${cancelData.email}?`}
        mutationQuery={CANCEL_INVITATION}
        options={{
          variables: { id: cancelData.id },
          refetchQueries: [
            { query: GET_ALL_INVITATIONS },
            { query: GET_GROUP_MEMBERS, variables: { id: cancelData.groupId } },
          ],
        }}
        handleClose={() => {
          setCancelInvitationOpen(false);
          setCancelData({});
        }}
      />
      <MaterialTable
        options={{
          columnsButton: true,
          pageSize: 10,
          exportButton: true,
          exportAllData: true,
          pageSizeOptions: [5, 10, 20, tableData.length],
          actionsColumnIndex: -1,
          exportCsv: (col, data) => {
            handleCsvExport(col, data)
          },
        }}
        columns={[
          {
            title: 'ID',
            field: 'id',
            type: 'numeric',
            defaultSort: 'desc',
          },
          { title: 'Name', field: 'firstName' },
          { title: 'Surname', field: 'surname' },
          { title: 'Email', field: 'email' },
          { title: 'Manager', field: 'isManager', type: 'boolean' },
          { title: 'Trainer', field: 'isTrainer', type: 'boolean' },
          { title: 'Group', field: 'groupName' },
          { title: 'Sent At', field: 'sentAt' },
          {
            title: 'HorseID',
            cellStyle: { display: 'none' },
            headerStyle: { display: 'none' },
            field: 'horseId',
          },
          {
            title: 'HorseName',
            cellStyle: { display: 'none' },
            headerStyle: { display: 'none' },
            field: 'horseName',
          },
        ]}
        actions={[
          rowData =>
            rowData.role === 'Pending Invitation'
              ? {
                  icon: 'refresh',
                  tooltip: 'Resend the invitation',
                  onClick: (event, rowData) =>
                    resendInvite({
                      variables: { id: rowData.id },
                      refetchQueries: [{ query: GET_ALL_INVITATIONS }],
                    }).then(({data}) => {
                      if(data.resendInvitation) {
                        setSuccessResendInvitation(true)
                      }
                    }).catch(() => setErrorResendInvitation(true)),
                }
              : null,
          rowData =>
            rowData.role === 'Pending Invitation'
              ? {
                  icon: 'cancel',
                  tooltip: 'Cancel the invitation',
                  onClick: (event, rowClickData) => {
                    setCancelData({
                      id: rowClickData.id,
                      name: `${rowClickData.firstName} ${rowClickData.surname}`,
                      email: rowClickData.email,
                      groupId: rowClickData.group.id,
                    });
                    setCancelInvitationOpen(true);
                  },
                }
              : null,
          rowData =>
            !rowData.newUser
              ? {
                  icon: 'check_circle_outline',
                  tooltip: 'Confirm user membership',
                  onClick: (event, rowData) =>
                    confirmInvitation({
                      variables: {
                        inviteId: rowData.id,
                        firstName: rowData.firstName,
                        surname: rowData.surname,
                      },
                      refetchQueries: [{ query: GET_ALL_INVITATIONS }],
                    }),
                }
              : null,
        ]}
        data={tableData}
        title="Pending Users"
        detailPanel={rowData => {
          return rowData.horses.map((i, index) => {
            return (
              <UserGroupHorses key={index} name={i.horse.name} role={i.role} shares={i.shares} />
            );
          });
        }}
      />
      {invitationMessage() && (
        <Notification
          message={invitationMessage().message}
          variant={invitationMessage().variant}
          onClose={() => {
            setErrorResendInvitation(false)
            setSuccessResendInvitation(false)
          }}
        />
      )}
    </>
  );
}

PendingUsers.propTypes = {
  data: PropTypes.object,
};

export default graphql(GET_ALL_INVITATIONS)(withRouter(PendingUsers));
