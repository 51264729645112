import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { graphql } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { ADD_GROUP, GET_GROUPS_SELECTOR, GET_ORGANIZATION_GROUPS } from 'queries';
import { Notification, SubmitButton, CenteredPaper, PaperTitle, Loader, Autosuggest } from 'shared';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    display: 'flex',
    width: 540,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  formControl: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: theme.spacing(1),
    minWidth: 225,
  },
});

class Create extends Component {
  state = {
    groupId: '',
  };

  submitHander = event => {
    const { id } = this.props.match.params;
    event.preventDefault();
    this.props
      .mutate({
        variables: {
          ...this.state,
          id,
        },
        refetchQueries: [{ query: GET_ORGANIZATION_GROUPS, variables: { id } }],
      })
      .then(() => this.props.history.push(`/organizations/${id}/view/groups`))
      .catch(res => {
        this.setState({ error: localStorage.getItem('error') });
      })
      .then(setTimeout(() => this.setState({ error: null })), 2000);
  };

  handleChangeAutosuggest = input => e => {
    this.setState({ [input]: e ? e.value : '' });
  };

  render() {
    // const { role, userId } = this.state;
    const { classes } = this.props;
    const { loading, error } = this.props.data;

    if (loading) return <Loader />;
    if (error) return <Notification message={error.message} variant="error" />;
    const userOptions = this.props.data.getAllGroups.map(item => ({
      value: item.id,
      label: item.name,
    }));
    return (
      <form onSubmit={this.submitHander}>
        <CenteredPaper>
          <PaperTitle>Add Group to Organization</PaperTitle>
          <Grid container className={classes.root}>
            <br />
            <Grid item xs={12} sm={12}>
              <Autosuggest
                options={userOptions}
                handleChange={this.handleChangeAutosuggest('groupId')}
                placeholder="Search a group..."
              />
            </Grid>
            <SubmitButton>Add Group</SubmitButton>
          </Grid>
        </CenteredPaper>
        {this.state.error ? <Notification message={this.state.error} variant="error" /> : null}
      </form>
    );
  }
}

export const AddGroup = graphql(ADD_GROUP)(
  graphql(GET_GROUPS_SELECTOR, {
    options: props => {
      return {
        variables: { id: props.match.params.id },
      };
    },
  })(withRouter(withStyles(styles)(Create))),
);
