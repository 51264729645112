export default theme => ({
  main: {
    [theme.breakpoints.down('sm')]: {
      background: '#3d4a63',
    },

    // background: "url(https://source.unsplash.com/1920x1080/?horse)",
    background: 'url(https://source.unsplash.com/collection/1301586/1920x1080)',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    // height: "1px",
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    color: '#142339',
  },
  title: {
    color: '#fff',
    background: '#142339',
    marginTop: -40,
    marginLeft: -33,
    marginRight: -33,
    padding: '15px 50px',
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);',
    borderRadius: 4,
  },
  paper: {
    width: 400,
    [theme.breakpoints.down('sm')]: {
      width: 300,
      textAlign: 'center',
      marginTop: 80,
    },
    marginTop: 200,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2, 3, 3),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: '#ec5c74',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
    marginLeft: '25%',
    width: '50%',
    marginTop: theme.spacing(3),
    backgroundColor: '#142339',
    color: '#fff',
  },
});
