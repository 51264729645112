import React from 'react';
import { ApolloProvider } from 'react-apollo';
import { BrowserRouter } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core';
import client from 'config/apollo';
import router from 'config/router';
import theme from 'config/theme';

function App() {
  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <MuiThemeProvider theme={theme}>{router()}</MuiThemeProvider>
      </BrowserRouter>
    </ApolloProvider>
  );
}

export default App;
