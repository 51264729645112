export default theme => ({
  root: {
    paddingTop: 10,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    display: 'flex',
    width: 540,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  findButton: {
    height: 32,
    borderRadius: 0,
    marginLeft: 468,
    position: 'absolute',
    top: 188,
    backgroundColor: '#142339',
    color: 'white',
    '&:hover': {
      backgroundColor: '#f18a9b',
    },
  },
  grid: {
    '& > :first-child': {
      margin: 0,
    },
  },
  formControl: {
    minWidth: 70,
    display: 'flex',
    flexWrap: 'wrap',
  },
  buttonsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '10px 0',
  },
  button: {
    display: 'flex',
    margin: '10px 20px',
    color: '#fff',
    width: '100%',
    maxWidth: '300px',
    background: '#f18a9b',
    borderColor: '#f18a9b',
    '&:hover': {
      background: '#fff',
      color: '#142339',
      borderColor: '#142339',
    },
  },
});
