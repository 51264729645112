import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { Mutation } from 'react-apollo';
import { Tooltip, IconButton, Grid } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

const styles = theme => ({
  button: {
    marginTop: 30,
    margin: theme.spacing(1),
    display: 'flex',
    color: '#fff',
    background: '#142339',
    borderColor: '#142339',
    '&:hover': {
      background: '#fff',
      color: '#f18a9b',
      borderColor: '#f18a9b',
    },    
  },
  disabled:{
    background: 'white !important'
  },
  dialog: {
    marginLeft: 73,
  },
  db: {
    '&:hover': {
      backgroundColor: 'white',
    },
  },
});

class AlertDialog extends React.Component {
  state = {
    open: false,
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleDelete = () => {
    this.setState({ open: false });
  };

  render() {
    const {
      classes,
      history,
      title,
      mutationQuery,
      id,
      refetchQ,
      redirectPath,
      iconButton,
      disableButton
    } = this.props;
    const { open } = this.state;
    return (
      <div>
        {this.props.iconButton ? (
          <Tooltip title={iconButton}>            
            <IconButton 
              disabled={disableButton}
              color="secondary" 
              aria-label="delete" 
              onClick={this.handleClickOpen}>
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        ) : (
          <Button
            disabled={disableButton}
            variant="outlined"
            color="primary"
            className={(disableButton)?classes.button+' '+classes.disabled:classes.button}
            onClick={this.handleClickOpen}
          >
            Delete
          </Button>
        )}
        <Dialog
          className={classes.dialog}
          open={open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You won't be able to undo it.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              style={{ paddingLeft: 16, paddingRight: 16 }}
            >
              <Button onClick={this.handleClose} className={classes.db} autoFocus>
                Cancel
              </Button>
              <Mutation mutation={mutationQuery}>
                {(remove, { loading, error }) => (
                  <Button
                    className={classes.db}
                    onClick={e => {
                      e.preventDefault();
                      remove({
                        variables: { id },
                        refetchQueries: [{ query: refetchQ }],
                      });
                      this.setState({ open: false });
                      if (!loading && !error) {
                        history.push(redirectPath);
                      }
                    }}
                    color="secondary"
                    style={{ color: '#f50057' }}
                  >
                    Delete
                  </Button>
                )}
              </Mutation>
            </Grid>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export const ConfirmationDialog = withRouter(withStyles(styles)(AlertDialog));
