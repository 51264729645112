import React from 'react';
import { Grid, Typography } from '@material-ui/core';

const UserGroupHorses = ({ name, role, shares }) => (
  <Grid
    container
    direction="row"
    justify="flex-start"
    alignItems="center"
    style={{
      borderBottom: '1px solid rgba(224, 224, 224, 1)',
      minHeight: 47,
    }}
  >
    <Typography style={{ width: 300, marginLeft: 16 }} variant="body2">
      Horse: {name}
    </Typography>
    <Typography style={{ width: 300, textTransform: 'capitalize' }} variant="body2">
      Role: {role}
    </Typography>
    <Typography style={{ width: 284 }} variant="body2">
      Shares: {shares}%
    </Typography>
  </Grid>
);

export default UserGroupHorses;
