import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { graphql } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { GET_COMMENT, EDIT_COMMENT } from 'queries';
import { Loader, Notification, CenteredPaper, PaperTitle, SubmitButton } from 'shared';

const styles = theme => ({
  root: {
    paddingTop: '28px',
    flexGrow: 1,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
});

class EditComment extends Component {
  state = {
    message: '',
  };

  submitHandler = event => {
    const { id } = this.props.match.params;

    event.preventDefault();
    this.props
      .mutate({
        variables: {
          ...this.state,
          id,
        },
        refetchQueries: [
          // { query: GET_GROUP_UPDATES, variables: { id: groupId } },
          // { query: GET_ORGANIZATION_UPDATES, variables: { id: groupId } },
          { query: GET_COMMENT, variables: { id } },
        ],
      })
      .then(this.props.data.refetch())
      .then(() => this.props.history.goBack())
      // .then(() => this.props.history.push(`/groups/${groupId}/view/updates`))
      .catch(res => {
        this.setState({ error: localStorage.getItem('error') });
      })
      .then(setTimeout(() => this.setState({ error: null })), 2000);
  };

  handleChange = input => e => {
    this.setState({ [input]: e });
  };

  componentDidMount() {
    if (!this.props.data.loading) {
      this.setState(this.props.data.getUpdateById);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data.getUpdateById !== this.props.data.getUpdateById) {
      this.setState(this.props.data.getUpdateById);
    }
  }

  handleDelete = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes } = this.props;
    const { loading, error } = this.props.data;
    if (loading) return <Loader />;
    if (error) return <Notification message={error.message} variant="error" />;
    const { message } = this.state;
    return (
      <form onSubmit={this.submitHandler}>
        <CenteredPaper>
          <PaperTitle>{`Edit ${this.props.match.params.type}`}</PaperTitle>
          <Grid container className={classes.root} spacing={24}>
            <Grid item xs={12}>
              <ReactQuill value={message} onChange={this.handleChange('message')} />
            </Grid>
            <Grid item xs={12} style={{ margin: '-25px -8px' }}>
              <SubmitButton>Edit</SubmitButton>
            </Grid>
          </Grid>
        </CenteredPaper>
        {this.state.error ? <Notification message={this.state.error} variant="error" /> : null}
      </form>
    );
  }
}

export default graphql(EDIT_COMMENT)(
  graphql(GET_COMMENT, {
    options: props => {
      return {
        variables: {
          id: props.match.params.id,
        },
      };
    },
  })(withRouter(withStyles(styles)(EditComment))),
);
