import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const styles = {
  gif: {
    width: 600,
    marginTop: '30vh',
  },
};

function LinearQuery(props) {
  const { classes } = props;
  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <img
        className={classes.gif}
        src="https://s3-eu-west-1.amazonaws.com/trm-koa/assets/colourised-float-logo.gif"
        alt="Loading"
      />
    </Grid>
  );
}

LinearQuery.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const Loader = withStyles(styles)(LinearQuery);
