import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { graphql } from 'react-apollo';
import axios from 'axios';
import ReactQuill from 'react-quill';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import { TextField, FormControlLabel, Switch } from '@material-ui/core';
import 'react-quill/dist/quill.snow.css';
import { CenteredPaper, PaperTitle, SubmitButton, Notification } from 'shared';
import { GET_GROUP_UPDATES, CREATE_POLL } from 'queries';

registerPlugin(FilePondPluginImagePreview);

const token = localStorage.getItem('token');

const styles = theme => ({
  root: {
    flexGrow: 1,
    paddingTop: '14px',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    padding: 0,
    display: 'flex',
    width: 540,
  },
});

class CreatePoll extends Component {
  state = {
    message: '',
    attachments: [],
    files: [],
    sending: false,
    options: ['', '', '', '', ''],
    noAuthor: true,
  };

  submitHandler = event => {
    event.preventDefault();
    const { horseId, groupId, size } = this.props.match.params;

    const formData = new FormData();
    this.state.files.map((file, index) => formData.append(index, file));
    this.setState({ sending: true });
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/upload`,
      headers: {
        Authorization: `JWT ${token}`,
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    })
      .then(response => {
        this.props
          .mutate({
            variables: {
              message: this.state.message,
              attachments: response.data,
              options: this.state.options.filter(option => option !== ''),
              horseId,
              noAuthor: this.state.noAuthor,
            },
            refetchQueries: [
              {
                query: GET_GROUP_UPDATES,
                variables: { id: groupId, size: parseInt(size) },
              },
            ],
          })
          .then(() => this.props.history.push(`/groups/${groupId}/view/updates`))
          .catch(res => {
            this.setState({
              error: localStorage.getItem('error'),
              sending: false,
            });
          })
          .then(setTimeout(() => this.setState({ error: null })), 2000);
      })
      .catch(() => this.setState({ sending: false }));
  };

  handleChange = (input, index) => e => {
    const { value } = e.target;
    this.setState(prevState => {
      const newOptions = [...prevState.options];
      newOptions[index] = value.charAt(0).toUpperCase() + value.slice(1) || '';
      return { options: newOptions };
    });
  };

  handleRichtextChange = input => e => {
    this.setState({ [input]: e });
  };

  handleSwitch = input => e => {
    this.setState({ [input]: e.target.checked });
  };

  render() {
    const { classes } = this.props;
    const { message, error, options, sending, noAuthor } = this.state;
    return (
      <form onSubmit={this.submitHandler}>
        <CenteredPaper>
          <PaperTitle>Create Poll</PaperTitle>
          <Grid container className={classes.root} spacing={24}>
            {options.map((option, index) => (
              <Grid item xs={12}>
                <TextField
                  label={`Option ${index + 1}`}
                  value={option}
                  onChange={this.handleChange('options', index)}
                  className={classes.textField}
                />
              </Grid>
            ))}

            <Grid item xs={12}>
              <ReactQuill value={message} onChange={this.handleRichtextChange('message')} />
            </Grid>
            <Grid item xs={12}>
              <FilePond
                allowMultiple
                files={this.state.files}
                onupdatefiles={fileItems => {
                  this.setState({
                    files: fileItems.map(fileItem => fileItem.file),
                  });
                }}
              />
            </Grid>
            <FormControlLabel
              style={{ marginLeft: 0 }}
              control={
                <Switch
                  value="noAuthor"
                  checked={noAuthor}
                  onChange={this.handleSwitch('noAuthor')}
                />
              }
              label="Send from the TRM account"
            />
            <Grid item xs={12} style={{ margin: '-25px -8px' }}>
              <SubmitButton disabled={sending}>{!sending ? 'Create' : 'Sending'}</SubmitButton>
            </Grid>
          </Grid>
        </CenteredPaper>
        {error ? <Notification message={error} variant="error" /> : null}
      </form>
    );
  }
}

export default graphql(CREATE_POLL)(withRouter(withStyles(styles)(CreatePoll)));
