import React, { Component } from 'react';
import { withRouter, Route } from 'react-router-dom';
import {
  withStyles,
  Paper,
  Grid,
  FormControlLabel,
  Switch,
  MenuItem,
  TextField,
} from '@material-ui/core';
import MaterialTable, { MTableToolbar } from 'material-table';
import { graphql, withApollo } from 'react-apollo';
import { GET_GROUP_MEMBERS } from 'queries';
import { Notification } from 'shared';
import HorseBulkSelector from './HorseBulkSelector';

const styles = {
  paper: {
    width: 900,
    margin: 'auto',
  },
};

class BulkUpload extends Component {
  state = { users: [], autoOnboard: false, managerId: '' };

  parseData = data => {
    const clipRows = data.split('\n');
    const users = clipRows.map(row => {
      const splitted = row.split(String.fromCharCode(9));
      return {
        name: splitted[0] || '',
        surname: splitted[1] || '',
        email: splitted[2] ? splitted[2].trim() : '',
        phoneNumber: splitted[3] ? `+${splitted[3].replace('+', '')}` : '',
      };
    });
    this.setState({ users });
  };

  paste = e => {
    if (this.state.users.length === 0) {
      const data = e.clipboardData.getData('text');
      this.parseData(data);
    }
  };

  buttonPaste = () => {
    navigator.clipboard
      .readText()
      .then(text => {
        this.parseData(text);
      })
      .catch(err => {
        console.error('Failed to read clipboard contents: ', err);
      });
  };

  handleSwitch = input => e => {
    this.setState({ [input]: e.target.checked, managerId: '' });
  };

  handleChangeAutosuggest = input => e => {
    this.setState({ [input]: e ? e.value : '' });
  };

  handleChange = input => e => {
    this.setState({ [input]: e.target.value });
  };

  // shouldComponentUpdate(nextProps, nextState) {
  //   console.log(nextState);
  //   if (nextState.managerId === this.state.managerId && nextState.managerId !== '') {
  //     return false;
  //   } else {
  //     return true;
  //   }
  // }

  render() {
    const { users, autoOnboard, managerId } = this.state;
    const { classes } = this.props;
    const { loading, error, getGroupById } = this.props.data;
    if (loading) return null;
    if (error) return <Notification message={error.message} variant="error" />;

    const { members } = getGroupById;
    const EMAIL_REG = /^[_a-zA-Z0-9-]+(\.[_a-z0-9-]+)*(\+[a-z0-9-]+)?@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]{2,})+$/;
    const validateEmail = email => EMAIL_REG.test(email);

    const valErrors =
      users.filter(
        user =>
          user.name === '' ||
          user.surname === '' ||
          user.email === '' ||
          !validateEmail(user.email),
      ).length !== 0;

    const managers = members.filter(
      member => member.role === 'manager' || member.role === 'org-manager',
    );
    const managersOptions = managers.map(manager => ({
      value: manager.user.id,
      label: manager.user.email,
    }));

    return (
      <div id="div" onPaste={this.paste}>
        <Paper className={classes.paper}>
          <MaterialTable
            components={{
              Toolbar: props => (
                <div>
                  <MTableToolbar {...props} />
                  <Grid
                    container
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    style={{
                      height: 68,
                      zIndex: 1000,
                      position: 'relative',
                    }}
                  >
                    <Grid item xs={3}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={autoOnboard}
                            value="autoOnboard"
                            onChange={this.handleSwitch('autoOnboard')}
                          />
                        }
                        label="Auto-Onboard"
                      />
                    </Grid>
                    <Grid item xs={8}>
                      {autoOnboard ? (
                        <TextField
                          margin="dense"
                          fullWidth
                          select
                          label="Select Manager"
                          value={managerId}
                          onChange={this.handleChange('managerId')}
                          helperText="Select the manager who will be the email sender"
                        >
                          {managersOptions.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      ) : null}
                    </Grid>
                  </Grid>
                </div>
              ),
            }}
            className={this.props.classes.table}
            actions={[
              {
                isFreeAction: true,
                icon: 'vertical_align_bottom',
                tooltip: 'Set the data from the clipboard',
                onClick: e => {
                  this.buttonPaste();
                },
              },
              {
                isFreeAction: true,
                icon: 'clear',
                tooltip: 'Clear the table to insert the another one',
                onClick: () => {
                  this.setState({ users: [] });
                },
              },
              {
                isFreeAction: true,
                icon: 'forward',
                disabled: users.length === 0 || valErrors,
                tooltip: 'Choose the horses for these users',
                onClick: () => {
                  this.props.history.push('./bulk-upload/choose-horses');
                },
              },
            ]}
            options={{
              pageSize: 10,
              actionsColumnIndex: -1,
              showEmptyDataSourceMessage: true,
              paging: false,
              maxBodyHeight: 'calc(100vh - 189px)',
            }}
            columns={[
              {
                title: 'Name',
                field: 'name',
                cellStyle: data => {
                  if (data === undefined || data === '') {
                    return {
                      border: '1px solid red',
                      backgroundColor: 'rgba(255,0,0, 0.3)',
                    };
                  }
                },
              },
              {
                title: 'Surname',
                field: 'surname',
                cellStyle: data => {
                  if (data === undefined || data === '') {
                    return {
                      border: '1px solid red',
                      backgroundColor: 'rgba(255,0,0, 0.3)',
                    };
                  }
                },
              },
              {
                title: 'Email',
                field: 'email',
                cellStyle: data => {
                  if (data === undefined || data === '' || !validateEmail(data)) {
                    return {
                      border: '1px solid red',
                      backgroundColor: 'rgba(255,0,0, 0.3)',
                    };
                  }
                },
              },
              {
                title: 'Phone number',
                field: 'phoneNumber',
              },
            ]}
            editable={{
              onRowAdd: newData =>
                new Promise((resolve, reject) => {
                  // setTimeout(() => {
                  {
                    const data = users;
                    data.push(newData);
                    this.setState({ data }, () => resolve());
                  }
                  resolve();
                  // }, 500);
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  // setTimeout(() => {
                  {
                    const data = users;
                    const index = data.indexOf(oldData);
                    data[index] = newData;
                    this.setState({ data }, () => resolve());
                  }
                  resolve();
                  // }, 500);
                }),
              onRowDelete: oldData =>
                new Promise((resolve, reject) => {
                  // setTimeout(() => {
                  {
                    const data = users;
                    const index = data.indexOf(oldData);
                    data.splice(index, 1);
                    this.setState({ data }, () => resolve());
                  }
                  resolve();
                  // }, 500);
                }),
            }}
            data={users}
            title={`Members to upload - ${users.length}`}
            localization={{
              body: {
                emptyDataSourceMessage:
                  'No records to display. Paste the data using key-shortcut or use the button above.',
              },
              header: {
                actions: '',
              },
            }}
          />
        </Paper>

        <Route
          exact
          path="/groups/:id/bulk-upload/choose-horses"
          render={props => <HorseBulkSelector users={users} managerId={managerId} {...props} />}
        />
      </div>
    );
  }
}

// export const BulkUpload = withRouter(withStyles(styles)(Group));

export default graphql(GET_GROUP_MEMBERS, {
  options: props => {
    return {
      variables: { id: props.match.params.id },
    };
  },
})(withApollo(withRouter(withStyles(styles)(BulkUpload))));

// If there is a need, there is a way to set specific relation for users, mb with the predefined default value, which you could change
