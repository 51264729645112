export const horseGenders = {
  g: 'Gelding',
  m: 'Mare',
  c: 'Colt',
  f: 'Filly',
  b: 'Broodmare',
  r: 'Rig',
  h: 'Horse',
};

export const horseColours = {
  ch: 'Chestnut',
  gr: 'Grey',
  b: 'Bay',
  br: 'Brown',
  bl: 'Black',
  wh: 'White',
  pt: 'Coloured',
  ro: 'Roa',
  dkb: 'Dark Bay',
  pal: 'Palomino',
};

export const countries = [
  { key: 'ARG', label: 'Argentina' },
  { key: 'AUS', label: 'Australia' },
  { key: 'BHR', label: 'Bahrain' },
  { key: 'BRA', label: 'Brazil' },
  { key: 'CAN', label: 'Canada' },
  { key: 'UAE', label: 'Emirates' },
  { key: 'FR', label: 'France' },
  { key: 'GER', label: 'Germany' },
  { key: 'GB', label: 'Great Britain' },
  { key: 'IRE', label: 'Ireland' },
  { key: 'ITA', label: 'Italy' },
  { key: 'JPN', label: 'Japan' },
  { key: 'NZ', label: 'New Zealand' },
  { key: 'SAF', label: 'South Africa' },
  { key: 'USA', label: 'United States' },
];

countries.sort((a, b) => {
  return a.label.localeCompare(b.label);
});

export const currencies = {
  DOLLAR: '$',
  EURO: '€',
  POUND: '£',
  GUINEA: 'gn',
};

export const services = {
  TIMEFORM: 'Timeform',
  FG: 'France Galop',
  UST: 'US Timeform'
};

export const mainSiteUrl =
  process.env.REACT_APP_BASE_URL === 'dev'
    ? 'https://dev.theracingmanager.com'
    : 'https://theracingmanager.com';
