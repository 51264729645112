import { Grid } from '@material-ui/core';
import MaterialTable from 'material-table';

import { DELETE_GROUP, GET_ALL_GROUPS } from 'queries';
import React from 'react';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';

import { ConfirmationDialog, Loader, Notification } from 'shared';

function GroupCreate(props) {
  const { loading, error, getAllGroups } = props.data;
  if (loading) return <Loader />;
  if (error) return <Notification message={error.message} variant="error" />;
  const groupWithMembers = getAllGroups.map(g => ({
    ...g,
    members: g.members.length,
    horses: g.horses.length,
  }));
  
  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <MaterialTable
        actions={[
          {
            icon: 'edit_circle',
            tooltip: 'Edit Group Info',
            onClick: (event, rowData) => {
              props.history.push(`groups/${rowData.id}/edit`);
            },
          },
          {
            isFreeAction: true,
            icon: 'add_circle',
            tooltip: 'Add Group',
            onClick: () => {
              props.history.push('/groups/create');
            },
          },
          {
            icon: 'visibility_circle',
            tooltip: 'Show Group',
            onClick: (event, rowData) => {
              props.history.push(`/groups/${rowData.id}/view/info`);
            },
          },
        ]}
        options={{
          grouping: true,
          columnsButton: true,
          pageSize: 10,
          exportButton: true,
          exportAllData: true,
          pageSizeOptions: [5, 10, 20, groupWithMembers.length],
        }}
        columns={[
          { 
            title: 'TRM group ID', 
            field: 'id', 
            type: 'numeric', 
            defaultSort: 'desc',
            render: rowData => <p style={{minWidth: '115px'}}>{rowData.id}</p>
          },
          { title: 'Name', field: 'name' },
          { title: 'Type', field: 'type' },
          { title: 'Members', field: 'members' },
          { title: 'Horses', field: 'horses' },
          { title: 'Address', field: 'address1' },
          { title: 'Country', field: 'country' },
          { title: 'Town', field: 'town' },
          { title: 'Period', field: 'period' },
          { title: 'Approved', field: 'bhaApproved', type: 'boolean' },
          { title: 'Existing', field: 'existing', type: 'boolean' },
          { title: 'Paid', field: 'membershipPayedByUser', type: 'boolean' },
          { title: 'Trial', field: 'trial', type: 'boolean' },
          {
            title: '',
            field: '',
            render: rowData => (
              <div>
                <ConfirmationDialog
                  iconButton="Delete Group"
                  title="Do you really want to delete this group?"
                  mutationQuery={DELETE_GROUP}
                  refetchQ={GET_ALL_GROUPS}
                  disableButton={(rowData.horses>0)?true:false}
                  id={rowData.id}                  
                  redirectPath="/groups"
                />
              </div>
            ),
          },
        ]}
        data={groupWithMembers}
        title="Groups"
      />
    </Grid>
  );
}

export default graphql(GET_ALL_GROUPS)(withRouter(GroupCreate));
