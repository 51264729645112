import React, { Component } from 'react';

import { withRouter, Link, Route } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import { styles } from 'shared/ViewStyles';
import UserList from './UserList';
import PendingUsers from './UserPendingList';

class Users extends Component {
  state = {
    value: '',
  };

  componentDidMount() {
    this.setState({
      value: this.props.history.location.pathname,
    });
  }

  componentDidUpdate() {
    if (this.state.value !== this.props.history.location.pathname)
      this.setState({
        value: this.props.history.location.pathname,
      });
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { classes } = this.props;
    const { value } = this.state;
    const showTabs = this.state.value === '/users' || this.state.value === '/users/pendingUsers';
    return (
      <Paper className={classes.paper} style={{width:"100%"}}>
        {showTabs ? (
          <Tabs
            value={value}
            onChange={this.handleChange}
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
            centered
          >
            <Tab
              label="Existing Users"
              disableRipple
              component={Link}
              to="/users"
              value="/users"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            <Tab
              label="Pending Users"
              disableRipple
              component={Link}
              to="/users/pendingUsers"
              value="/users/pendingUsers"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
          </Tabs>
        ) : null}

        <Route exact path="/users" render={() => <UserList />} />
        <Route exact path="/users/pendingUsers" render={() => <PendingUsers />} />
      </Paper>
    );
  }
}

export default withRouter(withStyles(styles)(Users));
