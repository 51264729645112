import React from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { Typography, Avatar, withStyles, Grid, Button } from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';
import Like from '@material-ui/icons/Favorite';
import Edit from '@material-ui/icons/EditOutlined';
import { CDforComment } from 'shared/CDforComment';
import { GET_GROUP_UPDATES, REMOVE_COMMENT } from 'queries';

const styles = {
  comment: {
    width: 868,
    margin: '16px',
    backgroundColor: 'rgba(0, 0, 0, 0.06)',
    color: 'white',
    borderRadius: 10,
    padding: 10,
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);',
  },
  typography: {
    color: 'black',
    lineHeight: '1.3em',
  },
  bigAvatar: {
    margin: '0 15px',
    width: 35,
    height: 35,
  },
  icon: {
    height: 15,
    margin: '-2px 0',
    color: '#ec5c74',
  },
};

function Details(props) {
  const { classes, groupId, size } = props;
  return props.data.map(comment => (
    <Grid
      className={classes.comment}
      container
      direction="row"
      justify="flex-start"
      alignItems="flex-start"
    >
      <Grid item xs={1}>
        {/* <Avatar
          alt={`${comment.author.userName}`}
          // src={`${comment.author.avatarImage}`}
          src="https://material-ui.com/static/images/avatar/1.jpg"
          className={classes.bigAvatar}
        /> */}
        {comment.author.avatarImage ? (
          <Avatar
            alt={comment.author.id}
            src={comment.author.avatarImage}
            className={classes.bigAvatar}
          />
        ) : (
          <Avatar className={classes.bigAvatar}>TRM</Avatar>
        )}
      </Grid>
      <Grid item xs={2}>
        <Typography className={classes.typography} variant="body2">
          {moment(comment.createdAt).format('hh:ss DD/MM/YYYY')}
        </Typography>
        <Typography className={classes.typography} variant="subtitle2">
          {comment.author.userName}
        </Typography>
      </Grid>
      <Grid item xs={7}>
        <Typography className={classes.typography} variant="subtitle2">
          <Like className={classes.icon} />
          {comment.likes.length}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <CDforComment
          title="Do you really want to remove this comment?"
          mutationQuery={REMOVE_COMMENT}
          refetchQ={GET_GROUP_UPDATES}
          groupId={groupId}
          id={comment.id}
          size={size}
        />
        <Button onClick={() => props.history.push(`/edit/comment/${groupId}/${comment.id}`)}>
          <Edit />
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.typography} variant="body2">
          {ReactHtmlParser(comment.message)}
        </Typography>
      </Grid>
    </Grid>
  ));
}

export const DetailsComments = withStyles(styles)(withRouter(Details));
