import React from 'react';
import ReactPlayer from 'react-player';
import { Grid } from '@material-ui/core';
import { Query } from 'react-apollo';
import { GET_ATR_VIDEO } from 'queries';
import { Message } from 'shared/Message';

function Video(props) {
  const { video, message } = props;
  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      style={{ padding: 16 }}
      spacing={16}
    >
      <Grid item xs={6}>
        <Message message={message} />
      </Grid>
      {video.eventId ? (
        <Query
          query={GET_ATR_VIDEO}
          variables={{
            eventId: video.eventId,
          }}
        >
          {({ loading, error, data }) => {
            if (loading)
              return <img width={426} src="/assets/colourised-float-logo.gif" alt="Loading" />;
            return (
              <Grid item xs={6}>
                <ReactPlayer url={data.getATRStreamingURL} controls width="100%" height="67%" />
              </Grid>
            );
          }}
        </Query>
      ) : (
        <Grid item xs={6}>
          <ReactPlayer url={video.path} controls width="100%" height="67%" />
        </Grid>
      )}
    </Grid>
  );
}

export const DetailsVideoReplay = Video;
