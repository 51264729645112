import React, { Component } from "react";

import { withRouter, Link, Route } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";

import { styles } from "shared/ViewStyles";
import HorseView from "./HorseView";
import ManagerView from "./ManagerView";
import OrganisationView from "./OrganisationView";

class Performance extends Component {
  state = {
    value: "",
  };

  componentDidMount() {
    this.setState({
      value: this.props.history.location.pathname,
    });
  }

  componentDidUpdate() {
    if (this.state.value !== this.props.history.location.pathname)
      this.setState({
        value: this.props.history.location.pathname,
      });
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { classes } = this.props;
    const { value } = this.state;

    return (
      <Paper className={classes.paper}>
        <Tabs
          value={value}
          onChange={this.handleChange}
          classes={{
            root: classes.tabsRoot,
            indicator: classes.tabsIndicator,
          }}
          centered
        >
          {/* <Tab
            label={`Horse View`}
            disableRipple
            component={Link}
            to="/performance/horseView"
            value={`/performance/horseView`}
            classes={{
              root: classes.tabRoot,
              selected: classes.tabSelected,
            }}
          /> */}
          <Tab
            label={`Manager View`}
            disableRipple
            component={Link}
            to="/performance/managerView"
            value={`/performance/managerView`}
            classes={{
              root: classes.tabRoot,
              selected: classes.tabSelected,
            }}
          />
          <Tab
            label={`Organisation View`}
            disableRipple
            component={Link}
            to="/performance/organisationView"
            value={`/performance/organisationView`}
            classes={{
              root: classes.tabRoot,
              selected: classes.tabSelected,
            }}
          />
        </Tabs>

        {/* <Route
          exact
          path="/performance/horseView"
          render={() => <HorseView />}
        /> */}
        <Route
          exact
          path="/performance/managerView"
          render={() => <ManagerView />}
        />
        <Route
          exact
          path="/performance/organisationView"
          render={() => <OrganisationView />}
        />
      </Paper>
    );
  }
}

export default withRouter(withStyles(styles)(Performance));
