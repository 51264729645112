import React, { useState } from "react";

import { useQuery } from "react-apollo";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Box, Divider } from "@material-ui/core";
import { Paper } from "@material-ui/core";

import { Notification } from "shared/Notification";
import { Loader } from "shared/Loader";

import { HorsesTab } from "./Horses/Horses";
import { OverviewTab } from "./Overview/Overview";
import { UsersTab } from "./Users/Users";
import { HistoricHorsesTab } from "./Historic/HistoricHorses";

import { daysInRange } from "./Utils/accountingHelpers";
import { SearchFilter } from "./Utils/SearchFilter";

import {
  GET_ALL_ORGANIZATIONS,
  GET_ACCOUNTING_ORGANIZATION,
  GET_USERHORSES_BY_GROUP_ID,
  GET_HORSES_BY_GROUP_ID,
  GET_HISTORICAL_HORSES,
} from "queries";

import useStyles from "./styles";
import {
  formateOverviewTableData,
  formatHistoricalHorsesTableData,
} from "./Utils/tableMappers";

const Accounting = () => {
  const classes = useStyles();

  const [activeTab, setActiveTab] = useState(0);

  const [selectedOrgId, setSelectedOrgId] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);

  const [horseCost, setHorseCost] = useState('0');
  const [agreedHorseDays, setAgreedHorseDays] = useState('0');
  const [annualCostUser, setAnnualCostUsers] = useState('0');
  const [agreedNumberUser, setAgreedNumberUser] = useState('0');

  const [dateToValue, setDateToValue] = useState(new Date());
  const [dateFromValue, setDateFromValue] = useState(new Date());

  const [horseFilterData, setHorseFilterData] = useState({});
  const [userFilterData, setUserFilterData] = useState({});
  const [historicalHorsesFilterData, setHistoricalHorsesFilterData] = useState({});

  const totalDays = daysInRange(dateFromValue, dateToValue)

  const {
    data: allOrgsData,
    error: allOrgsError,
    loading: allOrgsLoading
  } = useQuery(GET_ALL_ORGANIZATIONS);


  // ORG DATA CALL
  const { data: orgaizationData, error: organizationError } = useQuery(
    GET_ACCOUNTING_ORGANIZATION,
    {
      variables: { id: selectedOrgId, includeDeleted: true },
      skip: !selectedOrgId
    }
  );

  const selectedGroupId = (selectedGroup ? selectedGroup.id : orgaizationData?.getOrganizationById?.groups.map(group => group.id)) ?? [];

  const { data: horsesData, error: horsesError } = useQuery(
    GET_HORSES_BY_GROUP_ID,
    {
      variables: {
        groupId: selectedGroupId,
        horseId: horseFilterData.id,
        startDate: dateFromValue,
        endDate: dateToValue,
        annualCostPerHorse: horseCost,
        agreedNumberPerDay: agreedHorseDays
      },
      skip: !orgaizationData
    }
  );

  const { data: usersAndHorsesData, error: usersAndHorsesError } = useQuery(
    GET_USERHORSES_BY_GROUP_ID,
    {
      variables: {
        groupId: selectedGroupId,
        username: userFilterData.name,
        startDate: dateFromValue,
        endDate: dateToValue,
        annualCostPerUser: annualCostUser,
        agreedNumberPerDay: agreedNumberUser
      },
      skip: !orgaizationData
    }
  );

  const { data: historicalHorsesData, error: historicalHorsesError } = useQuery(
    GET_HISTORICAL_HORSES,
    {
      variables: { id: selectedGroupId },
      skip: !orgaizationData
    }
  );

  const userTableData = usersAndHorsesData?.getGroupAccountingUserHorses.userHorses ?? []
  const horseTableData = horsesData?.getGroupAccountingHorses.horses ?? []

  const historicalHorsesTableData = formatHistoricalHorsesTableData(
    historicalHorsesData?.getHistoricHorsesByGroupID ?? [],
    { name: historicalHorsesFilterData.name }
  );

  const overviewTableData = formateOverviewTableData(
    orgaizationData?.getOrganizationById?.groups ?? [],
    {
      organizationName: orgaizationData?.getOrganizationById?.name,
      breedingUpdates: orgaizationData?.getOrganizationById?.breedingTile,
      groupName: selectedGroup?.name,
    }
  )

  return (
    <>
      {allOrgsError && (
        <Notification
          variant="error"
          message="Something went wrong with loading the Organisations"
        />
      )}
      {usersAndHorsesError && !allOrgsError && (
        <Notification
          variant="error"
          message="Something went wrong when loading Users"
        />
      )}
      {horsesError && !allOrgsError && (
        <Notification
          variant="error"
          message="Something went wrong when loading Horses"
        />
      )}
      {historicalHorsesError && !allOrgsError && (
        <Notification
          variant="error"
          message="Something went wrong when loading Historical Horses"
        />
      )}
      {organizationError && !allOrgsError && (
        <Notification
          variant="error"
          message="Something went wrong when loading Organization"
        />
      )}
      <Box display="flex" justifyContent="center">
        {allOrgsLoading ? (
          <Loader />
        ) : (
          <Paper
            elevation={0}
            style={{
              paddingLeft: "30px",
              paddingRight: "30px",
              maxWidth: activeTab === 0 ? 1180 : 1310
            }}
          >
            <Tabs
              value={activeTab}
              onChange={(_, newValue) => setActiveTab(newValue)}
              TabIndicatorProps={{
                style: {
                  background: "#000000",
                  height: 3,
                  fontFamily: "Gill Sans",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "16px",
                  lineHeight: "19px",
                  color: "#000000",
                  paddingLeft: "10px"
                }
              }}
            >
              <Tab
                label="Overview"
                style={{
                  fontWeight: activeTab === 0 ? 700 : 600
                }}
                className={classes.tabPanel}
              />
              <Tab
                label="Horses"
                style={{
                  fontWeight: activeTab === 1 ? 700 : 600
                }}
                className={classes.tabPanel}
              />
              <Tab
                label="Users"
                style={{
                  fontWeight: activeTab === 2 ? 700 : 600
                }}
                className={classes.tabPanel}
              />
              <Tab
                label="Historic Horses"
                style={{
                  fontWeight: activeTab === 3 ? 700 : 600
                }}
                className={classes.tabPanel}
              />
            </Tabs>
            <Divider className="divider" />
            <SearchFilter
              tab={activeTab}
              orgData={allOrgsData?.getAllOrganizations ?? []}
              groupData={orgaizationData?.getOrganizationById?.groups ?? []}
              setSelectedOrg={({ id }) => setSelectedOrgId(id)}
              setSelectedGroup={setSelectedGroup}
              totalDays={totalDays}
              dateToValue={dateToValue}
              setDateToValue={setDateToValue}
              dateFromValue={dateFromValue}
              setDateFromValue={setDateFromValue}
              selectedGroup={selectedGroup}
              horseTableData={horseTableData}
              horseFilterData={horseFilterData}
              setHorseFilterData={setHorseFilterData}
              userTableData={userTableData}
              userFilterData={userFilterData}
              setUserFilterData={setUserFilterData}
              historicalHorsesTableData={historicalHorsesTableData}
              historicalHorsesFilterData={historicalHorsesFilterData}
              setHistoricalHorsesFilterData={setHistoricalHorsesFilterData}
              disableGroup={!orgaizationData}
            />
            <TabPanel value={activeTab} index={0}>
              <OverviewTab
                horseCost={horseCost}
                setHorseCost={setHorseCost}
                agreedHorseDays={agreedHorseDays}
                setAgreedHorseDays={setAgreedHorseDays}
                totalHorseDays={horsesData?.getGroupAccountingHorses.totalDays ?? 0}
                agreedCost={horsesData?.getGroupAccountingHorses.agreedCost ?? 0}
                avgHorsesPerDay={horsesData?.getGroupAccountingHorses.averagePerDay ?? 0}
                horseDifferenceAmount={horsesData?.getGroupAccountingHorses.difference ?? 0}
                annualCostUser={annualCostUser}
                setAnnualCostUsers={setAnnualCostUsers}
                agreedNumberUser={agreedNumberUser}
                setAgreedNumberUser={setAgreedNumberUser}
                selectedGroup={selectedGroup}
                tableData={overviewTableData}
                totalUserSubs={usersAndHorsesData?.getGroupAccountingUserHorses.totalDays ?? 0}
                avgUserSubsPerDay={usersAndHorsesData?.getGroupAccountingUserHorses.averagePerDay ?? 0}
                userDifferenceAmount={usersAndHorsesData?.getGroupAccountingUserHorses.difference ?? 0}
                agreedUserCost={usersAndHorsesData?.getGroupAccountingUserHorses.agreedCost ?? 0}
                totalUsers={userTableData.length}
              />
            </TabPanel>
            <TabPanel value={activeTab} index={1}>
              <HorsesTab
                horseCost={horseCost}
                setHorseCost={setHorseCost}
                agreedHorseDays={agreedHorseDays}
                setAgreedHorseDays={setAgreedHorseDays}
                totalHorseDays={horsesData?.getGroupAccountingHorses.totalDays ?? 0}
                avgHorsesPerDay={horsesData?.getGroupAccountingHorses.averagePerDay ?? 0}
                horseDifferenceAmount={horsesData?.getGroupAccountingHorses.difference ?? 0}
                agreedCost={horsesData?.getGroupAccountingHorses.agreedCost ?? 0}
                selectedGroup={selectedGroup}
                totalDays={totalDays}
                dateToValue={dateToValue}
                dateFromValue={dateFromValue}
                tableData={horseTableData}
              />
            </TabPanel>
            <TabPanel value={activeTab} index={2}>
              <UsersTab
                annualCostUser={annualCostUser}
                setAnnualCostUsers={setAnnualCostUsers}
                agreedNumberUser={agreedNumberUser}
                setAgreedNumberUser={setAgreedNumberUser}
                selectedGroup={selectedGroup}
                tableData={userTableData}
                totalUserSubs={usersAndHorsesData?.getGroupAccountingUserHorses.totalDays ?? 0}
                avgUserSubsPerDay={usersAndHorsesData?.getGroupAccountingUserHorses.averagePerDay ?? 0}
                userDifferenceAmount={usersAndHorsesData?.getGroupAccountingUserHorses.difference ?? 0}
                agreedUserCost={usersAndHorsesData?.getGroupAccountingUserHorses.agreedCost ?? 0}
                totalUsers={userTableData.length}
              />
            </TabPanel>
            <TabPanel value={activeTab} index={3}>
              <HistoricHorsesTab tableData={historicalHorsesTableData} />
            </TabPanel>
          </Paper>
        )}
      </Box>
    </>
  );
};

const TabPanel = ({ value, index, other, children }) => {
  return (
    <div
      hidden={value !== index}
      role="tabpanel"
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

export default Accounting;
