import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(() => ({
  divider: {
    backgroundColor: "#13223A !important"
  },
  textHighlight: {
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "19px",
    color: "#F08496",
    fontFamily: "Gill sans"
  },
  text: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "18px",
    color: "#808080",
    fontFamily: "Gill Sans"
  },
  smallText: {
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "14px",
    color: "rgba(128, 128, 128, 0.5)"
  },
  gridItem: {
    padding: "12px"
  },
  panelItemSpacing: {
    padding: "20px 0px 20px 0px"
  },
  downloadButton: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "18px",
    color: "#13233A",
    textTransform: "capitalize !important",
    padding: "0 !important"
  }
}));
