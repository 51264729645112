import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import differenceBy from 'lodash.differenceby';
import { graphql } from 'react-apollo';
import {
  withStyles,
  Grid,
  MenuItem,
  TextField,
  Button,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { GET_GROUP_HORSES_SELECTOR, GET_GROUP_MEMBERS, ADD_MEMBER } from 'queries';
import { Autosuggest, SubmitButton, Notification } from 'shared';

const styles = () => ({
  horse: {
    marginTop: 16,
  },
  root: {
    overflow: 'auto',
    scrollBehavior: 'smooth',
    maxHeight: 'calc(100vh - 400px)',
    '&::-webkit-scrollbar': {
      width: '0em',
    },
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
});

class HorsesToAdd extends Component {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    errors: null,
    horses: [],
  };

  // eslint-disable-next-line react/sort-comp
  submitHander = event => {
    const {
      match: {
        params: { id },
      },
      user,
      mutate,
      history,
      manageAllHorses,
    } = this.props;
    const { horses } = this.state;
    event.preventDefault();
    const submitHorses = horses
      .filter(h => h.role)
      .map(horse => ({
        horseId: horse.horseId,
        shares: horse.role === 'guest' ? 0 : parseFloat(horse.shares) || 0,
        role: horse.role,
      }));
    const { userId, role } = user;
    mutate({
      variables: {
        horses: submitHorses,
        userId,
        role,
        id,
        manageAllHorses,
      },
      refetchQueries: [{ query: GET_GROUP_MEMBERS, variables: { id } }],
    })
      .then(() => history.push(`/groups/${id}/view/members`))
      .catch(res => {
        this.setState({ errors: localStorage.getItem('error') });
      })
      .then(setTimeout(() => this.setState({ errors: null })), 2000);
  };

  handleChange = (name, index, isChecked = false) => event => {
    const { value, checked } = event.target;
    this.setState(prevState => {
      const newHorses = [...prevState.horses];
      newHorses[index][name] = isChecked ? checked : value;
      return { horses: newHorses };
    });
  };

  handleChangeAutosuggest = (name, index) => event => {
    const value = event ? event.value : '';
    this.setState(prevState => {
      const newHorses = [...prevState.horses];
      newHorses[index][name] = value;
      newHorses[index].maxShares = event ? event.maxShares : 0;
      newHorses[index].selectedOption = event;
      return { horses: newHorses };
    });
  };

  handleAddHorse = () => {
    this.setState(state => ({
      horses: state.horses.concat({
        horseId: '',
        role: '',
        shares: '',
        maxShares: '0',
        selectedOption: null,
      }),
    }));
  };

  handleRemoveElement = index => {
    this.setState(state => ({
      horses: [...state.horses.slice(0, index), ...state.horses.slice(index + 1)],
    }));
  };

  componentDidUpdate(prevProps, prevState) {
    const { horses } = this.state;
    const { manageAllHorses, data, setManageAllHorses } = this.props;
    const dataHorseLength = data && data.getGroupById && data.getGroupById.horses.length;
    if (prevState.horses.length < horses.length) {
      const objDiv = document.getElementById('scroll');
      objDiv.scrollTop = objDiv.scrollHeight;
    }
    if (prevState.horses.length !== horses.length) {
      if (dataHorseLength > horses.filter(h => h.role).length) {
        setManageAllHorses(false);
      }
    }
    if (prevProps.manageAllHorses !== manageAllHorses && manageAllHorses) {
      const allHorseOptions = !data.loading
        ? data.getGroupById.horses.map(item => ({
            value: item.id,
            horseId: item.id,
            label: `${item.name} - ${item.maxShares}%`,
            maxShares: item.maxShares,
            role: 'manager',
            selectedOption: {
              value: item.id,
              horseId: item.id,
              label: `${item.name} - ${item.maxShares}%`,
              maxShares: item.maxShares,
              role: 'manager',
            },
          }))
        : [];
      const horseOptions = differenceBy(allHorseOptions, horses, 'horseId');
      this.setState(state => ({
        horses: state.horses.concat(horseOptions).filter(h => h.role),
      }));
    }
  }

  render() {
    const {
      classes,
      data,
      user: { role: userRole },
      manageAllHorses,
    } = this.props;
    const { horses, errors } = this.state;
    const { loading, error } = data;
    const allHorseOptions = !loading
      ? data.getGroupById.horses.map(item => ({
          value: item.id,
          horseId: item.id,
          label: `${item.name} - ${item.maxShares}%`,
          maxShares: item.maxShares,
        }))
      : [];
    const horseOptions = differenceBy(allHorseOptions, horses, 'horseId');
    return (
      <>
        <form onSubmit={this.submitHander}>
          <Grid container className={classes.root} id="scroll">
            {horses.map((horse, index) => {
              const { role, horseId, shares, maxShares, selectedOption } = horse;
              return (
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                  className={classes.horse}
                  key={horseId}
                >
                  <Grid item xs={10} sm={10}>
                    <Autosuggest
                      selectedOption={selectedOption}
                      options={horseOptions}
                      handleChange={this.handleChangeAutosuggest('horseId', index)}
                      placeholder="Search a horse..."
                    />
                  </Grid>
                  <Grid item xs={10} sm={10}>
                    <Grid
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                      spacing={16}
                    >
                      <Grid item xs={6} sm={6}>
                        <TextField
                          id="Role"
                          required={horseId}
                          fullWidth
                          select
                          label="Role"
                          value={role}
                          onChange={this.handleChange('role', index)}
                          SelectProps={{
                            MenuProps: {
                              className: classes.menu,
                            },
                          }}
                          margin="normal"
                        >
                          <MenuItem value="owner">Owner</MenuItem>
                          <MenuItem value="trainer">Trainer</MenuItem>
                          <MenuItem value="guest">Guest</MenuItem>
                          <MenuItem
                            value="manager"
                            disabled={userRole === 'member' || manageAllHorses}
                          >
                            Manager
                          </MenuItem>
                        </TextField>
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          fullWidth
                          required={horseId}
                          disabled={role === 'guest' || role === 'manager'}
                          label="Horse Shares"
                          type="number"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">{maxShares}%</InputAdornment>
                            ),
                          }}
                          placeholder="Maximum shares:"
                          error={parseFloat(shares) > parseFloat(maxShares)}
                          helperText={error ? 'Too big value!' : null}
                          value={shares}
                          onChange={this.handleChange('shares', index)}
                          margin="normal"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <IconButton
                    className={classes.button}
                    aria-label="Delete"
                    style={{ marginTop: -48 }}
                    onClick={() => this.handleRemoveElement(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              );
            })}
          </Grid>
          <Grid container direction="row" justify="space-between" alignItems="center">
            <Button onClick={this.handleAddHorse} style={{ marginTop: 30 }} color="secondary" variant="contained">
              Add a Horse
            </Button>
            <SubmitButton>Add Member</SubmitButton>
          </Grid>

          {errors || error ? <Notification message={errors || error} variant="error" /> : null}
        </form>
      </>
    );
  }
}

HorsesToAdd.propTypes = {
  match: PropTypes.object,
  classes: PropTypes.object,
  data: PropTypes.object,
  user: PropTypes.object,
  mutate: PropTypes.func,
  history: PropTypes.object,
  manageAllHorses: PropTypes.bool,
  setManageAllHorses: PropTypes.func,
};

export default graphql(ADD_MEMBER)(
  graphql(GET_GROUP_HORSES_SELECTOR, {
    options: props => {
      const { id } = props;
      return {
        variables: { id },
      };
    },
  })(withRouter(withStyles(styles)(HorsesToAdd))),
);

// If there is a need, there is a way to set specific relation for users, mb with the predefined default value, which you could change
