import {
  Box,
  Divider,
  Grid,
  Typography,
  withStyles,
  Button,
} from "@material-ui/core";
import { Notification } from "shared";
import { GET_STATISTICS } from "queries";
import React from "react";
import { Query } from "react-apollo";
import { Link, withRouter } from "react-router-dom";
import styles from "./styles";

function HomePage(props) {
  const { classes } = props;
  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <Grid item xs={12}>
        <Typography variant="h2" className={classes.typography}>
          Welcome!
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <img
          className={classes.gif}
          src="https://s3-eu-west-1.amazonaws.com/trm-koa/assets/colourised-float-logo.gif"
          alt="Logo"
        />
      </Grid>
      <Grid item xs={12} className={classes.createOrgContainer}>
        <Button
          variant="outlined"
          color="primary"
          type="submit"
          className={classes.createOrgButton}
          component={Link}
          to="/organizations/create?flow=true"
        >
          Create an Organisation
        </Button>
      </Grid>
      <Divider className={classes.divider} />
      <Query query={GET_STATISTICS}>
        {({ loading, error, data }) => {
          if (loading) return null;
          if (error)
            return <Notification message={error.message} variant="error" />;
          const { getAllUsers, getAllHorses, getAllGroups, getCountOfHorses } = data;
          return (
            <>
              <Typography variant="h4" className={classes.typography}>
                Last Statistics:
              </Typography>
              <Grid container justify="space-between" spacing={2}>
                <Grid item xs={4}>
                  <Typography
                    variant="h6"
                    component={Link}
                    to="/users"
                    className={classes.statisticsTypography}
                  >
                    Users: {getAllUsers.length}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    variant="h6"
                    component={Link}
                    to="/groups"
                    className={classes.statisticsTypography}
                  >
                    Groups: {getAllGroups.length}
                    <br />
                    Trial: {getAllGroups.filter((g) => g.trial).length}
                  </Typography>
                </Grid>
                <Grid item xs={4} container justify="space-between" spacing={4}>
                  <Grid item xs={6}>
                    <Typography
                      variant="h6"
                      component={Link}
                      to="/horses"
                      className={classes.statisticsTypography}
                    >
                      Horses: {getAllHorses.length}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Box variant="h6" className={classes.box}>
                      UST Horses: {getCountOfHorses.countOfUST}
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box variant="h6" className={classes.box}>
                      FG Horses: {getCountOfHorses.countOfFG}
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box variant="h6" className={classes.box}>
                      TF Horses: {getCountOfHorses.countOfTF}
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box variant="h6" className={classes.box}>
                      Unlinked Horses: {getCountOfHorses.countOfUnlinked}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </>
          );
        }}
      </Query>
      <Divider className={classes.divider} />
    </Grid>
  );
}

export default withStyles(styles)(HomePage);
