import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import MaterialTable from 'material-table';
import Like from '@material-ui/icons/Favorite';
import CommentIcon from '@material-ui/icons/Comment';

import { REMOVE_UPDATE, GET_ORGANIZATION_UPDATES } from 'queries';
import {
  Notification,
  TableLoader,
  CDforList,
  DetailsEntriesAndDeclarations,
  DetailsMessage,
  DetailsComments,
  DetailsMultiple,
  DetailsVideo,
  DetailsVideoReplay,
  DetailsAudio,
  DetailsImage,
  DetailsMasterRating,
  DetailsEvent,
  DetailsPoll,
} from 'shared';
import { Avatar } from '@material-ui/core';

class List extends Component {
  state = {
    open: false,
    id: null,
  };

  render() {
    const { loading, error, getOrganizationById } = this.props.data;
    if (loading) return <TableLoader />;
    if (error) return <Notification message={error.message} variant="error" />;

    const arr = [];
    const { updates, id } = getOrganizationById;
    updates.updates.map(update =>
      arr.push({
        ...update,
        author: update.author ? update.author.userName : 'The Racing Manager',
        avatarImage: update.author ? update.author.avatarImage : null,
        horse: update.horse ? update.horse.name : null,
        horseId: update.horse ? update.horse.id : null,
        date: update.createdAt? update.createdAt : Date(),
        seenbys: update.seenBy ? update.seenBy.length : 0,
        likes: update.likes.length,
        commentsCount: update.comments.length,
      }),
    );

    const details = data => ({
      text: <DetailsMessage message={data.message} />,
      masterRatingUpdated: <DetailsMasterRating data={data.rating} />,
      timeformEntryAdded: <DetailsEntriesAndDeclarations data={data.entry} />,
      image: <DetailsImage attachments={data.attachments} message={data.message} />,
      video: <DetailsVideo attachments={data.attachments} message={data.message} />,
      audio: <DetailsAudio attachments={data.attachments} message={data.message} />,
      multiplemedia: <DetailsMultiple attachments={data.attachments} message={data.message} />,
      poll: <DetailsPoll attachments={data.attachments} message={data.message} poll={data.poll} />,
      timeformDeclarationAdded: <DetailsEntriesAndDeclarations data={data.declaration} />,
      event: <DetailsEvent data={data.event} />,
      videoReplayAdded: <DetailsVideoReplay video={data.video} message={data.message} />,
      timeformPerformanceAdded: null,
      timeformPerspectiveComment: null,
      arionRunnerAdded: null,
      arionResultAdded: null,
      timeformAnalystVerdict: null,
    });
    return (
      <>
        <CDforList
          open={this.state.open}
          title="Do you really want to remove this update?"
          mutationQuery={REMOVE_UPDATE}
          options={{
            variables: {
              id: this.state.id,
              groupId: this.props.id,
            },
            refetchQueries: [
              {
                query: GET_ORGANIZATION_UPDATES,
                variables: { id: this.props.id, size: this.props.size },
              },
            ],
          }}
          handleClose={() => {
            this.setState({ open: false });
          }}
        />
        <MaterialTable
          actions={[
            {
              isFreeAction: true,
              icon: 'add_circle',
              tooltip: 'Create Update',
              onClick: (event, rowData) => {
                this.props.history.push(
                  `/create-organization-update/${this.props.id}/${this.props.size}`,
                );
              },
            },
            {
              icon: 'edit_circle',
              tooltip: 'Edit Update message',
              onClick: (event, rowData) => {
                this.props.history.push(`/edit/update/${this.props.id}/${rowData.id}`);
              },
            },
            {
              icon: 'delete_forever',
              tooltip: 'Remove update',
              onClick: (event, rowData) => {
                this.setState({ id: rowData.id, open: true });
              },
            },
          ]}
          headerStyle={{
            backgroundColor: 'white',
          }}
          options={{
            grouping: true,
            pageSizeOptions: [5, 10, 20, arr.length],
            columnsButton: true,
            pageSize: 10,
            actionsColumnIndex: -1,
            showEmptyDataSourceMessage: true,
            exportButton: true,
          }}
          columns={[
            {
              title: 'Avatar',
              field: 'avatarImage',
              render: rowData =>
                rowData.avatarImage ? (
                  <Avatar alt={rowData.id} src={rowData.avatarImage} styles={{ margin: 10 }} />
                ) : (
                  <Avatar
                    styles={{
                      margin: 10,
                    }}
                  >
                    TRM
                  </Avatar>
                ),
            },
            {
              title: 'ID',
              field: 'id',
              defaultSort: 'desc',
            },
            {
              title: 'Author',
              field: 'author',
            },
            {
              title: 'Type',
              field: 'type',
              lookup: {
                poll: 'Poll',
                event: 'Event',
                image: 'Image',
                video: 'Video',
                audio: 'Audio',
                text: 'Message',
                multiplemedia: 'Multiple Media',
                masterRatingUpdated: 'Master Rating Update',
                timeformEntryAdded: 'TimeForm Entry',
                timeformDeclarationAdded: 'TimeForm Declaration',
                videoReplayAdded: 'Video Replay',
                timeformPerformanceAdded: 'Timeform Performance added',
                timeformPerspectiveComment: 'Timeform Perspective Comment',
                arionRunnerAdded: 'Arion Runner Added',
                arionResultAdded: 'Arion Result Added',
                timeformAnalystVerdict: 'Timeform Analyst Verdict',
              },
            },
            {
              title: "Horse",
              field: "horse"
            },
            {
              title: "Date",
              field: "date",
              render: rowData => {
                const date = new Date(rowData.date);
                return date.toLocaleString('en-GB', {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: false,
                });
              },
            },
            {
              title: "SeenBy's",
              field: "seenbys"
            },
            {
              title: 'Likes',
              field: 'likes',
              render: rowData => (
                <>
                  <Like
                    style={{
                      height: 15,
                      margin: '-2px 0',
                      color: '#ec5c74',
                    }}
                  />
                  {rowData.likes}
                </>
              ),
            },
            {
              title: 'Comments',
              field: 'commentsCount',
              render: rowData => (
                <>
                  <CommentIcon
                    style={{
                      height: 15,
                      margin: '-2px 0',
                      color: '#ec5c74',
                    }}
                  />
                  {rowData.commentsCount}
                </>
              ),
            },
          ]}
          detailPanel={[
            {
              icon: 'expand_more',
              openIcon: 'expand_less',
              tooltip: 'Show Details',
              render: rowData => details(rowData)[rowData.type],
            },
            {
              icon: 'comment',
              openIcon: 'message',
              tooltip: 'Show Comments',
              render: rowData => {
                return (
                  <DetailsComments
                    data={rowData.comments}
                    updateId={rowData.id}
                    size={this.props.size}
                    groupId={id}
                  />
                );
              },
            },
          ]}
          data={arr}
          title="Updates"
          onRowClick={(event, rowData, togglePanel) => togglePanel(0)}
        />
      </>
    );
  }
}

export const OrganizationUpdates = graphql(GET_ORGANIZATION_UPDATES, {
  options: props => {
    const { id, size } = props;
    return {
      variables: { id, size },
    };
  },
})(withRouter(List));
