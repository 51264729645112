import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

import { LOGIN } from 'queries';
import { withRouter } from 'react-router-dom';
import { graphql } from 'react-apollo';

import { Notification } from 'shared';
import styles from './styles';

class Login extends Component {
  state = {
    email: '',
    password: '',
  };

  submitHander = event => {
    const { email, password } = this.state;
    event.preventDefault();
    this.props
      .mutate({
        variables: {
          password,
          email,
        },
        // refetchQueries: [{ query: GET_ALL_USERS }]
      })
      .then(() => this.props.history.push('/users'))
      .catch(res => {
        this.setState({ error: localStorage.getItem('error') });
      })
      .then(setTimeout(() => this.setState({ error: null })), 2000);
  };

  render() {
    const { classes } = this.props;
    const { error, password, email } = this.state;
    return (
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <h3 className={classes.title}>The Racing Manager: Admin Panel</h3>
          <Avatar className={classes.avatar}>
            <LockIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form className={classes.form} onSubmit={this.submitHander}>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="email">Email Address</InputLabel>
              <Input
                value={email}
                onChange={event => this.setState({ email: event.target.value })}
                id="email"
                name="email"
                autoComplete="email"
                autoFocus
              />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="password">Password</InputLabel>
              <Input
                value={password}
                onChange={event => this.setState({ password: event.target.value })}
                name="password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
            </FormControl>
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            <Button type="submit" fullWidth variant="contained" className={classes.submit}>
              Sign in
            </Button>
          </form>
        </Paper>
        {error ? <Notification message={error} variant="error" /> : null}
      </main>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default graphql(LOGIN)(withRouter(withStyles(styles)(Login)));
