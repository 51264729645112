import React, { Component } from 'react';
import { graphql, withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import MaterialTable from 'material-table';

import { REMOVE_HORSE, GET_USER_HORSES, REMOVE_USER_HORSE } from 'queries';
import { CDforList, Notification, TableLoader } from 'shared';

const linkURL =
  process.env.REACT_APP_API === 'dev'
    ? 'https://dev.theracingmanager.com'
    : 'https://theracingmanager.com';

class UserHorsesList extends Component {
  state = {
    open: false,
    horse: { group: {} },
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { id, data, history } = this.props;
    const { loading, error, getUserById = {} } = data;
    const { ownerships } = getUserById;
    const { horse, open } = this.state;
    if (loading) return <TableLoader />;
    if (error) return <Notification message={error.message} variant="error" />;
    const arr = [];
    ownerships.forEach(({ horses }) => horses.forEach(uH => arr.push({ ...uH, ...uH.horse })));
    return (
      <>
        <CDforList
          open={open}
          handleClose={this.handleClose}
          title={`Do you really want to remove ${horse.name} from this user?`}
          mutationQuery={REMOVE_USER_HORSE}
          options={{
            variables: { groupId: horse.group.id, memberId: id, horseId: horse.id },
            refetchQueries: [{ query: GET_USER_HORSES, variables: { id } }],
          }}
        />
        <MaterialTable
          actions={[
            {
              icon: 'visibility_circle',
              tooltip: 'Show Horse',
              onClick: (event, rowData) => {
                history.push(`/horses/${rowData.id}/view/info`);
              },
            },
            {
              icon: 'edit_circle',
              tooltip: 'Edit Horse Info',
              onClick: (event, rowData) => {
                history.push(`/horses/${rowData.id}/edit`);
              },
            },
            {
              icon: 'web_circle',
              tooltip: 'Open Horse page at TRM',
              onClick: (event, rowData) => {
                window.open(`${linkURL}/horse/${rowData.slug}`);
              },
            },
            rowData =>
              rowData.role !== 'manager' &&
              rowData.role !== 'org-manager' && {
                icon: 'delete_forever',
                tooltip: 'Remove horse from the user',
                onClick: (event, rowData) => {
                  this.setState({ horse: rowData, open: true });
                },
              },
          ]}
          options={{
            columnsButton: true,
            pageSize: 10,
            actionsColumnIndex: -1,
            showEmptyDataSourceMessage: true,
            exportButton: true,
            exportAllData: true,
            pageSizeOptions: [10, 20, arr.length],
          }}
          columns={[
            { title: 'ID', field: 'id', type: 'numeric' },
            { title: 'Name', field: 'name', defaultSort: 'asc' },
            { title: 'TimeForm ID', field: 'timeformId' },
            { title: 'Role', field: 'role' },
            { title: 'Shares', field: 'shares' },
          ]}
          data={arr}
          title="Horses"
        />
      </>
    );
  }
}

export default withApollo(
  graphql(REMOVE_HORSE)(
    graphql(GET_USER_HORSES, {
      options: props => {
        return {
          variables: { id: props.id },
        };
      },
    })(withRouter(UserHorsesList)),
  ),
);
