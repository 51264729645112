import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { createHttpLink } from 'apollo-link-http';

const afterwareLink = new ApolloLink((operation, forward) =>
  forward(operation).map(response => {
    const context = operation.getContext();
    const {
      response: { headers },
    } = context;
    if (headers) {
      const authorization = headers.get('Authorization');
      if (authorization) {
        const token = authorization.replace(/^JWT\s/, '');
        localStorage.setItem('token', token);
      }
    }
    return response;
  }),
);

const linkURL = `${process.env.REACT_APP_BASE_URL}/admin/graphql`;

const httpLink = createHttpLink({
  uri: linkURL,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token');
  if (token) {
    return { headers: { ...headers, Authorization: `JWT ${token}` } };
  }
  return { headers };
});

const resetToken = onError(({ networkError, graphQLErrors }) => {
  if (
    (networkError && networkError.statusCode === 401) ||
    (graphQLErrors && graphQLErrors[0].message === 'access denied')
  ) {
    localStorage.removeItem('token');
    window.location.href = '/login';
  } else {
    const error = graphQLErrors ? graphQLErrors[0].message : 'Something went wrong';
    localStorage.setItem('error', error);
  }
});

const authFlow = authLink.concat(resetToken);

const link = ApolloLink.from([afterwareLink, authFlow, httpLink]);
const client = new ApolloClient({
  // dataIdFromObject: o => o.id,
  link,
  cache: new InMemoryCache(),
});

export default client;
