import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import moment from 'moment';
import { useQuery } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Button from '@material-ui/core/Button';

import { GET_MANAGERS_PERFORMANCE } from 'queries';

const styles = {
  periodContainer: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'end',
    marginTop: '20px',
  },

  periodContainerTitle: {
    margin: '0 10px',
  },
};

function ManagerView(props) {
  const { classes } = props;
  const [tableData, setTableData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const { data, loading, error, refetch } = useQuery(GET_MANAGERS_PERFORMANCE, {
    variables: {
      startDate,
      endDate,
    },
  });

  useEffect(() => {
    if (data && data.getAllManagers) {
      const dataToSet = data.getAllManagers.map(m => {
        const horses = m.ownerships.filter(r => r.role === 'manager' || r.role === 'org-manager').map(h => h.horses.length);
        const group = m.lastPost && m.lastPost.horses && m.lastPost.isGroupUpdate;
        const horse = m.lastPost && m.lastPost.horses && !m.lastPost.isGroupUpdate;
        const organisation = m.lastPost && m.lastPost.organisation;
        return {
          name: `${m.firstName} ${m.surname}`,
          horses: m.ownerships ? horses.reduce((a, b) => a + b, 0) : '-',
          average: m.averagePostsPerHorse,
          lastPostDate: m.lastPost ? moment(m.lastPost.createdAt).format('DD-MM-YYYY HH:mm') : '-',
          lastPostAbout: organisation
            ? organisation.name
            : group
            ? m.lastPost.horses[0].group.name
            : horse
            ? m.lastPost.horses.map(h => h.name).join(', ')
            : '-',
          lastPostLikes: m.lastPost ? m.lastPost.likes.length : '-',
          lastPostComments: m.lastPost ? m.lastPost.comments.length : '-',
        };
      });
      setTableData(dataToSet);
    }
  }, [data]);

  const handleClearValue = () => {
    setEndDate(null);
    setStartDate(null);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className={classes.periodContainer}>
        <p className={classes.periodContainerTitle}>Selected Period: </p>
        <DatePicker
          label="Start Date"
          variant="inline"
          value={startDate}
          onChange={startDate =>
            setStartDate(
              moment(startDate)
                .startOf('day')
                .toString(),
            )}
          format="yyyy/MM/dd"
        />
        <DatePicker
          label="End Date"
          variant="inline"
          value={endDate}
          onChange={endDate =>
            setEndDate(
              moment(endDate)
                .endOf('day')
                .toString(),
            )}
          format="yyyy/MM/dd"
        />
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          style={{ marginTop: '12px' }}
          onClick={handleClearValue}
        >
          clear
        </Button>
      </div>
      <MaterialTable
        columns={[
          {
            title: 'Name',
            field: 'name',
          },
          {
            title: 'Number of Horses',
            field: 'horses',
          },
          {
            title: 'Average Posts/Horse',
            field: 'average',
          },
          {
            title: 'Last Post Date',
            field: 'lastPostDate',
          },
          {
            title: 'Last Post About',
            field: 'lastPostAbout',
          },
          {
            title: 'Last Post Likes',
            field: 'lastPostLikes',
          },
          {
            title: 'Last Post Comments',
            field: 'lastPostComments',
          },
        ]}
        options={{
          pageSize: 10,
          pageSizeOptions: [5, 10, 20, 40, 50, 100, tableData.length],
          exportButton: true,
          exportAllData: true,
        }}
        title="Managers View"
        data={tableData}
      />
    </MuiPickersUtilsProvider>
  );
}

export default withStyles(styles)(ManagerView);
