import React from 'react';
import { Typography } from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';

function Msg(props) {
  const { message } = props;
  return <Typography variant="subtitle2">Message: {ReactHtmlParser(message)}</Typography>;
}

export const Message = Msg;
