import React from 'react';
import MaskedInput from 'react-text-mask';

const DateFormattedInput = props => {
  const { inputRef, name, ...other } = props;
  let mask = [/[0-3]/, /[0-9]/, '/', /[0-1]/, /[0-9]/, '/', /[1-2]/, /[0-9]/, /[0-9]/, /[0-9]/];
  if (name === 'time') {
    mask = [/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/];
    // mask = [
    //   /[0-2]/,
    //   /[0-9]/,
    //   ':',
    //   /[0-5]/,
    //   /[0-9]/,
    //   ' ',
    //   '-',
    //   ' ',
    //   /[0-2]/,
    //   /[0-9]/,
    //   ':',
    //   /[0-5]/,
    //   /[0-9]/,
    // ];
  }
  if (name === 'ownershipPeriodEndDate') {
    mask = [/[0-3]/, /[0-9]/, '/', /[0-1]/, /[0-9]/];
  }

  return (
    <MaskedInput
      {...other}
      name={name}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={mask}
      placeholderChar={'\u2000'}
      keepCharPositions
    />
  );
};

export default DateFormattedInput;
