import gql from 'graphql-tag';

export const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      id
      email
    }
  }
`;

export const GET_STATISTICS = gql`
  {
    getAllUsers {
      id
    }
    getAllGroups {
      id
      trial
    }
    getAllHorses {
      id
      service
    }
    getCountOfHorses {
      countOfTF
      countOfFG
      countOfUST
      countOfUnlinked
    }
  }
`;

export const GET_ALL_ORGANIZATIONS = gql`
  {
    getAllOrganizations {
      id
      name
      slug
      logoImage
      sms
      personalise
      breedingTile
      groups {
        id
        name
      }
      horses {
        id
        name
        createdAt
        deletedAt
        service
        linkedAt
        groupId
      }
      managers {
        id
      }
      pageId
      updates {
        totalPages
        size
        totalSize
      }
    }
  }
`;

export const GET_ORGANIZATION = gql`
  query getOrganizationById($id: ID!) {
    getOrganizationById(id: $id) {
      id
      name
      slug
      sms
      multilingualEmailInvite
      personalise
      logoImage
      breedingTile
      tpdTile
      toteLink
      groups {
        id
        name
        slug
        sms
        createdAt
        deletedAt
      }
      managers {
        id
        organizationId
        userId
        user {
          id
        }
        role
      }
      pageId
      updates {
        totalPages
        size
        totalSize
      }
    }
  }
`;

export const GET_ORGANIZATION_GROUPS = gql`
  query getOrganizationGroups($id: ID!) {
    getOrganizationById(id: $id) {
      id
      groups {
        id
        name
        slug
        type
        period
        bhaApproved
        existing
        trial
        membershipPayedByUser
        logoImage
        country
        town
        district
        postCode
        address1
        address2
        maxOwners
        owners
        managers
        trainers
        guests
        invitations {
          id
        }
        members {
          userId
        }
        horses {
          id
        }
      }
    }
  }
`;

export const GET_ORGANIZATION_HORSES = gql`
  query getOrganizationHorses($id: ID!) {
    getOrganizationById(id: $id) {
      id
      horses {
        id
        name
        silkCode
        timeformHorse {
          silkCode
        }
        group {
          name
        }
        members {
          userGroupId
          userGroup {
            user {
              id
              email
              firstName
              surname
              userName
            }
          }
          role
          shares
        }
        pendingMembers {
          role
          shares
          invitation {
            id
            email
            firstName
            surname
            isManager
          }
        }
      }
    }
  }
`;

export const GET_ORGANIZATION_MANAGERS = gql`
  query getOrganizationManagers($id: ID!) {
    getOrganizationById(id: $id) {
      id
      managers {
        id
        user {
          id
          firstName
          surname
          userName
          email
        }
        role
      }
    }
  }
`;

export const CREATE_ORGANIZATION = gql`
  mutation CreateOrganization($name: String!) {
    createOrganization(name: $name) {
      id
    }
  }
`;

export const UPDATE_ORGANIZATION = gql`
  mutation updateOrganization(
    $id: ID
    $name: String
    $logoImage: String
    $sms: Boolean
    $multilingualEmailInvite: Boolean
    $personalise: Boolean
    $breedingTile: Boolean
    $tpdTile: Boolean
    $toteLink: Boolean
  ) {
    organization(id: $id) {
      edit(
        name: $name
        logoImage: $logoImage
        sms: $sms
        multilingualEmailInvite: $multilingualEmailInvite
        personalise: $personalise
        breedingTile: $breedingTile
        tpdTile: $tpdTile
        toteLink: $toteLink
      ) {
        id
        name
        logoImage
        sms
        multilingualEmailInvite
        personalise
        breedingTile
        tpdTile
        toteLink
      }
    }
  }
`;

export const DELETE_ORGANIZATION = gql`
  mutation DeleteOrganization($id: ID!) {
    organization(id: $id) {
      remove {
        id
        name
      }
    }
  }
`;

export const ADD_GROUP = gql`
  mutation AddGroup($id: ID!, $groupId: ID!) {
    organization(id: $id) {
      addGroup(groupId: $groupId) {
        id
      }
    }
  }
`;

export const GET_ALL_USERS = gql`
  {
    getAllUsers {
      id
      email
      firstName
      surname
      userName
      isContentManager
      avatarImage
      lastLogin
      lastSeenBy
      ownerships {
        groupId
        role
        horses {
          role
        }
      }
      totalHorses
      deviceToken
    }
  }
`;

export const GET_USER = gql`
  query getUserById($id: ID!) {
    getUserById(id: $id) {
      id
      email
      firstName
      surname
      userName
      isContentManager
      deviceToken
      birthDate
      phoneNumber
      pushNotification
      textNotification
      emailNotification
      userUpdateNots
      commentNots
      previewNots
      raceNots
      ustRaceResultNots
      ratingNots
      breedingNots
      formAlertNots
      auctionAlertNots
      preraceNots
      ustWorkoutNots
      icsNots
      turfTraxNots
      totalPerformanceDataNots
      country
      address1
      address2
      district
      town
      postCode
      marketing
    }
  }
`;

export const GET_USER_HORSES = gql`
  query getUserById($id: ID!) {
    getUserById(id: $id) {
      id
      ownerships {
        role
        group {
          id
        }
        horses {
          role
          shares
          horse {
            id
            name
            timeformId
            slug
            group {
              id
            }
          }
        }
      }
    }
  }
`;

export const GET_USER_GROUPS = gql`
  query getUserById($id: ID!) {
    getUserById(id: $id) {
      id
      ownerships {
        role
        group {
          id
          name
          type
        }
      }
    }
  }
`;

export const CREATE_USER = gql`
  mutation CreateUser(
    $firstName: String!
    $surname: String!
    $email: String!
    $userName: String!
    $password: String!
    $isContentManager: Boolean!
  ) {
    createUser(
      firstName: $firstName
      surname: $surname
      email: $email
      userName: $userName
      isContentManager: $isContentManager
      password: $password
    ) {
      id
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser(
    $id: ID!
    $firstName: String
    $surname: String
    $email: String
    $userName: String
    $phoneNumber: String
    $birthDate: String
    $pushNotification: Boolean
    $textNotification: Boolean
    $emailNotification: Boolean
    $userUpdateNots: Boolean
    $commentNots: Boolean
    $previewNots: Boolean
    $raceNots: Boolean
    $ustRaceResultNots: Boolean
    $ratingNots: Boolean
    $breedingNots: Boolean
    $formAlertNots: Boolean
    $auctionAlertNots: Boolean
    $preraceNots: Boolean
    $ustWorkoutNots: Boolean
    $icsNots: Boolean
    $turfTraxNots: Boolean
    $totalPerformanceDataNots: Boolean
    $isContentManager: Boolean
    $address1: String
    $address2: String
    $country: String
    $district: String
    $town: String
    $postCode: String
    $marketing: Boolean
  ) {
    user(id: $id) {
      edit(
        firstName: $firstName
        surname: $surname
        email: $email
        userName: $userName
        phoneNumber: $phoneNumber
        birthDate: $birthDate
        pushNotification: $pushNotification
        textNotification: $textNotification
        emailNotification: $emailNotification
        userUpdateNots: $userUpdateNots
        commentNots: $commentNots
        previewNots: $previewNots
        raceNots: $raceNots
        ustRaceResultNots: $ustRaceResultNots
        ratingNots: $ratingNots
        breedingNots: $breedingNots
        formAlertNots: $formAlertNots
        auctionAlertNots: $auctionAlertNots
        preraceNots: $preraceNots
        ustWorkoutNots: $ustWorkoutNots
        icsNots: $icsNots
        turfTraxNots: $turfTraxNots
        totalPerformanceDataNots: $totalPerformanceDataNots
        isContentManager: $isContentManager
        address1: $address1
        address2: $address2
        country: $country
        district: $district
        town: $town
        postCode: $postCode
        marketing: $marketing
      ) {
        id
        firstName
        surname
        email
        userName
        phoneNumber
        birthDate
        pushNotification
        textNotification
        emailNotification
        userUpdateNots
        commentNots
        previewNots
        raceNots
        ustRaceResultNots
        ratingNots
        breedingNots
        formAlertNots
        auctionAlertNots
        preraceNots
        ustWorkoutNots
        icsNots
        turfTraxNots
        totalPerformanceDataNots
        isContentManager
        address1
        address2
        country
        district
        town
        postCode
        marketing
      }
    }
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUser($id: ID!) {
    user(id: $id) {
      remove
    }
  }
`;

// =================================
//               GROUPS
// =================================

export const GET_ALL_GROUPS = gql`
  {
    getAllGroups {
      id
      name
      bhaApproved
      existing
      trial
      type
      period
      address1
      country
      town
      membershipPayedByUser
      members {
        user {
          id
        }
      }
      horses {
        id
      }
    }
  }
`;

export const GET_GROUPS_SELECTOR = gql`
  {
    getAllGroups {
      id
      name
    }
  }
`;

export const GET_GROUP = gql`
  query getGroupById($id: ID!) {
    getGroupById(id: $id) {
      organization {
        id
        name
      }
      id
      name
      bhaApproved
      existing
      trial
      type
      period
      address1
      address2
      country
      district
      town
      postCode
      membershipPayedByUser
      maxOwners
      owners
      managers
      trainers
      guests
      sms
      permissions {
        id
        name
        groupId
        createPost
      }
      updates {
        totalPages
        size
        totalSize
      } 
      horsesCount 
    }
  }
`;

export const EDIT_GROUP_PERMISSIONS = gql`
  mutation editPermissions($groupId: ID!, $permissions: [PermissionsInput]!) {
    group(id: $groupId) {
      editPermissions(permissions: $permissions) {
        id
        permissions {
          id
          name
          groupId
          createPost
        }
      }
    }
  }
`;

export const GET_GROUP_HORSES = gql`
  query getGroupById($id: ID!) {
    getGroupById(id: $id) {
      id
      horses {
        id
        name
        timeformId
        fgId
        ustId
        slug
        maxShares
        location
        members {
          userGroupId
          role
          shares
          userGroup {
            user {
              id
              userName
              email
              firstName
              surname
            }
          }
        }
        pendingMembers {
          role
          shares
          invitation {
            id
            email
            firstName
            surname
            isManager
          }
        }
      }
      updates {
        totalPages
        size
        totalSize
      }
    }
  }
`;

export const GET_GROUP_HORSES_SELECTOR = gql`
  query getGroupHorsesSelector($id: ID!) {
    getGroupById(id: $id) {
      id
      horses {
        id
        name
        maxShares
      }
    }
  }
`;

export const GET_USERS_SELECTOR = gql`
  {
    getAllUsers {
      id
      email
    }
  }
`;
export const GET_ORGANIZATIONS_SELECTOR = gql`
  {
    getAllOrganizations {
      id
      name
    }
  }
`;

export const GET_GROUP_MEMBERS = gql`
  query getGroupById($id: ID!) {
    getGroupById(id: $id) {
      id
      invitations {
        id
        email
        firstName
        horses {
          horseId
          horse {
            id
            name
          }
          shares
          role
        }
        surname
        isManager
        groupId
      }
      members {
        user {
          id
          firstName
          surname
          email
          lastLogin
          lastSeenBy
        }
        role
        horses {
          horse {
            id
            name
          }
          shares
          role
        }
      }
    }
  }
`;

export const RESEND_INVITATION = gql`
  mutation resendInvitation($id: ID!) {
    resendInvitation(id: $id)
  }
`;

export const CANCEL_INVITATION = gql`
  mutation deleteInvitation($id: ID!) {
    deleteInvitation(id: $id)
  }
`;

export const CONFIRM_INVITATION = gql`
  mutation confirmInvitation(
    $inviteId: ID!
    $firstName: String!
    $surname: String!
    $password: String
    $userName: String
    $phoneNumber: String
  ) {
    confirmInvitation(
      id: $inviteId
      userName: $userName
      firstName: $firstName
      surname: $surname
      password: $password
      phoneNumber: $phoneNumber
    ) {
      id
      userName
      avatarImage
    }
  }
`;

export const GET_GROUP_UPDATES = gql`
  query getGroupById($id: ID!, $size: Int) {
    getGroupById(id: $id) {
      id
      updates(size: $size) {
        updates {
          id
          horse {
            name
            id
          }
          userId
          author {
            id
            userName
            avatarImage
          }
          message
          type
          video {
            thumbnail
            path
            eventId
          }
          entry {
            courseName
            draw
            weightCarried
            jockeyName
            apprenticeClaim
            entryNumber
            horseName
            startTimeLocalScheduled
            distanceText
            trainerName
            raceTitle
            numberOfRunners
            meetingDate
            raceNumber
            courseId
          }
          declaration {
            courseName
            draw
            weightCarried
            jockeyName
            apprenticeClaim
            entryNumber
            horseName
            startTimeLocalScheduled
            distanceText
            trainerName
            raceTitle
            numberOfRunners
            meetingDate
            raceNumber
            courseId
          }
          event {
            address
            postcode
            title
            date
            endDate
            time
            endTime
            description
            attenders {
              userName
              avatarImage
              firstName
              surname
              email
              phoneNumber
            }
          }
          poll {
            title
            description
            options
            answers {
              option
              userId
            }
          }
          rating {
            ratingType
            oldValue
            newValue
          }
          attachments
          comments {
            id
            userId
            author {
              id
              userName
              avatarImage
            }
            message
            attachments
            parentId
            likes {
              user {
                id
                userName
              }
            }
            createdAt
          }
          likes {
            updateId
          }
        }
      }
    }
  }
`;
export const GET_ORGANIZATION_UPDATES = gql`
  query getOrganizationById($id: ID!, $size: Int) {
    getOrganizationById(id: $id) {
      id
      updates(size: $size) {
        updates {
          id
          horse {
            name
            id
          }
          userId
          author {
            id
            userName
            avatarImage
          }
          message
          type
          video {
            thumbnail
            path
            eventId
          }
          entry {
            courseName
            draw
            weightCarried
            jockeyName
            apprenticeClaim
            entryNumber
            horseName
            startTimeLocalScheduled
            distanceText
            trainerName
            raceTitle
            numberOfRunners
            meetingDate
            raceNumber
            courseId
          }
          declaration {
            courseName
            draw
            weightCarried
            jockeyName
            apprenticeClaim
            entryNumber
            horseName
            startTimeLocalScheduled
            distanceText
            trainerName
            raceTitle
            numberOfRunners
            meetingDate
            raceNumber
            courseId
          }
          event {
            address
            postcode
            title
            date
            endDate
            time
            endTime
            description
            attenders {
              userName
              avatarImage
              firstName
              surname
              email
              phoneNumber
            }
          }
          poll {
            title
            description
            options
            answers {
              option
              userId
            }
          }
          rating {
            ratingType
            oldValue
            newValue
          }
          attachments
          comments {
            id
            userId
            author {
              id
              userName
              avatarImage
            }
            message
            attachments
            parentId
            likes {
              user {
                id
                userName
              }
            }
            createdAt
          }
          likes {
            updateId
          }
          seenBy {
            status
            userId
            updateId
          }
          createdAt
        }
      }
    }
  }
`;
export const GET_ATR_VIDEO = gql`
  query getATRStreamingURL($eventId: String) {
    getATRStreamingURL(eventId: $eventId)
  }
`;

export const GET_COMMENT = gql`
  query getComment($id: ID!) {
    getUpdateById(id: $id) {
      id
      userId
      author {
        id
        userName
        avatarImage
      }
      message
      attachments
      parentId
      likes {
        user {
          id
          userName
        }
      }
      createdAt
    }
  }
`;

export const EDIT_COMMENT = gql`
  mutation editComment($id: ID!, $message: String) {
    update(id: $id) {
      edit(message: $message) {
        id
        comments {
          id
          userId
          author {
            id
            userName
            avatarImage
          }
          message
          attachments
          parentId
          likes {
            user {
              id
              userName
            }
          }
          createdAt
        }
      }
    }
  }
`;

export const CREATE_UPDATE = gql`
  mutation createUpdate(
    $horseId: ID!
    $message: String
    $attachments: [String]
    $noAuthor: Boolean
  ) {
    horse(id: $horseId) {
      createUpdate(update: { message: $message, attachments: $attachments }, noAuthor: $noAuthor) {
        id
      }
    }
  }
`;

export const CREATE_ORGANIZATION_UPDATE = gql`
  mutation createUpdate($id: ID!, $message: String, $attachments: [String], $noAuthor: Boolean) {
    organization(id: $id) {
      createUpdate(update: { message: $message, attachments: $attachments }, noAuthor: $noAuthor) {
        id
      }
    }
  }
`;

export const CREATE_POLL = gql`
  mutation createUpdate(
    $horseId: ID!
    $message: String!
    $attachments: [String]
    $options: [String!]!
    $noAuthor: Boolean
  ) {
    horse(id: $horseId) {
      createPoll(
        message: $message
        attachments: $attachments
        options: $options
        noAuthor: $noAuthor
      ) {
        id
      }
    }
  }
`;

export const CREATE_EVENT = gql`
  mutation createUpdate(
    $noAuthor: Boolean
    $horseId: ID!
    $address: String!
    $postcode: String!
    $date: String!
    $endDate: String
    $time: String!
    $endTime: String
    $title: String!
    $description: String!
  ) {
    horse(id: $horseId) {
      createEvent(
        noAuthor: $noAuthor
        address: $address
        postcode: $postcode
        date: $date
        endDate: $endDate
        time: $time
        endTime: $endTime
        title: $title
        description: $description
      ) {
        id
      }
    }
  }
`;

export const REMOVE_UPDATE = gql`
  mutation removeUpdate($id: ID!) {
    update(id: $id) {
      remove
    }
  }
`;

export const REMOVE_COMMENT = gql`
  mutation removeComment($id: ID!) {
    update(id: $id) {
      remove
    }
  }
`;

export const CREATE_GROUP = gql`
  mutation CreateGroup(
    $organizationId: ID!
    $name: String!
    $bhaApproved: Boolean!
    $existing: Boolean!
    $trial: Boolean!
    $type: String!
    $period: String!
    $address1: String!
    $address2: String!
    $country: String!
    $district: String!
    $town: String!
    $postCode: String!
    $membershipPayedByUser: Boolean!
    $maxOwners: Int!
    $owners: Int!
    $managers: Int!
    $trainers: Int!
    $guests: Int!
  ) {
    createGroup(
      organizationId: $organizationId
      name: $name
      bhaApproved: $bhaApproved
      existing: $existing
      trial: $trial
      type: $type
      period: $period
      address1: $address1
      address2: $address2
      country: $country
      district: $district
      town: $town
      postCode: $postCode
      membershipPayedByUser: $membershipPayedByUser
      maxOwners: $maxOwners
      owners: $owners
      managers: $managers
      trainers: $trainers
      guests: $guests
    ) {
      id
    }
  }
`;

export const UPDATE_GROUP = gql`
  mutation updateGroup(
    $organizationId: ID
    $id: ID!
    $name: String
    $bhaApproved: Boolean
    $existing: Boolean
    $trial: Boolean
    $type: String
    $period: String
    $address1: String
    $address2: String
    $country: String
    $district: String
    $town: String
    $postCode: String
    $membershipPayedByUser: Boolean
    $maxOwners: Int
    $owners: Int
    $managers: Int
    $trainers: Int
    $guests: Int
    $sms: Boolean
  ) {
    group(id: $id) {
      edit(
        organizationId: $organizationId
        name: $name
        bhaApproved: $bhaApproved
        existing: $existing
        trial: $trial
        type: $type
        period: $period
        address1: $address1
        address2: $address2
        country: $country
        district: $district
        town: $town
        postCode: $postCode
        membershipPayedByUser: $membershipPayedByUser
        maxOwners: $maxOwners
        owners: $owners
        managers: $managers
        trainers: $trainers
        guests: $guests
        sms: $sms
      ) {
        id
        name
        bhaApproved
        existing
        trial
        type
        period
        address1
        address2
        country
        district
        town
        postCode
        membershipPayedByUser
        maxOwners
        owners
        managers
        trainers
        guests
        sms
        organization {
          id
          name
        }
      }
    }
  }
`;

export const DELETE_GROUP = gql`
  mutation DeleteGroup($id: ID!) {
    group(id: $id) {
      remove
    }
  }
`;

export const ADD_HORSE = gql`
  mutation AddHorse(
    $id: ID!
    $name: String!
    $gender: String
    $colour: String
    $secondColour: String
    $foalingDate: String
    $service: Service
    $timeformId: String
    $fgId: String
    $ustId: String
    $damName: String
    $damSireName: String
    $ownerFullName: String
    $sireName: String
    $trainerFullName: String
    $stud: String
    $location: String
    $saleLocation: String
    $purchasePrice: Int
    $purchaseCurrency: PurchaseCurrency
    $isSouthernHemisphere: Boolean
  ) {
    group(id: $id) {
      addHorse(
        name: $name
        gender: $gender
        colour: $colour
        secondColour: $secondColour
        foalingDate: $foalingDate
        service: $service
        timeformId: $timeformId
        fgId: $fgId
        ustId: $ustId
        damName: $damName
        damSireName: $damSireName
        ownerFullName: $ownerFullName
        sireName: $sireName
        trainerFullName: $trainerFullName
        stud: $stud
        location: $location
        saleLocation: $saleLocation
        purchasePrice: $purchasePrice
        purchaseCurrency: $purchaseCurrency
        isSouthernHemisphere: $isSouthernHemisphere
      ) {
        id
      }
    }
  }
`;
export const ADD_MEMBER = gql`
  mutation AddMember(
    $id: ID!
    $userId: ID!
    $role: String!
    $horses: [InvitationHorseInput]!
    $manageAllHorses: Boolean
  ) {
    group(id: $id) {
      addMember(role: $role, userId: $userId, horses: $horses, manageAllHorses: $manageAllHorses)
    }
  }
`;
export const ADD_MEMBERS_BULK = gql`
  mutation AddMembersBulk(
    $id: ID!
    $members: [InviteMemberInput]!
    $horses: [InvitationHorseInput]!
  ) {
    group(id: $id) {
      bulkAddMembers(members: $members, horses: $horses)
    }
  }
`;
export const ADD_MEMBERS_BULK_FAST = gql`
  mutation AddMembersBulkFast(
    $id: ID!
    $managerId: ID
    $members: [InviteMemberInput]!
    $horses: [InvitationHorseInput]!
  ) {
    group(id: $id) {
      bulkAutoAddMembers(members: $members, horses: $horses, managerId: $managerId)
    }
  }
`;

export const SET_SHARES = gql`
  mutation setShares($userId: ID!, $id: ID!, $shares: [Shares!]!) {
    group(id: $id) {
      member(userId: $userId) {
        setShares(shares: $shares)
      }
    }
  }
`;

export const REMOVE_HORSE = gql`
  mutation RemoveHorse($id: ID!, $groupId: ID!) {
    group(id: $groupId) {
      removeHorse(id: $id)
    }
  }
`;

export const REMOVE_USER = gql`
  mutation RemoveUser($id: ID!, $groupId: ID!) {
    group(id: $groupId) {
      member(userId: $id) {
        remove
      }
    }
  }
`;

export const REMOVE_USER_HORSE = gql`
  mutation setSharesMutation($groupId: ID!, $memberId: ID!, $horseId: ID!) {
    group(id: $groupId) {
      member(userId: $memberId) {
        horse(id: $horseId) {
          remove
        }
      }
    }
  }
`;

export const ADD_MANAGER = gql`
  mutation AddHorseManager($groupId: ID!, $userId: ID!) {
    group(id: $groupId) {
      member(userId: $userId) {
        addManager
      }
    }
  }
`;

export const DOWNGRADE_USER = gql`
  mutation DowngradeUser($id: ID!, $groupId: ID!) {
    group(id: $groupId) {
      member(userId: $id) {
        downgrade
      }
    }
  }
`;

export const UPGRADE_USER = gql`
  mutation UpgradeUser($id: ID!, $groupId: ID!) {
    group(id: $groupId) {
      member(userId: $id) {
        upgrade
      }
    }
  }
`;

// =================================
//              HORSE
// =================================

export const SEARCH_HORSE = gql`
  query searchHorse($name: String!) {
    searchHorse(name: $name) {
      name
      timeformId
      foalingDate
      horseGender
      horseColour
      damName
      sireName
      damSireName
      ownerFullName
      trainerFullName
    }
  }
`;

export const SEARCH_FG_INPUT_HORSE = gql`
  query searchFGInputHorse($name: String!) {
    searchFGInputHorse(name: $name) {
      fgId
      name
      origin
      breed
      gender
      birthYear
    }
  }
`;

export const SEARCH_FG_HORSE = gql`
  query searchFGHorse($fgId: String!) {
    searchFGHorse(fgId: $fgId) {
      name
      fgId
      droitPrime
      breed
      colour
      gender
      foalingDate
      birthPlace
      deathDate
      castrationDate
      damName
      damFgId
      sireName
      sireFgId
      damSireName
      damSireFgId
      propertyType
      ownerFullName
      ownerCode
      trainerFullName
      lastRatingValue
    }
  }
`;

export const SEARCH_UST_INPUT_HORSE = gql`
  query searchUSTInputHorse($name: String!) {
    searchUSTInputHorse(name: $name) {
      ustId
      name
      foalingDate
    }
  }
`;

export const SEARCH_UST_HORSE = gql`
  query searchUSTHorse($ustId: String!) {
    searchUSTHorse(ustId: $ustId) {
      name
      ustId
      foalingDate
      horseGender
      horseColour
      damName
      sireName
      damSireName
      ownerFullName
      trainerFullName
    }
  }
`;

export const GET_ALL_HORSES = gql`
  {
    getAllHorses {
      id
      name
      gender
      colour
      secondColour
      foalingDate
      primaryColour
      timeformId
      slug
      groupId
      silkCode
      location
      isLinkedToTimeform
      isLinkedToFranceGalop
      isLinkedToUST
      linkedAt
      isSouthernHemisphere
      linkedBy {
        id
        userName
        email
      }
      group {
        id
        name
      }
      timeformHorse {
        silkCode
        horseColour
        horseGender
      }
      fgHorse {
        gender
        colour
        damName
        sireName
        damSireName
      }
      ustHorse {
        ownerFullName
        horseColour: color
        horseGender: sex
      }
    }
  }
`;

export const GET_HORSE = gql`
  query getHorseById($id: ID!) {
    getHorseById(id: $id) {
      id
      name
      gender
      colour
      secondColour
      foalingDate
      timeformId
      fgId
      ustId
      groupId
      silkCode
      trainerName
      ownerName
      stud
      saleLocation
      purchasePrice
      purchaseCurrency
      location
      isSouthernHemisphere
      timeformHorse {
        trainerFullName
        ownerFullName
        horseGender
        horseColour
        silkCode
        horseName
        damName
        sireName
        damSireName
      }
      fgHorse {
        trainerFullName
        ownerFullName
        gender
        colour
        horseName
        damName
        sireName
        damSireName
      }
      ustHorse {
        trainerFullName
        ownerFullName
        damName
        sireName
        damSireName
      }
    }
  }
`;

export const GET_HORSE_SERVICES = gql`
  query getHorseById($id: ID!) {
    getHorseById(id: $id) {
      id
      timeformId
      fgId
      ustId
    }
  }
`

export const GET_HORSE_MEMBERS = gql`
  query getHorseMembers($id: ID!) {
    getHorseById(id: $id) {
      id
      name
      members {
        userGroupId
        userGroup {
          user {
            id
            email
            firstName
            surname
            userName
          }
        }
        role
        shares
      }
      pendingMembers {
        role
        shares
        invitation {
          id
          email
          firstName
          surname
          isManager
        }
      }
    }
  }
`;

export const GET_HORSE_TIMEFORM = gql`
  query getHorseTimeformById($id: ID!) {
    getHorseById(id: $id) {
      id
      name
      trainerName
      ownerName
      location
      isLinkedToTimeform
      isLinkedToFranceGalop
      isLinkedToUST
      linkedAt
      foalingDate
      isSouthernHemisphere
      linkedBy {
        id
        userName
        email
      }
      createdAt
      createdBy {
        id
        userName
        email
      }
      timeformHorse {
        horseCode
        horseAge
        foalingDate
        wins
        places
        damName
        sireName
        damSireName
        ownerFullName
        trainerFullName
        productionCommentFlat
        productionCommentJump
        performanceCount
      }
      fgHorse {
        fgId
        horseName
        horseAge
        trainerFullName
        ownerFullName
        gender
        colour
        damName
        sireName
        damSireName
      }
      ustHorse {
        ustId
        foalingDate
        wins
        places
        damName
        sireName
        damSireName
        ownerFullName
        trainerFullName
      }
    }
  }
`;

export const GET_HORSE_TIMEFORM_RANKS = gql`
  query getHorseTimeformRanks($id: ID!) {
    getHorseById(id: $id) {
      id
      ranks {
        raceType
        runs
        wins
        places
        bestTFR
      }
    }
  }
`;

export const GET_HORSE_TIMEFORM_RESULTS = gql`
  query getHorseTimeformResults($id: ID!) {
    getHorseById(id: $id) {
      id
      results {
        meetingDate
        courseName
        raceTitle
        distanceText
        raceType
        surfaceName
        going
        position
        btn
        eq
        jockeyName
        sp
        ips
        tfig
        tfr
        timeformVerdict
        raceNumber
        courseId
      }
    }
  }
`;

export const GET_HORSE_UST_RESULTS = gql`
  query getHorseUSTResults($id: ID!) {
    getHorseById(id: $id) {
      id
      resultsUST {
        meetingDate
        courseName
        raceTitle
        distanceText
        raceType
        surfaceName
        going
        position
        btn
        eq
        jockeyName
        sp
        ips
        tfig
        tfr
        timeformVerdict
        raceNumber
        courseId
      }
    }
  }
`;

export const GET_HORSE_TIMEFORM_FUTURE_RACES = gql`
  query getHorseTimeformResults($id: ID!) {
    getHorseById(id: $id) {
      id
      futureRaces {
        meetingDate
        startTimeLocalScheduled
        courseName
        raceTitle
        distanceText
        prizeFund
        raceType
        surfaceName
        going
        draw
        eq
        raceNumber
        courseId
      }
    }
  }
`;

export const GET_HORSE_UST_FUTURE_RACES = gql`
  query getHorseUSTResults($id: ID!) {
    getHorseById(id: $id) {
      id
      futureUSTRaces {
        meetingDate
        startTimeLocalScheduled
        courseName
        raceTitle
        distanceText
        prizeFund
        raceType
        surfaceName
        going
        draw
        eq
        raceNumber
        courseId
      }
    }
  }
`;

export const GET_HORSE_TIMEFORM_RESULT_DETAILS = gql`
  query getHorseTimeformResultDetails(
    $id: ID!
    $courseId: String!
    $raceNumber: Int!
    $meetingDate: String!
  ) {
    getHorseById(id: $id) {
      id
      resultDetails(courseId: $courseId, raceNumber: $raceNumber, meetingDate: $meetingDate) {
        video {
          path
          eventId
        }
        horses {
          position
          btn
          silkCode
          horseName
          jockeyName
          trainerName
          horseAge
          sp
          wgt
          tfr
          timeformVerdict
        }
      }
    }
  }
`;

export const GET_HORSE_TIMEFORM_FUTURE_RACES_DETAILS = gql`
  query getHorseTimeformFutureRacesDetails(
    $id: ID!
    $courseId: String!
    $raceNumber: Int!
    $meetingDate: String!
  ) {
    getHorseById(id: $id) {
      id
      futureRaceDetails(courseId: $courseId, raceNumber: $raceNumber, meetingDate: $meetingDate) {
        entryNumber
        draw
        silkCode
        horseName
        jockeyName
        trainerName
        horseAge
        wgt
        tfr
        eq
        analystsComment
        productionComment
      }
    }
  }
`;

export const CREATE_HORSE = gql`
  mutation CreateHorse(
    $name: String!
    $gender: String
    $colour: String
    $secondColour: String
    $foalingDate: String
    $timeformId: String
  ) {
    createHorse(
      name: $name
      gender: $gender
      colour: $colour
      secondColour: $secondColour
      foalingDate: $foalingDate
      timeformId: $timeformId
    ) {
      id
    }
  }
`;

export const UPDATE_HORSE = gql`
  mutation updateHorse(
    $id: ID!
    $name: String
    $gender: String
    $colour: String
    $secondColour: String
    $foalingDate: String
    $timeformId: String
    $fgId: String
    $ustId: String
    $trainerName: String
    $ownerName: String
    $silkCode: String
    $stud: String
    $damName: String
    $sireName: String
    $damSireName: String
    $saleLocation: String
    $purchasePrice: Int
    $purchaseCurrency: PurchaseCurrency
    $location: String
    $isSouthernHemisphere: Boolean
  ) {
    horse(id: $id) {
      edit(
        name: $name
        gender: $gender
        colour: $colour
        secondColour: $secondColour
        foalingDate: $foalingDate
        timeformId: $timeformId
        fgId: $fgId
        ustId: $ustId
        trainerName: $trainerName
        ownerName: $ownerName
        silkCode: $silkCode
        stud: $stud
        damName: $damName
        sireName: $sireName
        damSireName: $damSireName
        saleLocation: $saleLocation
        purchasePrice: $purchasePrice
        purchaseCurrency: $purchaseCurrency
        location: $location
        isSouthernHemisphere: $isSouthernHemisphere
      ) {
        id
        name
        gender
        colour
        secondColour
        foalingDate
        timeformId
        fgId
        ustId
        trainerName
        ownerName
        silkCode
        stud
        saleLocation
        purchasePrice
        purchaseCurrency
        location
        isSouthernHemisphere
        timeformHorse {
          trainerFullName
          ownerFullName
          horseGender
          horseColour
          silkCode
          horseName
          damName
          sireName
          damSireName
        }
        ustHorse {
          trainerFullName
          ownerFullName
          horseGender
          horseColour
          damName
          sireName
          damSireName
        }
      }
    }
  }
`;

export const UPDATE_HORSE_HISTORY = gql`
  mutation UpdateHorseHistory($id: ID!) {
    horse(id: $id) {
      updateHistory
    }
  }
`;

export const DELETE_HORSE = gql`
  mutation DeleteHorse($id: ID!) {
    horse(id: $id) {
      remove
    }
  }
`;

export const ADD_ORG_MANAGER = gql`
  mutation addOrgManager($orgId: ID!, $userId: ID!) {
    organization(id: $orgId) {
      addManager(userId: $userId) {
        id
        managers {
          id
          organizationId
          userId
          user {
            id
          }
          role
        }
      }
    }
  }
`;

export const REMOVE_ORG_MANAGER = gql`
  mutation removeOrgManager($orgId: ID!, $userId: ID!) {
    organization(id: $orgId) {
      removeManager(userId: $userId) {
        id
        managers {
          id
          organizationId
          userId
          user {
            id
            firstName
            surname
            userName
            email
          }
          role
        }
      }
    }
  }
`;

export const HORSE_CHANGE_GROUP = gql`
  mutation horseChangeGroup($horseId: ID!, $groupId: ID!) {
    horse(id: $horseId) {
      changeGroup(groupId: $groupId) {
        id
        name
        groupId
      }
    }
  }
`;

export const GET_USER_NOTES = gql`
  query getUserNotes($id: ID!) {
    getUserById(id: $id) {
      id
      notes {
        id
        text
        createdAt
        updatedAt
        createdBy {
          id
          firstName
          surname
          userName
        }
      }
    }
  }
`;

export const ADD_USER_NOTE = gql`
  mutation addUserNote($id: ID!, $text: String!) {
    user(id: $id) {
      addNote(text: $text) {
        id
        notes {
          id
          text
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const REMOVE_USER_NOTE = gql`
  mutation removeUserNote($userId: ID!, $noteId: ID!) {
    user(id: $userId) {
      removeNote(id: $noteId) {
        id
        notes {
          id
          text
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const EDIT_USER_NOTE = gql`
  mutation editUserNote($userId: ID!, $noteId: ID!, $text: String) {
    user(id: $userId) {
      editNote(id: $noteId, text: $text) {
        id
        text
        createdAt
        updatedAt
      }
    }
  }
`;

export const EDIT_NOTE = gql`
  mutation note($noteId: ID!, $text: String) {
    note(id: $noteId) {
      editNote(id: $noteId, text: $text) {
        id
        text
        createdAt
        updatedAt
      }
    }
  }
`;

export const REMOVE_NOTE = gql`
  mutation note($noteId: ID!) {
    note(id: $noteId) {
      removeNote(id: $noteId)
    }
  }
`;

export const GET_ALL_HORSES_HISTORY = gql`
  query getAllHorsesHistory {
    getAllHorses(withDeleted: true) {
      id
      name
      gender
      colour
      secondColour
      foalingDate
      primaryColour
      timeformId
      slug
      groupId
      silkCode
      group {
        id
        name
        sms
      }
      members {
        userGroupId
        userGroup {
          user {
            id
            email
            firstName
            surname
            userName
            isContentManager
          }
        }
        role
        shares
      }
      trial
      breedingUpdates
      createdAt
      deletedAt
      isLinkedToTimeform
      isLinkedToFranceGalop
      isLinkedToUST
      linkedAt(format: "YYYY-MM-DD")
      linkedBy {
        id
        userName
        email
      }
      createdBy {
        id
        userName
        email
      }
    }
  }
`;

export const GET_DELETED_HORSES = gql`
  query getDeletedHorses {
    getAllDeletedHorses {
      id
      name
      group {
        name
      }
      createdAt(format: "DD-MM-YYYY HH:mm")
      createdBy {
        id
        email
      }
      deletedAt(format: "DD-MM-YYYY HH:mm")
      deletedBy {
        id
        email
      }      
    }
  }
`;

export const GET_ORGANIZATION_NOTES = gql`
  query getOrganizationNotes($orgId: ID!) {
    getOrganizationById(id: $orgId) {
      id
      name
      notes {
        id
        text
        createdAt
        updatedAt
        createdBy {
          id
          firstName
          surname
        }
      }
    }
  }
`;

export const ADD_ORGANIZATION_NOTE = gql`
  mutation addOrganizationNote($id: ID!, $text: String!) {
    organization(id: $id) {
      addNote(text: $text) {
        id
        notes {
          id
          text
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const REMOVE_ORGANIZATION_NOTE = gql`
  mutation removeOrganizationNote($orgId: ID!, $noteId: ID!) {
    organization(id: $orgId) {
      removeNote(id: $noteId) {
        name
        notes {
          id
          text
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const EDIT_ORGANIZATION_NOTE = gql`
  mutation editOrganizationNote($orgId: ID!, $noteId: ID!, $text: String!) {
    organization(id: $orgId) {
      editNote(id: $noteId, text: $text) {
        id
        text
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_GROUP_NOTES = gql`
  query getGroupNotes($groupId: ID!) {
    getGroupById(id: $groupId) {
      id
      name
      notes {
        id
        text
        createdAt
        updatedAt
        createdBy {
          id
          firstName
          surname
        }
      }
    }
  }
`;

export const ADD_GROUP_NOTE = gql`
  mutation addGroupNote($id: ID!, $text: String!) {
    group(id: $id) {
      addNote(text: $text) {
        id
        notes {
          id
          text
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const REMOVE_GROUP_NOTE = gql`
  mutation removeGroupNote($groupId: ID!, $noteId: ID!) {
    group(id: $groupId) {
      removeNote(id: $noteId) {
        name
        notes {
          id
          text
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const EDIT_GROUP_NOTE = gql`
  mutation editGroupNote($groupId: ID!, $noteId: ID!, $text: String!) {
    group(id: $groupId) {
      editNote(id: $noteId, text: $text) {
        id
        text
        createdAt
        updatedAt
      }
    }
  }
`;
export const GET_ALL_NOTES = gql`
  query getAllNotes {
    getAllNotes {
      id
      userId
      groupId
      organizationId
      text
      createdBy {
        id
        firstName
        surname
        userName
      }
      user {
        id
        userName
        firstName
        surname
      }
      group {
        id
        name
      }
      organization {
        id
        name
      }
      createdAt
      updatedAt
    }
  }
`;
export const GET_ALL_INVITATIONS = gql`
  query getAllInvitations {
    getPendingInvitations {
      id
      firstName
      surname
      email
      isManager
      newUser
      group {
        id
        name
      }
      sentAt
      horses {
        horseId
        horse {
          id
          name
        }
        shares
        role
      }
    }
  }
`;

export const GET_HORSES_PERFORMANCE = gql`
  query getAllHorses($startDate: String, $endDate: String) {
    getAllHorses {
      id
      name
      group {
        id
        name
        organization {
          id
          name
        }
      }
      updates {
        updates {
          id
        }
      }
      lastManagerTrainerUpdate {
        id
        createdAt
        likes {
          userId
        }
        comments {
          id
        }
        fileType
        author {
          id
          firstName
          surname
        }
      }
      totalManagerUpdates(startDate: $startDate, endDate: $endDate)
    }
  }
`;

export const GET_MANAGERS_PERFORMANCE = gql`
  query getAllManagers($startDate: String, $endDate: String) {
    getAllManagers {
      id
      firstName
      surname
      ownerships {
        horses {
          horseId
          role
        }
        role
      }
      averagePostsPerHorse
      lastPost(startDate: $startDate, endDate: $endDate) {
        id
        createdAt
        horses {
          id
          name
          group {
            id
            name
          }
        }
        isGroupUpdate
        organisation {
          id
          name
        }
        likes {
          userId
        }
        comments {
          id
        }
      }
    }
  }
`;

export const GET_ORGANIZATIONS_PERFORMANCE = gql`
  query getAllOrganizations($startDate: String, $endDate: String) {
    getAllOrganizations {
      id
      name
      horses {
        id
        name
      }
      posts(startDate: $startDate, endDate: $endDate) {
        total
        period
        average
        last {
          id
          createdAt
          likes {
            userId
          }
          comments {
            id
          }
          fileType
          author {
            id
            firstName
            surname
          }
        }
      }
    }
  }
`;


// ===========================
//  Account Page queries
// ===========================

export const GET_USERHORSES_BY_GROUP_ID = gql`
    query GetUserHorsesByGroupID(
      $groupId: [ID!]
      $username: String
      $startDate: Date!
      $endDate: Date!
      $annualCostPerUser: String!
      $agreedNumberPerDay: String!
    ) {
      getGroupAccountingUserHorses(
        groupId: $groupId
        username: $username
        startDate: $startDate
        endDate: $endDate
        annualCostPerUser: $annualCostPerUser
        agreedNumberPerDay: $agreedNumberPerDay
      ) {
        userHorses {
          userName
          email
          userGroupId
          horseId
          horseName
          daysInPeriod
          percentageInPeriod
          createdAt
          deletedAt
        }
        totalDays
        averagePerDay
        agreedCost
        difference
    }
  }
`;

export const GET_HORSES_BY_GROUP_ID = gql`
    query GetHorsesByGroupID(
      $groupId: [ID!]
      $horseId: [ID]
      $startDate: Date!
      $endDate: Date!
      $annualCostPerHorse: String!
      $agreedNumberPerDay: String!
    ) {
      getGroupAccountingHorses(
        groupId: $groupId
        horseId: $horseId
        startDate: $startDate
        endDate: $endDate
        annualCostPerHorse: $annualCostPerHorse
        agreedNumberPerDay: $agreedNumberPerDay
      ) {
        horses {
          id
          name
          groupId
          service
          action
          dateAdded
          dateRemoved
          groupName
          daysInPeriod
          percentageInPeriod
        }
        totalDays
        averagePerDay
        agreedCost
        difference
    }
  }
`;

export const GET_HISTORICAL_HORSES = gql`
    query getHistoricalHorses($id: [ID!]) {
      getHistoricHorsesByGroupID(id: $id){
        horseId
        horseName
        oldGroupId
        groupId
        userId
        action
        groupName
        userEmail
        createdAt
    }
  }
`;

export const GET_ACCOUNTING_ORGANIZATION = gql`
  query getOrganizationById($id: ID! $includeDeleted: Boolean) {
    getOrganizationById(id: $id) {
      id
      name
      groups(includeDeleted: $includeDeleted) {
        id
        name
        sms
        smsBreeding
        createdAt
        deletedAt
      }
    }
  }
`;
