import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import MaterialTable from 'material-table';
import Avatar from '@material-ui/core/Avatar';
import { Grid } from '@material-ui/core';
import { GET_ALL_ORGANIZATIONS, DELETE_ORGANIZATION } from 'queries';
import { Loader, Notification, ConfirmationDialog } from 'shared';

class List extends Component {
  render() {
    const { data, history } = this.props;
    const { loading, error, getAllOrganizations } = data;
    if (loading) return <Loader />;
    if (error) return <Notification message={error.message} variant="error" />;
    const orgs = getAllOrganizations.map(o => ({
      id: o.id,
      name: o.name,
      logoImage: o.logoImage,
      managers: o.managers.length,
      groups: o.groups.length,
      horses: o.horses.length,
      updates: o.updates.totalSize,
      slug: o.slug,
    }));
    return (
      <Grid container direction="row" justify="center" alignItems="center">
        <MaterialTable
          actions={[
            {
              icon: 'edit_circle',
              tooltip: 'Edit Organization Info',
              onClick: (event, rowData) => {
                history.push(`organizations/${rowData.id}/edit`);
              },
            },
            {
              isFreeAction: true,
              icon: 'add_circle',
              tooltip: 'Add Organization',
              onClick: () => {
                history.push('/organizations/create');
              },
            },
            {
              icon: 'visibility_circle',
              tooltip: 'Show Organization',
              onClick: (event, rowData) => {
                history.push(`/organizations/${rowData.id}/view/groups`);
              },
            },
          ]}
          options={{
            columnsButton: true,
            pageSize: 10,
            exportButton: true,
            exportAllData: true,
            pageSizeOptions: [5, 10, 20, getAllOrganizations.length],
          }}
          columns={[
            {
              title: 'Logo',
              field: 'logoImage',
              render: rowData =>
                rowData.logoImage ? (
                  <Avatar alt={rowData.id} src={rowData.logoImage} styles={{ margin: 10 }} />
                ) : (
                  <Avatar
                    styles={{
                      margin: 10,
                    }}
                  >
                    TRM
                  </Avatar>
                ),
            },
            {
              title: 'ID',
              field: 'id',
              type: 'numeric',
              defaultSort: 'desc',
            },
            { title: 'Name', field: 'name' },
            { title: 'Groups', field: 'groups' },
            { title: 'Horses', field: 'horses' },
            { title: 'Managers', field: 'managers' },
            { title: 'Updates', field: 'updates' },
            {
              title: '',
              field: '',
              render: rowData => (
                <ConfirmationDialog
                  iconButton="Delete Organization"
                  title="Do you really want to delete this organization?"
                  mutationQuery={DELETE_ORGANIZATION}
                  refetchQ={GET_ALL_ORGANIZATIONS}
                  id={rowData.id}
                  redirectPath="/organizations"
                />
              ),
            },
          ]}
          // onRowClick={(e, rowData) =>
          //   this.props.history.push(`/organizations/${rowData.id}/view/groups`)
          // }
          data={orgs}
          title="Organizations"
        />
      </Grid>
    );
  }
}

List.propTypes = {
  data: PropTypes.object,
  history: PropTypes.object,
};

export const OrganizationList = graphql(GET_ALL_ORGANIZATIONS)(withRouter(List));
