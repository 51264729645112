import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import MaterialTable from 'material-table';

import { GET_ORGANIZATION_GROUPS } from 'queries';
import { Notification, TableLoader } from 'shared';
import { Avatar } from '@material-ui/core';

class List extends Component {
  render() {
    const { loading, error, getOrganizationById } = this.props.data;
    if (loading) return <TableLoader />;
    if (error) return <Notification message={error.message} variant="error" />;
    const data = getOrganizationById.groups.map(g => ({
      ...g,
      invitations: g.invitations.length,
      horses: g.horses.length,
      members: g.members.length,
    }));
    return (
      <MaterialTable
        actions={[
          {
            isFreeAction: true,
            icon: 'add_circle',
            tooltip: 'Add Group to Organization',
            onClick: () => {
              this.props.history.push('../addGroup');
            },
          },
          //   {
          //     icon: "group_circle",
          //     tooltip: "Organization Group",
          //     onClick: (event, rowData) => {
          //       this.props.history.push(`/groups/${rowData.id}/view/info`);
          //     }
          //   }
        ]}
        options={{
          columnsButton: true,
          pageSize: 10,
          actionsColumnIndex: -1,
          showEmptyDataSourceMessage: true,
          exportButton: true,
          exportAllData: true,
        }}
        columns={[
          {
            title: 'Logo',
            field: 'logoImage',
            render: rowData =>
              rowData.logoImage ? (
                <Avatar alt={rowData.id} src={rowData.logoImage} styles={{ margin: 10 }} />
              ) : (
                <Avatar
                  styles={{
                    margin: 10,
                  }}
                >
                  TRM
                </Avatar>
              ),
          },
          { title: 'ID', field: 'id' },
          { title: 'Name', field: 'name', defaultSort: 'asc' },
          { title: 'Invitations', field: 'invitations' },
          { title: 'Members', field: 'members' },
          { title: 'Horses', field: 'horses' },
        ]}
        onRowClick={(e, rowData) => this.props.history.push(`/groups/${rowData.id}/view/info`)}
        data={data}
        title="Groups"
      />
    );
  }
}

export const OrganizationGroups = graphql(GET_ORGANIZATION_GROUPS, {
  options: props => {
    return {
      variables: { id: props.id },
    };
  },
})(withRouter(List));
