import { createMuiTheme } from '@material-ui/core';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#233654',
      main: '#142339',
      dark: '#0d1a26',
      contrastText: '#fff',
    },
    secondary: {
      light: '#f18a9b',
      main: '#f18a9b',
      dark: '#dc778d',
      contrastText: '#fff',
    },
    error: {
      main: '#EE2942',
    },
  },
  overrides: {
    // Name of the component ⚛️ / style sheet\
    MuiAvatar: {
      root: {
        fontSize: '1em',
      },
      colorDefault: {
        backgroundColor: '#142339',
      },
    },
    // MuiToolbar: {
    //   root: {
    //     // height: 100,
    //     minWidth: 900,
    //     borderBottom: '1px solid rgba(224, 224, 224, 1)',
    //   },
    // },
    MuiTableCell: {
      root: {
        padding: '5px 10px',
      },
      body: {
        fontSize: '0.875rem',
      },
    },
  },
});

export default theme;
