export const styles = theme => ({
  tabsRoot: {
    color: 'white',
    backgroundColor: '#142339',
    borderRadius: 4,
    margin: -4,
    zIndex: 10,
    boxShadow:
      '0px 4px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);',
  },
  tabsIndicator: {
    backgroundColor: '#142339',
    borderTop: '5px solid #da5c74',
    borderBottom: '4px solid #da5c74',

    height: 48,
    zIndex: 1,
    transition: '0.7s',
  },
  tabRoot: {
    // textTransform: "initial",
    fontSize: '16px',
    opacity: 1,
    fontWeight: '500',
    color: 'white',
    minWidth: 72,
    marginRight: theme.spacing(4),
    '&:hover': {
      color: '#ec5c74',
      opacity: 1,
    },
    '&$tabSelected': {
      color: 'white',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:first-child': {
      minWidth: 300,
      maxWidth: 400,
    },
  },
  tabSelected: {
    zIndex: 2,
    color: 'white',
  },
  paper: {
    width: 1300,
    margin: 'auto',
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
  },
  table: {
    zIndex: -3,
    position: 'absolute',
    marginTop: 0,
    width: 1300,
  },
});
