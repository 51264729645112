import moment from "moment";

const isDayFirstDateFormat = (dateString) => {
    const dateRegexp = /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/;
    return (dateRegexp.test(dateString))
}

export const calculateHorseAge = (foalingDate, isSouthernHemisphere) => {
    if (!foalingDate) {
        return null;
    }

    const thisYear = parseInt(moment().format('YYYY'), 10);
    const foalingYear = isDayFirstDateFormat(foalingDate) ?
        parseInt(moment(foalingDate, 'DD/MM/YYYY').format('YYYY'), 10) :
        parseInt(moment(foalingDate, 'YYYY/MM/DD').format('YYYY'), 10);
    const horseAge = thisYear - foalingYear;

    if (!isSouthernHemisphere) {
        return horseAge;
    }

    const foalingMonth = isDayFirstDateFormat(foalingDate) ?
        parseInt(moment(foalingDate, 'DD/MM/YYYY').format('MM'), 10) :
        parseInt(moment(foalingDate, 'YYYY/MM/DD').format('MM'), 10); // get month
    const CURRENT_MONTH = parseInt(moment().format("MM").toString()); // get current month
    const MONTH_OF_AUGUST = 8;

    return (foalingMonth >= MONTH_OF_AUGUST && MONTH_OF_AUGUST > CURRENT_MONTH) ? horseAge - 1 : horseAge;
};
