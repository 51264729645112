import moment from 'moment';

export const formatHistoricalHorsesTableData = (horseList, options) => {
  const { name } = options;
  return horseList
    .filter(horse => !name || horse.horseName === name)
    .map(horse => {
    return {
      ...horse,
      id: horse.horseId,
      name: horse.horseName
    };
  });
}

export const formateOverviewTableData = (groups, options) => {
  const { groupName } = options;
  return groups
    .filter(item => !groupName || item.name === groupName)
    .map(group => ({
        organistationName: options.organizationName,
        groupName: group.name,
        dateLoaded: moment(parseInt(group.createdAt)).format("DD-MM-YYYY"),
        dateFinished: group.deletedAt
          ? moment(parseInt(group.deletedAt)).format("DD-MM-YYYY")
          : null,
        breedingUpdates: group.smsBreeding ? "Yes" : "No",
        sms: group.sms ? "Yes" : "No"
    }));
}