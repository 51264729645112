import React, { Component } from 'react';
import { GET_HORSE_TIMEFORM } from 'queries';

import { withRouter, Link, Route } from 'react-router-dom';
import { graphql } from 'react-apollo';

import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';

import { Loader, Notification } from 'shared';

import { styles } from 'shared/ViewStyles';
import HorseInfo from './HorseInfo';
import HorseMembers from './HorseMembers';
import HorseRanks from './HorseRanks';
import HorseResults from './HorseResults';
import HorseResultDetails from './HorseResults/HorseResultDetails';
import HorseFutureRaces from './HorseFutureRaces';
import HorseFutureRaceDetails from './HorseFutureRaces/HorseFutureRaceDetails';

class HorseView extends Component {
  state = {
    value: '',
  };

  componentDidMount() {
    this.setState({
      value: this.props.history.location.pathname,
    });
  }

  componentDidUpdate() {
    if (this.state.value !== this.props.history.location.pathname)
      this.setState({
        value: this.props.history.location.pathname,
      });
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  editHandler = () => {
    this.props.history.push(`horses/${this.props.data.getHorseById.id}/view`);
  };

  render() {
    const { classes } = this.props;
    const { value } = this.state;
    const { loading, error, getHorseById } = this.props.data;
    if (loading) return <Loader />;
    if (error) return <Notification message={error.message} variant="error" />;

    const { id, name } = getHorseById;
    return (
      <form onSubmit={this.submitHandler} className={classes.root}>
        <Paper className={classes.paper}>
          <Tabs
            value={value}
            onChange={this.handleChange}
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
            centered
          >
            <Tab
              label={`Horse: ${name}`}
              disableRipple
              component={Link}
              to="../edit"
              // value={`/horses/${id}/view/info`}
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            <Tab
              label="Info"
              disableRipple
              component={Link}
              to={`/horses/${id}/view/info`}
              value={`/horses/${id}/view/info`}
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            <Tab
              label="Members"
              disableRipple
              component={Link}
              to={`/horses/${id}/view/members`}
              value={`/horses/${id}/view/members`}
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            <Tab
              label="Ranks"
              disableRipple
              component={Link}
              to={`/horses/${id}/view/ranks`}
              value={`/horses/${id}/view/ranks`}
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            <Tab
              label="Results"
              disableRipple
              component={Link}
              to={`/horses/${id}/view/results`}
              value={`/horses/${id}/view/results`}
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            <Tab
              label="Future races"
              disableRipple
              component={Link}
              to={`/horses/${id}/view/future-races`}
              value={`/horses/${id}/view/future-races`}
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
          </Tabs>

          <Route
            exact
            path="/horses/:id/view/info"
            render={() => <HorseInfo data={getHorseById} />}
          />
          <Route
            exact
            path="/horses/:id/view/members"
            render={() => (
              <div className={classes.table}>
                <HorseMembers id={id} />
              </div>
            )}
          />
          <Route
            exact
            path="/horses/:id/view/ranks"
            render={() => (
              <div className={classes.table}>
                <HorseRanks id={id} />
              </div>
            )}
          />
          <Route
            exact
            path="/horses/:id/view/results"
            render={() => (
              <div className={classes.table}>
                <HorseResults id={id} />
              </div>
            )}
          />
          <Route
            exact
            path="/horses/:id/view/future-races"
            render={() => (
              <div className={classes.table}>
                <HorseFutureRaces id={id} />
              </div>
            )}
          />
          <Route
            exact
            path="/horses/:id/view/results/details/:course/:race/:date"
            render={() => (
              <div className={classes.table}>
                <HorseResultDetails id={id} name={name} />
              </div>
            )}
          />
          <Route
            exact
            path="/horses/:id/view/future-races/details/:course/:race/:date"
            render={() => (
              <div className={classes.table}>
                <HorseFutureRaceDetails id={id} name={name} />
              </div>
            )}
          />
        </Paper>
        {/* {this.state.errors ? (
          <Notification message={this.state.errors} variant={"error"} />
        ) : null} */}
      </form>
    );
  }
}

export default graphql(GET_HORSE_TIMEFORM, {
  options: props => {
    return {
      variables: { id: props.match.params.id },
    };
  },
})(withRouter(withStyles(styles)(HorseView)));
