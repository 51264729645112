import React from 'react';
import { withRouter } from 'react-router-dom';
import * as PropTypes from 'prop-types';
import { useQuery, useMutation } from 'react-apollo';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Switch from '@material-ui/core/Switch';
import { Grid } from '@material-ui/core';
import { Notification, CenteredPaper, PaperTitle, Loader, Autosuggest } from 'shared';
import { ADD_MEMBER, GET_USERS_SELECTOR } from 'queries';
import HorsesToAdd from './HorsesToAdd';
import useStyles from './styles';

const AddMember = ({ match }) => {
  const [role, setRole] = React.useState('');
  const [userId, setUserId] = React.useState('');
  const [userOptions, setUserOptions] = React.useState([]);
  const [manageAllHorses, setManageAllHorses] = React.useState(false);
  const { data, loading, error } = useQuery(GET_USERS_SELECTOR);
  const [add] = useMutation(ADD_MEMBER);

  const classes = useStyles();
  React.useEffect(() => {
    if (data && data.getAllUsers) {
      const options = data.getAllUsers.map(item => ({
        value: item.id,
        label: item.email,
      }));
      setUserOptions(options);
    }
  }, [data]);

  React.useEffect(() => {
    setManageAllHorses(role === 'manager');
  }, [role]);
  const {
    params: { id },
  } = match;

  if (loading) return <Loader />;
  if (error) return <Notification message={error.message} variant="error" />;

  return (
    <CenteredPaper>
      <PaperTitle>Add Member to Group</PaperTitle>
      <Grid container className={classes.root}>
        <br />
        <Grid item xs={12} sm={12}>
          <Autosuggest
            options={userOptions}
            handleChange={e => setUserId(e ? e.value : '')}
            placeholder="Search a user..."
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormControl fullWidth>
            <InputLabel htmlFor="role-simple">Role</InputLabel>
            <Select
              value={role}
              onChange={e => setRole(e.target.value)}
              inputProps={{
                name: 'role',
                id: 'role-simple',
              }}
            >
              <MenuItem value="manager">Manager</MenuItem>
              <MenuItem value="member">Member</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      {role === 'manager' && (
        <Grid item xs={12} sm={12}>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                value="manageAllHorses"
                checked={manageAllHorses}
                onChange={e => setManageAllHorses(e.target.checked)}
              />
            }
            label="Manage all horses"
          />
        </Grid>
      )}
      <Grid item xs={12} sm={12}>
        <HorsesToAdd
          manageAllHorses={manageAllHorses}
          user={{ role, userId }}
          mutate={add}
          id={id}
          setManageAllHorses={val => setManageAllHorses(val)}
        />
      </Grid>
    </CenteredPaper>
  );
};

AddMember.propTypes = {
  match: PropTypes.object,
};

export default withRouter(AddMember);
