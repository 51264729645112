import React, { Component } from 'react';
import Button from '@material-ui/core/Button';

import { withStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/EditOutlined';
import { withRouter, Link } from 'react-router-dom';

const styles = theme => ({
  button: {
    marginTop: 30,
    margin: -38,
    display: 'flex',
    color: '#fff',
    borderColor: '#142339',
    background: '#142339',
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);',
    '&:hover': {
      background: '#142339',

      color: '#ec5c74',
      borderColor: '#142339',
    },
  },
});

class MySubmitButton extends Component {
  render() {
    const { classes, children } = this.props;
    return (
      <Button
        variant="outlined"
        color="primary"
        className={classes.button}
        component={Link}
        to="../edit"
      >
        <EditIcon />
        {children}
      </Button>
    );
  }
}

export const EditButton = withStyles(styles)(withRouter(MySubmitButton));
