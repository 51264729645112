import React from 'react';
import { Mutation, Query } from 'react-apollo';
import { withRouter, Link } from 'react-router-dom';
import { FormControlLabel, Paper, Switch, Typography, withStyles, Button } from '@material-ui/core';
import { GET_ORGANIZATION, UPDATE_ORGANIZATION } from 'queries';
import queryString from 'query-string';

const styles = {
  paper: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '10px 0',
  },
  button: {
    display: 'flex',
    margin: '0 20px',
    color: '#fff',
    background: '#f18a9b',
    borderColor: '#f18a9b',
    '&:hover': {
      background: '#fff',
      color: '#142339',
      borderColor: '#142339',
    },
  }
};

const OrganizationPermissions = ({ id, classes, location }) => {
  const parsedLocation = queryString.parse(location.search);
  const isFlow = !!parsedLocation.flow;
  return (
    <Mutation mutation={UPDATE_ORGANIZATION}>
      {edit => (
        <Query query={GET_ORGANIZATION} variables={{ id }}>
          {({ data = {}, loading }) => {
            let org = {};
            if (loading) {
              return (
                <Paper className={classes.paper}>
                  <Typography color="textPrimary" variant="h5">
                    Loading...
                  </Typography>
                </Paper>
              );
            }
            if (!loading) {
              org = data.getOrganizationById;
            }
            return (
              <Paper className={classes.paper}>
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      value="sms"
                      checked={org.sms}
                      onChange={e => {
                        const { checked } = e.target;
                        edit({
                          variables: {
                            id,
                            sms: checked,
                          },
                        });
                      }}
                    />
                  }
                  label="Allow SMS notifications for members"
                />
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      value="multilingualEmailInvite"
                      checked={org.multilingualEmailInvite}
                      onChange={e => {
                        const { checked } = e.target;
                        edit({
                          variables: {
                            id,
                            multilingualEmailInvite: checked,
                          },
                        });
                      }}
                    />
                  }
                  label="Allow multilingual email invitations"
                />
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      value="personalise"
                      checked={org.personalise}
                      onChange={e => {
                        const { checked } = e.target;
                        edit({
                          variables: {
                            id,
                            personalise: checked,
                          },
                        });
                      }}
                    />
                  }
                  label="Allow send friendly email name"
                />
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      value="breedingTile"
                      checked={org.breedingTile}
                      onChange={e => {
                        const { checked } = e.target;
                        edit({
                          variables: {
                            id,
                            breedingTile: checked,
                          },
                        });
                      }}
                    />
                  }
                  label="Allow organisation to receive arion updates"
                />
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      value="tpdTile"
                      checked={org.tpdTile}
                      onChange={e => {
                        const { checked } = e.target;
                        edit({
                          variables: {
                            id,
                            tpdTile: checked,
                          },
                        });
                      }}
                    />
                  }
                  label="Allow organisation to receive Total Performance Data updates"
                />
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      value="toteLink"
                      checked={org.toteLink}
                      onChange={e => {
                        const { checked } = e.target;
                        edit({
                          variables: {
                            id,
                            toteLink: checked,
                          },
                        });
                      }}
                    />
                  }
                  label="Allow organisation to receive Tote Link"
                />
                {
                  isFlow ? <div className={classes.buttonContainer}>
                    <Button
                      variant="outlined"
                      color="primary"
                      type="submit"
                      className={classes.button}
                      component={Link}
                      to="/groups/create?flow=true"
                    >
                      Continue to add a group
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      type="submit"
                      className={classes.button}
                      component={Link}
                      to="/organizations"
                    >
                      Return to admin panel
                    </Button>
                  </div> : null
                }
              </Paper>
            );
          }}
        </Query>
      )}
    </Mutation>
  );
};

export default withRouter(withStyles(styles)(OrganizationPermissions));
