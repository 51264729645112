import React from "react";
import { HorseCost } from "./HorseCost";
import { Table } from "../Utils/Table";

export const HorsesTab = ({
  horseCost,
  setHorseCost,
  agreedHorseDays,
  setAgreedHorseDays,
  tableData,
  totalHorseDays,
  avgHorsesPerDay,
  horseDifferenceAmount,
  agreedCost
}) => {
  const tableHeadings = [
    { title: "", field: "rowId", width: "4%" },
    { title: "Horse ID", field: "id" },
    { title: "Name", field: "name" },
    { title: "Linked to", field: "service" },
    { title: "Group", field: "groupName" },
    { title: "Date Added", field: "dateAdded" },
    { title: "Date Removed", field: "dateRemoved" },
    { title: "No of Days", field: "daysInPeriod" },
    { title: "No Days %", field: "daysPercentage" }
  ];

  const data = tableData.map((row, index) => ({
    ...row,
    rowId: index + 1,
    service: row.service ?? "-",
    dateAdded: row.dateAdded ?? "-",
    dateRemoved: row.dateRemoved ?? "-",
    daysPercentage: `${row.percentageInPeriod} %`
  }));
  return (
    <>
      <HorseCost
        horseCost={horseCost}
        setHorseCost={setHorseCost}
        agreedHorseDays={agreedHorseDays}
        setAgreedHorseDays={setAgreedHorseDays}
        totalHorseDays={totalHorseDays}
        avgHorsesPerDay={avgHorsesPerDay}
        horseDifferenceAmount={horseDifferenceAmount}
        agreedCost={agreedCost}
      />
      <Table tableHeadings={tableHeadings} tableData={data} />
    </>
  );
};
