import DeleteFromGroupPopup from 'Group/GroupView/SetShares/DeleteFromGroupPopup';
import React from 'react';
import * as PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useQuery, useMutation } from 'react-apollo';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import {
  GET_GROUP_MEMBERS,
  SET_SHARES,
  GET_GROUP_HORSES_SELECTOR,
  GET_USER_HORSES,
  REMOVE_USER_HORSE,
  ADD_MANAGER,
} from 'queries';
import { CDforList, Notification, SubmitButton, TableLoader } from 'shared';
import useStyles from './styles';

const SetShares = ({ match, history, id }) => {
  const {
    params: { userId },
  } = match || {};
  const [horses, setHorses] = React.useState({});
  const [changed, setChanged] = React.useState(new Set());
  const [isDeletePopupOpen, setDeletePopupOpen] = React.useState(false);
  const [isDeleteFromGroupPopupOpen, setDeleteFromGroupPopupOpen] = React.useState(false);
  // const [isManagerPopupOpen, setManagerPopupOpen] = React.useState(false);
  const [ownedHorses, setOwnedHorses] = React.useState([]);
  const [horseToDelete, setHorseToDelete] = React.useState({ group: {} });
  const [error, setError] = React.useState(null);
  const classes = useStyles();

  const prevOwnedHorses = React.useRef();

  const { data, error: dataError, loading } = useQuery(GET_GROUP_HORSES_SELECTOR, {
    variables: { id },
  });
  const { data: userHorses, error: uHError, loading: uHLoading } = useQuery(GET_USER_HORSES, {
    variables: { id: userId },
  });
  const [setShares] = useMutation(SET_SHARES);
  const [AddManager] = useMutation(ADD_MANAGER);

  React.useEffect(() => {
    if (data && data.getGroupById && userHorses && userHorses.getUserById) {
      const dataHorses = {};
      const dataOwnedHorses = [];
      const groupForUser = userHorses.getUserById.ownerships.find(
        o => o.group.id === data.getGroupById.id,
      );
      groupForUser.horses.forEach(h => {
        const newH = { ...h };
        dataOwnedHorses.push(newH);
      });
      const groupRole = groupForUser.role;
      data.getGroupById.horses.forEach(h => {
        const ownedHorse = dataOwnedHorses.find(({ horse }) => horse.id === h.id) || {};
        dataHorses[h.id] = {
          horseId: h.id,
          name: h.name,
          label: `${h.name} - ${h.maxShares}%`,
          role: ownedHorse.role || '',
          shares: ownedHorse.shares || '',
          groupRole,
        };
      });
      setHorses(dataHorses);
      setOwnedHorses(dataOwnedHorses);
    }
  }, [data, userHorses]);

  React.useEffect(() => {
    if (
      prevOwnedHorses.current &&
      prevOwnedHorses.current.length === 1 &&
      ownedHorses.length === 0
    ) {
      setDeleteFromGroupPopupOpen(true);
    }
  }, [ownedHorses]);

  const submitHandler = event => {
    event.preventDefault();
    const shares = [...changed].map(i => ({
      horseId: horses[i].horseId,
      role: horses[i].role,
      shares: parseFloat(horses[i].shares || 0),
    }));
    setShares({
      variables: { id, userId, shares },
      refetchQueries: [
        { query: GET_GROUP_MEMBERS, variables: { id } },
        { query: GET_GROUP_HORSES_SELECTOR, variables: { id } },
      ],
    })
      .then(() => history.push(`/groups/${id}/view/members`))
      .catch(() => {
        setError(localStorage.getItem('error'));
      })
      .then(setTimeout(() => setError(null), 2000));
  };

  const handleChange = (value, inputName, horseId) => {
    const newHorses = { ...horses, [horseId]: { ...horses[horseId], [inputName]: value } };
    const newChanged = changed;
    newChanged.add(horseId);
    setHorses(newHorses);
    setChanged(newChanged);
  };

  React.useEffect(() => {
    prevOwnedHorses.current = ownedHorses;
  }, [ownedHorses]);

  const setManaging = (horseId, role, groupRole) => {
    if (groupRole === 'member') {
      AddManager({
        variables: { groupId: id, userId },
        refetchQueries: [{ query: GET_USER_HORSES, variables: { id: userId } }],
      })
        .then(res => (
          <Notification variant="success" message="the user was upgrated to a manager" />
        ))
        .catch(res => setError(localStorage.getItem('error')))
        .then(setTimeout(() => setError(null)), 2000);
    }
    if (!role) {
      handleChange('manager', 'role', horseId);
    }
  };

  // const filteredNames = horseList => {
  //   const h = Object.keys(horseList)
  //     .filter(key => horseList[key].role)
  //     .reduce((res, key) => Object.assign(res, { [key]: horseList[key].name }), {});
  //   return Object.values(h).join();
  // };
  const clearHorse = idToClear => {
    handleChange('', 'shares', idToClear);
    handleChange('', 'role', idToClear);
    const newChanged = changed;
    newChanged.delete(idToClear);
    setChanged(newChanged);
  };
  if (loading || uHLoading) return <TableLoader />;
  if (error || dataError || uHError)
    return <Notification message={error || dataError.message || uHError.message} variant="error" />;

  return (
    <form onSubmit={e => submitHandler(e)}>
      <CDforList
        open={isDeletePopupOpen}
        handleClose={() => setDeletePopupOpen(false)}
        title={`Do you really want to remove ${horseToDelete.name} from this user?`}
        mutationQuery={REMOVE_USER_HORSE}
        options={{
          variables: { groupId: id, memberId: userId, horseId: horseToDelete.id },
          refetchQueries: [{ query: GET_USER_HORSES, variables: { id: userId } }],
        }}
      />
      {/* <CDforList
        open={isManagerPopupOpen}
        handleClose={() => setManagerPopupOpen(false)}
        title={`You are about to make the user a manager. User will be a manager of ${filteredNames(
          horses,
        )}`}
        mutationQuery={AddManager}
        options={{
          variables: { groupId, userId },
          refetchQueries: [{ query: GET_USER_HORSES, variables: { id: userId } }],
        }}
      /> */}
      <DeleteFromGroupPopup
        open={isDeleteFromGroupPopupOpen}
        handleClose={() => setDeleteFromGroupPopupOpen(false)}
        userId={userId}
        groupId={id}
      />
      <Paper className={classes.paper}>
        {Object.values(horses).map(({ horseId, shares, role, label, name, groupRole }) => {
          const manageConditions =
            (groupRole !== 'org-manager' && groupRole === 'member') ||
            (!role && groupRole === 'manager');
          return (
            <Grid key={horseId} container className={classes.root} alignItems="flex-end">
              <Grid item xs={12} md={4}>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor={`${horseId}-role`}>Role</InputLabel>
                  <Select
                    value={role}
                    onChange={e => handleChange(e.target.value, 'role', horseId)}
                    inputProps={{
                      name: 'role',
                      id: `${horseId}-role`,
                    }}
                  >
                    <MenuItem value="owner">Owner</MenuItem>
                    <MenuItem value="trainer">Trainer</MenuItem>
                    <MenuItem value="guest">Guest</MenuItem>
                    <MenuItem value="org-manager" disabled>
                      Org Manager
                    </MenuItem>
                    <MenuItem value="manager" disabled>
                      Manager
                    </MenuItem>
                    <MenuItem value="" disabled>
                      Role
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                {label}
              </Grid>

              <Grid item xs={12} md={3}>
                <TextField
                  label="Horse Shares"
                  value={shares}
                  onChange={e => handleChange(e.target.value, 'shares', horseId)}
                  margin="normal"
                  className={classes.shares}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                />
              </Grid>
              {manageConditions ? (
                <Grid item xs={12} md={1}>
                  <Button onClick={() => setManaging(horseId, role, groupRole)} color="secondary">
                    Manage
                  </Button>
                </Grid>
              ) : (
                <Grid item xs={12} md={1} />
              )}
              {role && groupRole !== 'org-manager' && (
                <Grid item xs={12} md={1}>
                  <Button
                    onClick={() => {
                      const find = ownedHorses.find(h => h.horse.id === horseId);
                      if (find) {
                        setDeletePopupOpen(true);
                        setHorseToDelete({ id: horseId, name });
                      } else {
                        clearHorse(horseId);
                      }
                    }}
                  >
                    Remove
                  </Button>
                </Grid>
              )}
            </Grid>
          );
        })}
        <SubmitButton>Set Shares</SubmitButton>
      </Paper>
      {error ? <Notification message={error} variant="error" /> : null}
    </form>
  );
};

SetShares.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  id: PropTypes.string,
};

export default withRouter(SetShares);
