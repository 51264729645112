import React, { Component } from 'react';
import Button from '@material-ui/core/Button';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  button: {
    width: 150,
    marginTop: 30,
    margin: theme.spacing(1),
    display: 'flex',
    color: '#fff',
    background: '#f18a9b',
    borderColor: '#f18a9b',
    '&:hover': {
      background: '#fff',
      color: '#142339',
      borderColor: '#142339',
    },
  },
});

class MySubmitButton extends Component {
  render() {
    const { classes, disabled, children } = this.props;
    return (
      <Button
        disabled={disabled}
        variant="outlined"
        color="primary"
        type="submit"
        className={classes.button}
      >
        {children}
      </Button>
    );
  }
}

export const SubmitButton = withStyles(styles)(MySubmitButton);
