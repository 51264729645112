export default theme => ({
  backgroundWrapper: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    position: 'absolute',
    top: 0,
    minHeight: '100%',
    // bottom: 0,
    paddingLeft: 74,
    left: 0,
    right: 0,
    zIndex: 1300,
  },
  paper: {
    marginTop: 148,
    marginBottom: 88,
    padding: 30,
    paddingBottom: 0,
    paddingTop: 5,
    width: 900,
    margin: 'auto',
    zIndex: 11111,
  },
  root: {
    paddingBottom: 50,
    overflow: 'auto',
    scrollBehavior: 'smooth',
    maxHeight: 'calc(100vh - 400px)',
    '&::-webkit-scrollbar': {
      width: '0em',
    },
  },
  textField: {
    width: '100%',
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
});
