import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import MaterialTable from 'material-table';

import { REMOVE_HORSE, GET_GROUP_HORSES } from 'queries';
import { Notification, TableLoader, CDforList } from 'shared';
import HorseMembers from '../../../shared/HorseMembers';

const linkURL =
  process.env.REACT_APP_API === 'dev'
    ? 'https://dev.theracingmanager.com'
    : 'https://theracingmanager.com';

class GroupHorses extends Component {
  state = {
    open: false,
    id: null,
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { loading, error } = this.props.data;
    if (loading) return <TableLoader />;
    if (error) return <Notification message={error.message} variant="error" />;
    const { horses, updates } = this.props.data.getGroupById;
    const size = updates.size * updates.totalPages;
    const tableData = horses.map(h => ({ ...h, membersNum: h.members.length }));
    return (
      <>
        <CDforList
          open={this.state.open}
          title="Do you really want to remove this horse from group?"
          mutationQuery={REMOVE_HORSE}
          options={{
            variables: {
              id: this.state.id,
              groupId: this.props.id,
            },
            refetchQueries: [
              {
                query: GET_GROUP_HORSES,
                variables: { id: this.props.id },
              },
            ],
          }}
          handleClose={this.handleClose}
        />
        <MaterialTable
          actions={[
            {
              icon: 'add_circle',
              tooltip: 'Create Update for this Horse',
              onClick: (event, rowData) => {
                this.props.history.push(`/create-update/${rowData.id}/${this.props.id}/${size}`);
              },
            },
            {
              icon: 'poll_circle',
              tooltip: 'Create Poll for this Horse',
              onClick: (event, rowData) => {
                this.props.history.push(`/create-poll/${rowData.id}/${this.props.id}/${size}`);
              },
            },
            {
              icon: 'event_circle',
              tooltip: 'Create Event for this Horse',
              onClick: (event, rowData) => {
                this.props.history.push(`/create-event/${rowData.id}/${this.props.id}/${size}`);
              },
            },
            {
              icon: 'web_circle',
              tooltip: 'Open Horse page at TRM',
              onClick: (event, rowData) => {
                window.open(`${linkURL}/horse/${rowData.slug}`);
              },
            },
            {
              icon: 'visibility_circle',
              tooltip: 'Show Horse',
              onClick: (event, rowData) => {
                this.props.history.push(`/horses/${rowData.id}/view/info`);
              },
            },
            // {
            //   icon: "delete_forever",
            //   tooltip: "Remove horse from the group",
            //   onClick: (event, rowData) => {
            //     this.setState({ id: rowData.id, open: true });
            //   }
            // },
            {
              icon: 'edit_circle',
              tooltip: 'Edit Horse Info',
              onClick: (event, rowData) => {
                this.props.history.push(`/horses/${rowData.id}/edit`);
              },
            },
            {
              isFreeAction: true,
              icon: 'add_circle',
              tooltip: 'Add Horse to Group',
              onClick: () => {
                this.props.history.push('../addHorse');
              },
            },
          ]}
          options={{
            columnsButton: true,
            pageSize: 10,
            actionsColumnIndex: -1,
            exportButton: true,
            exportAllData: true,
            showEmptyDataSourceMessage: true,
            pageSizeOptions: [5, 10, 20, horses.length / 2, horses.length].sort((a, b) => a - b),
          }}
          columns={[
            {
              title: 'ID',
              field: 'id',
              type: 'numeric',
              defaultSort: 'desc',
            },
            { title: 'Name', field: 'name' },
            { title: 'TF ID', field: 'timeformId' },
            { title: 'FG ID', field: 'fgId' },
            { title: 'UST ID', field: 'ustId' },
            {
              title: 'Shares left',
              field: 'maxShares',
              render: rowData => Math.floor(rowData.maxShares * 100) / 100,
            },
            { title: 'Members', field: 'membersNum' },
          ]}
          data={tableData}
          detailPanel={rowData => {
            const members = [];
            rowData.members.forEach(member =>
              members.push({
                id: member.userGroupId,
                user: member.userGroup.user || {},
                role: member.role,
                shares: member.shares,
              }),
            );
            rowData.pendingMembers.forEach(invHorse =>
              members.push({
                id: invHorse.invitation.id,
                user: invHorse.invitation,
                role: `PENDING (${invHorse.role})`,
                shares: invHorse.shares,
              }),
            );
            return members.map(member => {
              return (
                <HorseMembers
                  key={member.id}
                  user={member.user}
                  role={member.role}
                  shares={member.shares}
                />
              );
            });
          }}
          title="Horses"
        />
      </>
    );
  }
}

export default graphql(REMOVE_HORSE)(
  graphql(GET_GROUP_HORSES, {
    options: props => {
      return {
        variables: { id: props.id },
      };
    },
  })(withRouter(GroupHorses)),
);
