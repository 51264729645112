import React from "react";
import { Grid } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import ChevronRight from "@material-ui/icons/ChevronRight";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  paginationText: {
    fontFamily: "Gill Sans",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "19px",
    display: "flex",
    alignItems: "center"
  }
});

export const CustomTablePagination = props => {
  const { page, rowsPerPage, count, onChangePage } = props;
  let to = rowsPerPage * (page + 1);
  let totalPages = count > 0 ? (count / rowsPerPage).toFixed(0) : 0;
  if (to > count) {
    to = count;
  }

  const classes = useStyles();

  return (
    <>
      {count > 25 && (
        <td
          style={{
            float: "right"
          }}
        >
          <Grid
            container
            alignItems="center"
            justify="flex-end"
            style={{ paddingTop: 8 }}
          >
            <Grid item>
              <IconButton
                disabled={page === 0}
                onClick={e => onChangePage(e, page - 1)}
              >
                <p
                  fontSize="small"
                  style={{ color: page === 0 ? "#AFB1B3" : "#000000" }}
                  className={classes.paginationText}
                >
                  {" "}
                  <ChevronLeft /> Prev{" "}
                </p>
              </IconButton>
            </Grid>
            <Grid item>
              <Typography
                variant="caption"
                style={{ color: "#000000" }}
                className={classes.paginationText}
              >
                {" "}
                {page}/{totalPages}{" "}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                disabled={to >= count}
                onClick={e => onChangePage(e, page + 1)}
              >
                <p
                  fontSize="small"
                  style={{ color: to < count ? "#000000" : "#AFB1B3" }}
                  className={classes.paginationText}
                >
                  {" "}
                  Next <ChevronRight />
                </p>
              </IconButton>
            </Grid>
          </Grid>
        </td>
      )}
    </>
  );
};
