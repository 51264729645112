import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import moment from 'moment';
import { useQuery } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import { GET_ORGANIZATIONS_PERFORMANCE } from 'queries';

const styles = {
  periodContainer: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'end',
    marginTop: '20px',
  },

  periodContainerTitle: {
    margin: '0 10px',
  },
};

function OrganisationView(props) {
  const { classes } = props;
  const [tableData, setTableData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const { data, loading, error, refetch } = useQuery(GET_ORGANIZATIONS_PERFORMANCE, {
    variables: {
      startDate,
      endDate,
    },
  });

  useEffect(() => {
    if (data && data.getAllOrganizations) {
      const dataToSet = data.getAllOrganizations.map(o => {
        let currDate;
        let postDate;
        if (o.posts.last) {
          currDate = moment()
            .format('YYYY-MM-DD')
            .split('-')
            .map(x => +x);
          postDate = moment(o.posts.last.createdAt)
            .format('YYYY-MM-DD')
            .split('-')
            .map(x => +x);
        }
        return {
          name: o.name,
          horses: o.horses.length,
          postsTotal: o.posts.total,
          postsPeriod: o.posts.period,
          postsAverage: o.posts.average,
          lastPostDate: o.posts.last
            ? moment(o.posts.last.createdAt).format('DD-MM-YYYY HH:mm')
            : '-',
          daysSinceLastPost: o.posts.last ? moment(currDate).diff(moment(postDate), 'days') : '-',
          lastPostAuthor: o.posts.last
            ? `${o.posts.last.author.firstName} ${o.posts.last.author.surname}`
            : '-',
          lastPostLikes: o.posts.last ? o.posts.last.likes.length : '-',
          lastPostComments: o.posts.last ? o.posts.last.comments.length : '-',
          lastPostFileType: o.posts.last ? o.posts.last.fileType : '-',
        };
      });
      setTableData(dataToSet);
    }
  }, [data]);

  const handleClearValue = () => {
    setEndDate(null);
    setStartDate(null);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className={classes.periodContainer}>
        <p className={classes.periodContainerTitle}>Selected Period: </p>
        <DatePicker
          label="Start Date"
          variant="inline"
          value={startDate}
          onChange={startDate =>
            setStartDate(
              moment(startDate)
                .startOf('day')
                .toString(),
            )
          }
          format="yyyy/MM/dd"
        />
        <DatePicker
          label="End Date"
          variant="inline"
          value={endDate}
          onChange={endDate => setEndDate(moment(endDate).endOf('day').toString())}
          format="yyyy/MM/dd"
        />
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          style={{ marginTop: '12px' }}
          onClick={handleClearValue}
        >
          clear
        </Button>
      </div>
      <MaterialTable
        columns={[
          {
            title: 'Name',
            field: 'name',
          },
          {
            title: 'Horses',
            field: 'horses',
          },
          {
            title: 'Total Posts',
            field: 'postsTotal',
          },
          {
            title: 'Posts in Period',
            field: 'postsPeriod',
          },
          {
            title: 'Average Posts per Horse',
            field: 'postsAverage',
          },
          {
            title: 'Last Post Date',
            field: 'lastPostDate',
          },
          {
            title: ' Days since last post',
            field: 'daysSinceLastPost',
          },
          {
            title: 'Last Post Author',
            field: 'lastPostAuthor',
          },
          {
            title: 'Last Post Likes',
            field: 'lastPostLikes',
          },
          {
            title: 'Last Post Comments',
            field: 'lastPostComments',
          },
          {
            title: 'Last Post File Type',
            field: 'lastPostFileType',
          },
        ]}
        options={{
          pageSize: 10,
          pageSizeOptions: [5, 10, 20, 50, 100, tableData.length],
          exportButton: true,
          exportAllData: true,
        }}
        title="Horse View"
        data={tableData}
      />
    </MuiPickersUtilsProvider>
  );
}

export default withStyles(styles)(OrganisationView);
