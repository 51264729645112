import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import moment from 'moment';
import { graphql, Mutation } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import MaterialTable from 'material-table';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import { GET_ALL_NOTES, REMOVE_NOTE } from 'queries';
import { Loader, Notification } from 'shared';

class List extends Component {
  render() {
    const { data } = this.props;
    const { loading, error, getAllNotes } = data;
    if (loading) return <Loader />;
    if (error) return <Notification message={error.message} variant="error" />;
    const notes = getAllNotes.map(note => ({
      id: note.id,
      userId: note.user ? note.user.id : null,
      groupId: note.group ? note.group.id : null,
      organisationId: note.organization ? note.organization.id : null,
      createdBy: note.createdBy ? `${note.createdBy.firstName} ${note.createdBy.surname}` : '?',
      createdAt: moment(note.createdAt).format('YYYY/MM/DD HH:mm'),
      relatedTo:
        (note.user && `USER: ${note.user.firstName} ${note.user.surname}`) ||
        (note.group && `GROUP: ${note.group.name}`) ||
        (note.organization && `ORG: ${note.organization.name}`),
      text: note.text,
    }));
    return (
      <MaterialTable
        options={{
          pageSize: 10,
          exportButton: true,
          exportAllData: true,
          pageSizeOptions: [5, 10, 20, getAllNotes.length],
          sorting: true,
        }}
        columns={[
          { title: 'Created', field: 'createdAt', defaultSort: 'desc' },
          { title: 'Author', field: 'createdBy' },
          { title: 'Related To', field: 'relatedTo' },
          { title: 'Text', field: 'text' },
          {
            title: '',
            field: '',
            render: rowData => (
              <Mutation mutation={REMOVE_NOTE}>
                {(remove, { loading, error }) => (
                  <IconButton
                    aria-label="Delete"
                    color="secondary"
                    onClick={e => {
                      e.preventDefault();
                      remove({
                        variables: { noteId: rowData.id },
                        refetchQueries: [
                          {
                            query: GET_ALL_NOTES,
                            variables: rowData.id,
                          },
                        ],
                      });
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </Mutation>
            ),
          },
        ]}
        data={notes}
        title="Client Relations Notes"
      />
    );
  }
}

List.propTypes = {
  data: PropTypes.object,
  history: PropTypes.object,
};

export const AllNotes = graphql(GET_ALL_NOTES)(withRouter(List));
