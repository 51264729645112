import React, { useEffect } from "react";
import { Box, Divider, Grid } from "@material-ui/core";

import { HorseCost } from "../Horses/HorseCost";
import { UserCost } from "../Users/UserCost";
import { Table } from "../Utils/Table";

export const OverviewTab = props => {
  const tableHeadings = [
    { title: "Organisation name", field: "organistationName" },
    { title: "Group name", field: "groupName" },
    { title: "Date Loaded", field: "dateLoaded" },
    { title: "Date finished", field: "dateFinished" },
    { title: "Breeding updates", field: "breedingUpdates" },
    { title: "SMS", field: "sms" }
  ];

  const data = props.tableData.map(row => ({
    ...row,
    dateFinished: row.dateFinished ?? '-',
  }))
  return (
    <Box>
      <Divider light />
      <HorseCost
        horseCost={props.horseCost}
        setHorseCost={props.setHorseCost}
        agreedHorseDays={props.agreedHorseDays}
        setAgreedHorseDays={props.setAgreedHorseDays}
        totalHorseDays={props.totalHorseDays}
        avgHorsesPerDay={props.avgHorsesPerDay}
        horseDifferenceAmount={props.horseDifferenceAmount}
        agreedCost={props.agreedCost}
      />
      <Divider light />
      <UserCost
        calculatedUserValues={props.calculatedUserValues}
        annualCostUser={props.annualCostUser}
        setAnnualCostUsers={props.setAnnualCostUsers}
        agreedNumberUser={props.agreedNumberUser}
        setAgreedNumberUser={props.setAgreedNumberUser}
        totalUserSubs={props.totalUserSubs}
        avgUserSubsPerDay={props.avgUserSubsPerDay}
        userDifferenceAmount={props.userDifferenceAmount}
        totalUsers={props.totalUsers}
        agreedUserCost={props.agreedUserCost}
      />
      <Divider light />
      <Grid container style={{}}>
        <Grid item xs={10} sm={4} md={4} className={["text", "gridItem"]}>
          Total difference Horses and subs
        </Grid>
        <Grid
          item
          xs={10}
          sm={4}
          md={2}
          className={["textHighlight", "gridItem"]}
        >
          £{" "}
          {parseInt(props.horseDifferenceAmount)
            ? props.horseDifferenceAmount
            : 0}{" "}
          <span className="smallText">Horse Cost</span>
        </Grid>
        <Grid
          item
          xs={10}
          sm={4}
          md={2}
          className={["textHighlight", "gridItem"]}
        >
          £{" "}
          {parseInt(props.userDifferenceAmount)
            ? props.userDifferenceAmount
            : 0}{" "}
          <span className="smallText">User Cost</span>
        </Grid>
      </Grid>
      <Table tableHeadings={tableHeadings} tableData={data} />
    </Box>
  );
};
