import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import MaterialTable from 'material-table';

import { DELETE_HORSE, GET_ALL_HORSES, UPDATE_HORSE_HISTORY } from 'queries';
import { graphql, useMutation } from 'react-apollo';
import { withRouter } from 'react-router-dom';

import { ConfirmationDialog, Loader, Notification } from 'shared';
import { horseColours, horseGenders } from 'shared/data';

const linkURL =
  process.env.REACT_APP_API === 'dev'
    ? 'https://dev.theracingmanager.com'
    : 'https://theracingmanager.com';

function mapHorseTableData(data) {
  return data.map(h => {
    const horseDetails = h.timeformHorse || h.fgHorse || h.ustHorse;
    let gender = '-',
      colour = '-';

    if (h.gender) {
      gender = <b>{horseGenders[h.gender]}</b>;
    } else if (horseDetails && Object.hasOwn(horseDetails, 'horseGender')) {
      gender = horseDetails.horseGender;
    }

    if (h.colour && h.secondColour) {
      colour = (
        <b>
          {horseColours[h.colour]}/{horseColours[h.secondColour]}
        </b>
      );
    } else if (h.colour) {
      colour = <b>{horseColours[h.colour]}</b>;
    } else if (horseDetails && Object.hasOwn(horseDetails, 'horseColour')) {
      colour = horseDetails.horseColour;
    }

    return {
      ...h,
      groupName: h.group ? h.group.name : 'Not in a group',
      silkCode: (
        <img
          alt={h.name}
          src={
            h.silkCode && h.silkCode.includes('france-galop')
              ? h.silkCode
              : `https://images.timeform.com/silks/${h.silkCode ||
                  (h.timeformHorse ? h.timeformHorse.silkCode : 111111)}.png`
          }
          style={{ outline: h.silkCode ? '2px solid black' : 'unset' }}
        />
      ),
      gender,
      colour,
    };
  });
}

function HorseList(props) {
  const { data, history } = props;
  const { loading, error, getAllHorses } = data;
  const [updateHorseHistory] = useMutation(UPDATE_HORSE_HISTORY);
  const [notification, setNotification] = useState({
    isVisible: false,
    message: null,
    variant: null,
  });

  if (loading) return <Loader />;
  if (error) return <Notification message={error.message} variant="error" />;
  const tableData = mapHorseTableData(getAllHorses);

  return (
    <>
      <Grid container direction="row" justify="center" alignItems="center">
        <MaterialTable
          actions={[
            {
              icon: 'visibility_circle',
              tooltip: 'Show Horse',
              onClick: (event, rowData) => {
                history.push(`/horses/${rowData.id}/view/info`);
              },
            },
            {
              icon: 'edit_circle',
              tooltip: 'Edit Horse Info',
              onClick: (event, rowData) => {
                history.push(`horses/${rowData.id}/edit`);
              },
            },
            {
              icon: 'update',
              tooltip: 'Update Horse History',
              onClick: (event, rowData) => {
                updateHorseHistory({ variables: { id: rowData.id } })
                  .then(res => {
                    if (!res?.data?.horse?.updateHistory) {
                      setNotification(n => ({
                        ...n,
                        isVisible: true,
                        message: (
                          <div>
                            <div>Could not update horse history!</div>
                            <div>Check if horse is linked.</div>
                          </div>
                        ),
                        variant: 'error',
                      }));
                    } else {
                      setNotification(n => ({
                        ...n,
                        isVisible: true,
                        message: (
                          <div>
                            <div>Horse is being updated!</div>
                            <div>Check it in a while...</div>
                          </div>
                        ),
                        variant: 'success',
                      }));
                    }
                  })
                  .catch(() => {
                    setNotification(n => ({
                      ...n,
                      isVisible: true,
                      message: (
                        <div>
                          <div>Could not update horse history!</div>
                          <div>Unexpected error happened.</div>
                        </div>
                      ),
                      variant: 'error',
                    }));
                  });
              },
            },
            {
              icon: 'group_circle',
              tooltip: 'Horse Group',
              onClick: (event, rowData) => {
                history.push(`groups/${rowData.groupId}/view/horses`);
              },
            },
            {
              icon: 'web_circle',
              tooltip: 'Open Horse page at website',
              onClick: (event, rowData) => {
                window.open(`${linkURL}/horse/${rowData.slug}`);
              },
            },
            // {
            //   isFreeAction: true,
            //   icon: "add_circle",
            //   tooltip: "Add Horse",
            //   onClick: () => {
            //     this.history.push("/horses/create");
            //   }
            // }
          ]}
          options={{
            grouping: true,
            columnsButton: true,
            pageSize: 10,
            exportButton: true,
            exportAllData: true,
            pageSizeOptions: [5, 10, 20, tableData.length],
          }}
          columns={[
            { title: 'ID', field: 'id', type: 'numeric', defaultSort: 'desc' },
            { title: 'Name', field: 'name' },
            {
              title: 'Gender',
              field: 'gender',
            },
            {
              title: 'Colour',
              field: 'colour',
            },
            { title: 'Foaling Date', field: 'foalingDate' },
            {
              title: 'Group Name',
              field: 'groupName',
              cellStyle: val => {
                if (val === 'Not in a group') {
                  return {
                    color: 'red',
                    textTransform: 'uppercase',
                  };
                }
                return null;
              },
            },
            {
              title: 'Silk',
              field: 'silkCode',
            },
            {
              title: 'Timeform Linked',
              field: 'isLinkedToTimeform',
              type: 'boolean',
            },
            {
              title: 'FG Linked',
              field: 'isLinkedToFranceGalop',
              type: 'boolean',
            },
            {
              title: 'US Timeform Linked',
              field: 'isLinkedToUST',
              type: 'boolean',
            },
            {
              title: '',
              field: '',
              render: rowData => (
                <ConfirmationDialog
                  iconButton="Delete Horse"
                  title="Do you really want to delete this horse?"
                  mutationQuery={DELETE_HORSE}
                  refetchQ={GET_ALL_HORSES}
                  id={rowData.id}
                  redirectPath="/horses"
                />
              ),
            },
          ]}
          data={tableData}
          title="Horses"
        />
      </Grid>
      {notification.isVisible && (
        <Notification
          message={notification.message}
          variant={notification.variant}
          onClose={() => {
            setNotification(n => ({ ...n, isVisible: false, message: null, variant: null }));
          }}
        />
      )}
    </>
  );
}

HorseList.propTypes = {
  history: PropTypes.object,
  data: PropTypes.object,
};

export default graphql(GET_ALL_HORSES)(withRouter(HorseList));
