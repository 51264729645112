import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const styles = theme => ({
  title: {
    color: '#fff',
    background: '#142339',
    marginTop: -5,
    marginLeft: -33,
    marginRight: -33,
    padding: 15,
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);',
    borderRadius: 4,
  },
});

class MyPaperTitle extends Component {
  render() {
    const { classes, children } = this.props;

    return (
      <Typography variant="h6" className={classes.title}>
        {children}
      </Typography>
    );
  }
}

export const PaperTitle = withStyles(styles)(MyPaperTitle);
