import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { graphql, withApollo } from 'react-apollo';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import { countries, currencies, services } from 'shared/data';
import DateFormattedInput from 'shared/FormattedInput';
import { UPDATE_HORSE, GET_HORSE, GET_ALL_HORSES, DELETE_HORSE, SEARCH_HORSE, SEARCH_FG_INPUT_HORSE, SEARCH_UST_INPUT_HORSE } from 'queries';
import {
  Loader,
  Notification,
  SubmitButton,
  CenteredPaper,
  PaperTitle,
  ConfirmationDialog,
  Autosuggest,
} from 'shared';
import Selector from 'shared/Selector';
import { getCountryFromName } from 'shared/utils';
import ChangeGroup from 'Horse/HorseEdit/ChangeGroup';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    width: 255,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  formControl: {
    marginTop: 16,
    minWidth: 70,
    display: 'flex',
    flexWrap: 'wrap',
  },
});

class HorseEdit extends Component {
  state = {
    id: '',
    name: '',
    gender: '',
    colour: '',
    secondColour: '',
    foalingDate: '',
    silkCode: '',
    trainerName: '',
    ownerName: '',
    timeformId: '',
    fgId: '',
    ustId: '',
    groupId: '',
    countryOfOrigin: '',
    sireName: '',
    damName: '',
    damSireName: '',
    damCountry: '',
    sireCountry: '',
    damSireCountry: '',
    stud: '',
    saleLocation: '',
    purchasePrice: '',
    purchaseCurrency: '',
    horseOptions: [],
    service: null,
    isSouthernHemisphere: false,
  };

  componentDidMount() {
    const { getHorseById } = this.props.data;
    if (getHorseById && getHorseById.timeformHorse) {
      this.setHorseDetails(getHorseById, 'timeformHorse');
    }
    if (getHorseById && getHorseById.fgHorse) {
      this.setHorseDetails(getHorseById, 'fgHorse');
    }
    if (getHorseById && getHorseById.ustHorse) {
      this.setHorseDetails(getHorseById, 'ustHorse');
    }
  }

  componentDidUpdate(prevProps) {
    const { getHorseById } = this.props.data;
    if (getHorseById && prevProps.data.getHorseById !== getHorseById  && getHorseById.fgHorse) {
      this.setHorseDetails(getHorseById, 'fgHorse');
    }
    if (
      prevProps.data.getHorseById !== getHorseById &&
      getHorseById &&
      getHorseById.timeformHorse
    ) {
      this.setHorseDetails(getHorseById, 'timeformHorse');
    }
    if (prevProps.data.getHorseById !== getHorseById && getHorseById && getHorseById.ustHorse) {
      this.setHorseDetails(getHorseById, 'ustHorse');
    }
    if (
      prevProps.data.getHorseById !== getHorseById &&
      getHorseById &&
      !getHorseById.timeformHorse &&
      !getHorseById.fgHorse &&
      !getHorseById.ustHorse
    ) {
      this.setHorseDetails(getHorseById, null);
    }
  }

  setHorseDetails(horseDetails, horseType) {
    const details = horseType ? horseDetails[horseType] : [];
    this.setState({
      ...details,
      ...horseDetails,
      countryOfOrigin: getCountryFromName(horseDetails.name),
      damCountry: getCountryFromName(details.damName),
      sireCountry: getCountryFromName(details.sireName),
      damSireCountry: getCountryFromName(details.damSireName),
      foalingDate: moment(horseDetails.foalingDate, 'YYYY-MM-DD').format('DD/MM/YYYY'),
    });
  }

  submitHandler = event => {
    const { foalingDate, groupId, purchasePrice } = this.state;

    const { id } = this.props.match.params;

    event.preventDefault();
    this.props
      .mutate({
        variables: {
          ...this.state,
          id,
          foalingDate,
          groupId: parseInt(groupId, 10),
          purchasePrice: parseInt(purchasePrice, 10),
        },
        refetchQueries: [{ query: GET_ALL_HORSES }],
      })
      .then(this.props.data.refetch())
      .then(() => this.props.history.push('/horses'))
      .catch(res => {
        this.setState({ error: localStorage.getItem('error') });
      })
      .then(setTimeout(() => this.setState({ error: null })), 2000);
  };

  handleChange = input => e => {
    this.setState({ [input]: e.target.value });
  };

  handleChangeCountry = input => e => {
    const { value } = e.target;
    const { name, damName, sireName, damSireName } = this.state;
    const parsedName = name.split(' (')[0];
    const parsedSireName = sireName.split(' (')[0];
    const parsedDamName = damName.split(' (')[0];
    const parsedDamSireName = damSireName.split(' (')[0];
    switch (input) {
      case 'countryOfOrigin':
        this.setState({ name: name ? `${parsedName} (${value})` : '' });
        break;
      case 'sireCountry':
        this.setState({
          sireName: sireName ? `${parsedSireName} (${value})` : '',
        });
        break;
      case 'damCountry':
        this.setState({
          damName: damName ? `${parsedDamName} (${value})` : '',
        });
        break;
      case 'damSireCountry':
        this.setState({
          damSireName: damSireName ? `${parsedDamSireName} (${value})` : '',
        });
        break;
      default:
        return;
    }
    this.setState({ [input]: value });
  };

  handleChangeService = input => e => {
    if (!this.service) {
      if (e.target.value === 'TIMEFORM') {
        this.setState({ timeformId: this.state.name, ustId: null, fgId: null });
      }
      if (e.target.value === 'UST') {
        this.setState({ timeformId: null, ustId: this.state.name, fgId: null });
      }
      if (e.target.value === 'FG') {
        this.setState({ timeformId: null, ustId: null, fgId: this.state.name });
      }
    }
    this.setState({
      [input]: e.target.value,
    });
  };

  handleAutosuggestSearch = e => {
    const { client } = this.props;
    client
      .query({
        query: SEARCH_HORSE,
        variables: { name: e },
      })
      .then(res => {
        const horseOptions = res.data.searchHorse.map(horse => ({
          ...horse,
          value: horse.timeformId,
          label: `${horse.name} ${horse.foalingDate ? horse.foalingDate.split('T')[0] : ''}`,
          colour: horse.horseColour,
          gender: horse.horseGender,
        }));
        this.setState({ horseOptions });
      });
  };

  handleAutosuggestSearchFG = e => {
    const { client } = this.props;
    client
      .query({
        query: SEARCH_FG_INPUT_HORSE,
        variables: { name: e },
      })
      .then(res => {
        console.log(res.data.searchFGInputHorse)
        const horseOptions = res.data.searchFGInputHorse.map(horse => ({
          ...horse,
          value: horse.fgId,
          label: `${horse.name} ${horse.origin} ${horse.birthYear}`,
        }));
        this.setState({ horseOptions });
      });
  };

  handleAutosuggestSearchUST = e => {
    const { client } = this.props;
    client
      .query({
        query: SEARCH_UST_INPUT_HORSE,
        variables: { name: e },
      })
      .then(res => {
        const horseOptions = res.data.searchUSTInputHorse.map(horse => ({
          ...horse,
          value: horse.ustId,
          label: `${horse.name} ${moment(horse.foalingDate).year()}`,
        }));
        this.setState({ horseOptions });
      });
  };

  handleLinkHorse = horse => {
    this.setState({
      timeformId: horse.timeformId,
      name: horse.name,
      countryOfOrigin: getCountryFromName(horse.name),
    });
  };

  handleLinkHorseFG = horse => {
    this.setState({
      fgId: horse.fgId,
      name: horse.name,
      countryOfOrigin: getCountryFromName(horse.name),
    });
  };

  handleLinkHorseUST = horse => {
    this.setState({
      ustId: horse.ustId,
      name: horse.name,
      countryOfOrigin: getCountryFromName(horse.name),
    });
  };

  render() {
    const { classes } = this.props;
    const { loading, error, getHorseById } = this.props.data;
    if (loading) return <Loader />;
    if (error) return <Notification message={error.message} variant="error" />;
    const {
      name,
      gender,
      colour,
      secondColour,
      foalingDate,
      timeformId,
      fgId,
      ustId,
      groupId,
      silkCode,
      trainerName,
      ownerName,
      countryOfOrigin,
      damName,
      sireName,
      damSireName,
      damCountry,
      sireCountry,
      damSireCountry,
      stud,
      horseOptions,
      saleLocation,
      purchasePrice,
      purchaseCurrency,
      service,
      location,
      isSouthernHemisphere,
    } = this.state;
    const horseDetails =
      getHorseById.timeformHorse || getHorseById.fgHorse || getHorseById.ustHorse || {};
    const isLinkedToTimeform = /\d{12}/.test(getHorseById.timeformId);
    const isLinkedToFG = /\d{8}/.test(getHorseById.fgId);
    const isLinkedToUST = /(?=^[A-Z0-9]{8}$)(?=[A-Z]{0,}[0-9][A-Z]{0,})/.test(getHorseById.ustId);
    const isLinked = isLinkedToTimeform || isLinkedToFG || isLinkedToUST;

    return (
      <form onSubmit={this.submitHandler}>
        <CenteredPaper>
          <PaperTitle>{`Edit Horse: ${name}`}</PaperTitle>
          <Grid container className={classes.root} spacing={24} alignItems="center">
            <Grid item xs={12} sm={12}>
              <TextField
                id="standard-select-period"
                select
                label="Choose Service"
                value={service}
                className={classes.textField}
                onChange={this.handleChangeService('service')}
                style={{ width: '100%' }}
              >
                {Object.keys(services).map(key => (
                  <MenuItem value={key}>{services[key]}</MenuItem>
                ))}
              </TextField>
            </Grid>
            {service === 'TIMEFORM' && !isLinkedToTimeform && (
              <>
                <Grid item xs={10} sm={10}>
                  <Autosuggest
                    options={horseOptions}
                    handleChange={this.handleLinkHorse}
                    onInputChange={this.handleAutosuggestSearch}
                    placeholder="Search a horse on Timeform"
                  />
                </Grid>
                <Grid item container alignItems="flex-end" xs={2} sm={2}>
                  <Button onClick={this.submitHandler} variant="contained" color="secondary">
                    Link
                  </Button>
                </Grid>
              </>
            )}
            {service === 'FG' && !isLinkedToFG && (
              <>
                <Grid item xs={10} sm={10}>
                  <Autosuggest
                    options={horseOptions}
                    handleChange={this.handleLinkHorseFG}
                    onInputChange={this.handleAutosuggestSearchFG}
                    placeholder="Search a horse on France Galop"
                  />
                </Grid>
                <Grid item container alignItems="flex-end" xs={2} sm={2}>
                  <Button onClick={this.submitHandler} variant="contained" color="secondary">
                    Link
                  </Button>
                </Grid>
              </>
            )}
            {service === 'UST' && !isLinkedToUST && (
              <>
                <Grid item xs={10} sm={10}>
                  <Autosuggest
                    options={horseOptions}
                    handleChange={this.handleLinkHorseUST}
                    onInputChange={this.handleAutosuggestSearchUST}
                    placeholder="Search a horse on US Timeform"
                  />
                </Grid>
                <Grid item container alignItems="flex-end" xs={2} sm={2}>
                  <Button onClick={this.submitHandler} variant="contained" color="secondary">
                    Link
                  </Button>
                </Grid>
              </>
            )}
            {service === 'TIMEFORM' && (
              <Grid item xs={12} sm={6}>
                <TextField
                  label="TimeForm ID"
                  value={timeformId}
                  onChange={this.handleChange('timeformId')}
                  className={classes.textField}
                  margin="normal"
                />
              </Grid>
            )}
            {service === 'FG' && (
              <Grid item xs={12} sm={6}>
                <TextField
                  label="FranceGalop ID"
                  value={fgId}
                  onChange={this.handleChange('fgId')}
                  className={classes.textField}
                  margin="normal"
                />
              </Grid>
            )}
            {service === 'UST' && (
              <Grid item xs={12} sm={6}>
                <TextField
                  label="US Timeform ID"
                  value={ustId}
                  onChange={this.handleChange('ustId')}
                  className={classes.textField}
                  margin="normal"
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <TextField
                disabled
                type="number"
                label="Group ID"
                value={groupId}
                onChange={this.handleChange('groupId')}
                className={classes.textField}
                margin="normal"
              />
            </Grid>
            <Grid item xs={10} sm={6}>
              <TextField
                label="Silk Code"
                value={silkCode}
                onChange={this.handleChange('silkCode')}
                className={classes.textField}
                margin="normal"
              />
            </Grid>
            <Grid item xs={2}>
              <Typography>Preview:</Typography>
              <img
                style={{ width: '40px' }}
                alt={name}
                src={silkCode && silkCode.includes('france-galop') ? silkCode : `https://images.timeform.com/silks/${silkCode || horseDetails.silkCode}.png`}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography>TF Value: {horseDetails.silkCode}</Typography>
              <img
                alt={name}
                src={`https://images.timeform.com/silks/${horseDetails.silkCode}.png`}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                label="Name"
                value={name}
                onChange={this.handleChange('name')}
                className={classes.textField}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Selector label="Horse country of origin">
                <Select
                  value={countryOfOrigin}
                  onChange={this.handleChangeCountry('countryOfOrigin')}
                  input={<Input name="countryOfOrigin" />}
                  displayEmpty
                  className={classes.selectEmpty}
                >
                  {countries.map(country => (
                    <MenuItem value={country.key}>{country.label}</MenuItem>
                  ))}
                </Select>
              </Selector>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Selector label="Gender">
                <Select
                  value={gender}
                  onChange={this.handleChange('gender')}
                  input={<Input name="gender" />}
                  displayEmpty
                  className={classes.selectEmpty}
                >
                  <MenuItem value={null}>Use TF</MenuItem>
                  <MenuItem value="g">Gelding</MenuItem>
                  <MenuItem value="m">Mare</MenuItem>
                  <MenuItem value="c">Colt</MenuItem>
                  <MenuItem value="f">Filly</MenuItem>
                  <MenuItem value="b">Broodmare</MenuItem>
                  <MenuItem value="r">Rig</MenuItem>
                  <MenuItem value="h">Horse</MenuItem>
                </Select>
              </Selector>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Selector label="Colour">
                <Select
                  value={colour}
                  onChange={this.handleChange('colour')}
                  input={<Input name="colour" />}
                  displayEmpty
                  className={classes.selectEmpty}
                >
                  <MenuItem value={null}>Use TF</MenuItem>
                  <MenuItem value="ch">Chestnut</MenuItem>
                  <MenuItem value="gr">Grey</MenuItem>
                  <MenuItem value="b">Bay</MenuItem>
                  <MenuItem value="br">Brown</MenuItem>
                  <MenuItem value="bl">Black</MenuItem>
                  <MenuItem value="wh">White</MenuItem>
                  <MenuItem value="pt">Coloured</MenuItem>
                  <MenuItem value="ro">Roa</MenuItem>
                  <MenuItem value="dkb">Dark Bay</MenuItem>
                  <MenuItem value="pal">Palomino</MenuItem>
                </Select>
              </Selector>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Selector label="Second Colour">
                <Select
                  value={secondColour}
                  onChange={this.handleChange('secondColour')}
                  input={<Input name="secondColour" />}
                  displayEmpty
                  className={classes.selectEmpty}
                >
                  <MenuItem value={null}>No colour</MenuItem>
                  <MenuItem value="ch">Chestnut</MenuItem>
                  <MenuItem value="gr">Grey</MenuItem>
                  <MenuItem value="b">Bay</MenuItem>
                  <MenuItem value="br">Brown</MenuItem>
                  <MenuItem value="bl">Black</MenuItem>
                  <MenuItem value="wh">White</MenuItem>
                  <MenuItem value="pt">Coloured</MenuItem>
                  <MenuItem value="ro">Roa</MenuItem>
                  <MenuItem value="dkb">Dark Bay</MenuItem>
                  <MenuItem value="pal">Palomino</MenuItem>
                </Select>
              </Selector>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Foaling Date (DD/MM/YYYY)"
                value={foalingDate}
                onChange={this.handleChange('foalingDate')}
                className={classes.textField}
                InputProps={{
                  inputComponent: DateFormattedInput,
                }}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Selector label="Is this horse from the Southern Hemisphere?">
                <Select
                  value={isSouthernHemisphere}
                  onChange={this.handleChange('isSouthernHemisphere')}
                  input={<Input name="isSouthernHemisphere" />}
                  displayEmpty
                  className={classes.selectEmpty}
                >
                  <MenuItem value={false}>No</MenuItem>
                  <MenuItem value={true}>Yes</MenuItem>
                </Select>
              </Selector>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Stud"
                value={stud}
                onChange={this.handleChange('stud')}
                margin="normal"
                className={classes.textField}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Location"
                value={location}
                onChange={this.handleChange('location')}
                margin="normal"
                className={classes.textField}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Sale Location"
                value={saleLocation}
                onChange={this.handleChange('saleLocation')}
                margin="normal"
                className={classes.textField}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                type="number"
                label="Purchase Price"
                value={purchasePrice}
                onChange={this.handleChange('purchasePrice')}
                margin="normal"
              />
            </Grid>
            <Grid item xs={2}>
              <Selector label="Currency" classes={{ formControl: classes.formControl }}>
                <Select
                  value={purchaseCurrency}
                  onChange={this.handleChange('purchaseCurrency')}
                  input={<Input name="purchaseCurrency" />}
                  className={classes.selectEmpty}
                >
                  {Object.keys(currencies).map(key => (
                    <MenuItem value={key}>{currencies[key]}</MenuItem>
                  ))}
                </Select>
              </Selector>
            </Grid>
            <Grid item xs={6}>
              <TextField
                disabled={isLinked}
                label="Dam Name"
                value={damName}
                onChange={this.handleChange('damName')}
                fullWidth
                margin="none"
              />
            </Grid>
            <Grid item xs={6}>
              <Selector label="Dam's country of origin">
                <Select
                  disabled={isLinked}
                  value={damCountry}
                  onChange={this.handleChangeCountry('damCountry')}
                  input={<Input name="damCountry" />}
                  displayEmpty
                >
                  {countries.map(country => (
                    <MenuItem value={country.key}>{country.label}</MenuItem>
                  ))}
                </Select>
              </Selector>
            </Grid>
            <Grid item xs={6}>
              <TextField
                disabled={isLinked}
                label="Dam Sire Name"
                value={damSireName}
                onChange={this.handleChange('damSireName')}
                fullWidth
                margin="none"
              />
            </Grid>
            <Grid item xs={6}>
              <Selector label="Dam Sire's country of origin">
                <Select
                  disabled={isLinked}
                  value={damSireCountry}
                  onChange={this.handleChangeCountry('damSireCountry')}
                  input={<Input name="damSireCountry" />}
                  displayEmpty
                >
                  {countries.map(country => (
                    <MenuItem value={country.key}>{country.label}</MenuItem>
                  ))}
                </Select>
              </Selector>
            </Grid>
            <Grid item xs={6}>
              <TextField
                disabled={isLinked}
                label="Sire Name"
                value={sireName}
                onChange={this.handleChange('sireName')}
                fullWidth
                margin="none"
              />
            </Grid>
            <Grid item xs={6}>
              <Selector label="Sire's country of origin">
                <Select
                  disabled={isLinked}
                  value={sireCountry}
                  onChange={this.handleChangeCountry('sireCountry')}
                  input={<Input name="sireCountry" />}
                  displayEmpty
                >
                  {countries.map(country => (
                    <MenuItem value={country.key}>{country.label}</MenuItem>
                  ))}
                </Select>
              </Selector>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                label="Trainer Name"
                value={trainerName || horseDetails.trainerFullName}
                onChange={this.handleChange('trainerName')}
                className={classes.textField}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Owner Name"
                value={ownerName || horseDetails.ownerFullName}
                onChange={this.handleChange('ownerName')}
                className={classes.textField}
                margin="normal"
              />
            </Grid>

            <ChangeGroup horseId={this.props.match.params.id} />

            <Grid item xs={12} sm={9}>
              <SubmitButton>Update Horse</SubmitButton>
            </Grid>
            <Grid item xs={12} sm={3}>
              <ConfirmationDialog
                title="Do you really want to delete this horse?"
                mutationQuery={DELETE_HORSE}
                refetchQ={GET_ALL_HORSES}
                id={this.props.match.params.id}
                redirectPath="/horses"
              />
            </Grid>
          </Grid>
        </CenteredPaper>
        {this.state.error ? <Notification message={this.state.error} variant="error" /> : null}
      </form>
    );
  }
}

export default graphql(UPDATE_HORSE)(
  graphql(GET_HORSE, {
    options: props => {
      return {
        variables: { id: props.match.params.id },
        fetchPolicy: 'network-only'
      };
    },
  })(withApollo(withRouter(withStyles(styles)(HorseEdit)))),
);
