import React, { useState } from "react";
import { InputLabel } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import Button from "@material-ui/core/Button";

import { Autoselect } from "./Autoselect";
import "../styles.css";

const useStyles = makeStyles({
  align: {
    display: "flex",
    alignItems: "center"
  },
  labelText: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "18px",
    color: "#13233A",
    display: "flex",
    alignItems: "center",
    fontFamily: "Gill Sans",
    fontStyle: "normal",
    marginRight: "30px"
  },
  overviewLabel: {
    marginRight: "30px"
  },
  dateFromLabel: {
    marginRight: "50px"
  },
  supText: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "18px",
    color: "#13233A",
    fontStyle: "normal",
    fontFamily: "Gill sans"
  },
  textBold: {
    fontWeight: 700
  },
  downArrow: {
    "&:hover": {
      cursor: "pointer"
    }
  },
  calendarText: {
    fontFamily: "Gill Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "18px"
  }
});

export const SearchFilter = ({
  tab,
  orgData,
  groupData,
  setSelectedOrg,
  setSelectedGroup,
  totalDays,
  dateToValue,
  setDateToValue,
  dateFromValue,
  setDateFromValue,
  selectedGroup,
  horseTableData,
  horseFilterData,
  setHorseFilterData,
  userTableData,
  userFilterData,
  setUserFilterData,
  historicalHorsesTableData,
  historicalHorsesFilterData,
  setHistoricalHorsesFilterData,
  disableGroup
}) => {
  const [dateToOpen, setDateToOpen] = useState(false);
  const [dateFromOpen, setDateFromOpen] = useState(false);

  const [showOrgResults, setShowOrgResults] = useState(false);
  const [showGroupResults, setShowGroupResults] = useState(false);

  const classes = useStyles();

  // let horseTableDataFiltered;
  // let userTableDataFiltered;
  // let historicalHorsesTableDataFiltered;

  // horseTableDataFiltered = horseTableData.map((horse) => {
  //     return {
  //         id: horse.id,
  //         name: horse.name,
  //     }
  // });

  // userTableDataFiltered = userTableData.map(user => {
  //     return {
  //         name: user.name,
  //     }
  // })

  // historicalHorsesTableDataFiltered = historicalHorsesTableData.map(horse => {
  //     return {
  //         id: horse.id,
  //         name: horse.name,
  //     }
  // })

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <form
        className="panelItemSpacing"
        style={{ width: tab === 0 ? 980 : "100%" }}
      >
        <Grid container direction="row" spacing={2}>
          <Grid item md={tab === 0 ? 5 : 4} className={classes.align}>
            <InputLabel
              htmlFor="organisation-input"
              className={`${classes.labelText} ${classes.overviewLabel}`}
            >
              Organisation{" "}
              <KeyboardArrowDownIcon
                onClick={() => setShowOrgResults(!showOrgResults)}
              />
            </InputLabel>
            <Autoselect
              options={orgData}
              externalShowBoxInput={showOrgResults}
              setSearchTermValue={setSelectedOrg}
              id="organisation-input"
            />
          </Grid>
          <Grid item md={tab === 0 ? 5 : 4} className={classes.align}>
            <InputLabel htmlFor="group-input" className={classes.labelText}>
              Group{" "}
              {!disableGroup && (
                <KeyboardArrowDownIcon
                  onClick={() => setShowGroupResults(!showGroupResults)}
                />
              )}
            </InputLabel>
            <Autoselect
              options={groupData}
              searchTermValue={selectedGroup}
              externalShowBoxInput={showGroupResults}
              setSearchTermValue={setSelectedGroup}
              id="group-input"
              disable={disableGroup}
            />
          </Grid>
          <Grid item md={tab === 0 ? 1 : 4} />
          <Grid item md={tab === 0 ? 5 : 4} className={classes.align}>
            <InputLabel
              htmlFor="date-from"
              className={`${classes.labelText} ${classes.dateFromLabel}`}
            >
              Date from
              <KeyboardArrowDownIcon
                onClick={() => setDateFromOpen(true)}
                className={classes.downArrow}
              />
            </InputLabel>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              value={dateFromValue}
              margin="normal"
              id="date-from"
              onChange={(date, value) => {
                setDateFromValue(date);
                setDateFromOpen(false);
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
                style: {
                  display: "none"
                }
              }}
              open={dateFromOpen}
              onClose={() => setDateFromOpen(false)}
              InputProps={{
                disableUnderline: true,
                className: classes.calendarText
              }}
            />
          </Grid>
          <Grid item md={tab === 0 ? 5 : 3} className={classes.align}>
            <InputLabel htmlFor="date-to" className={classes.labelText}>
              Date to{" "}
              <KeyboardArrowDownIcon
                onClick={() => setDateToOpen(true)}
                className={classes.downArrow}
              />
            </InputLabel>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              value={dateToValue}
              margin="normal"
              id="date-to"
              onChange={(date, value) => {
                setDateToValue(date);
                setDateToOpen(false);
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
                style: {
                  display: "none"
                }
              }}
              open={dateToOpen}
              onClose={() => setDateToOpen(false)}
              InputProps={{
                disableUnderline: true,
                className: classes.calendarText
              }}
              minDate={dateFromValue}
              minDateMessage="Date cannot be before From value"
            />
          </Grid>
          {tab !== 0 && (
            <>
              <Grid item md={2} className={classes.align}>
                <span className={classes.supText}>
                  N<sup>o</sup> of Days{" "}
                  <span className={classes.textBold}>{totalDays}</span>
                </span>
              </Grid>
              <Grid item md={3} className={classes.align}>
                <InputLabel htmlFor="horse-input" className={classes.labelText}>
                  Filter by:{" "}
                </InputLabel>
                {tab === 1 && (
                  <Autoselect
                    options={horseTableData}
                    searchTermValue={horseFilterData}
                    setSearchTermValue={setHorseFilterData}
                    id="horse-input"
                    placeholder="Horse Name"
                    disable={!horseTableData.length > 0}
                  />
                )}
                {tab === 2 && (
                  <Autoselect
                    options={userTableData}
                    searchTermValue={userFilterData}
                    setSearchTermValue={setUserFilterData}
                    id="horse-input"
                    placeholder="Username"
                    disable={!userTableData.length > 0}
                  />
                )}
                {tab === 3 && (
                  <Autoselect
                    options={historicalHorsesTableData}
                    searchTermValue={historicalHorsesFilterData}
                    setSearchTermValue={setHistoricalHorsesFilterData}
                    id="horse-input"
                    placeholder="Horse Name"
                    disable={!historicalHorsesTableData.length > 0}
                  />
                )}
              </Grid>
            </>
          )}
          <Grid item md={tab === 0 ? 5 : 4} className={classes.align}>
            <Button
              variant="outlined"
              color="primary"
              className={classes.button}
              onClick={() => {
                window.location.reload();
              }}
            >
              Refresh Accounting
            </Button>
          </Grid>
        </Grid>
      </form>
      <Divider light />
    </MuiPickersUtilsProvider>
  );
};
