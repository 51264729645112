import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  paper: {
    padding: 30,
    paddingTop: 5,
    width: 600,
    margin: 'auto',
  },
  width: {
    padding: 0,
  },
});

class MyPaper extends Component {
  render() {
    const { classes, children } = this.props;
    return <Paper className={classes.paper}>{children}</Paper>;
  }
}

export const CenteredPaper = withStyles(styles)(MyPaper);
