import React from 'react';
import { Mutation, Query } from 'react-apollo';
import { Paper, Typography, FormControlLabel, Switch, withStyles } from '@material-ui/core';
import { GET_GROUP, EDIT_GROUP_PERMISSIONS, UPDATE_GROUP } from 'queries';

const styles = {
  paper: {
    padding: 20,
  },
};

const GroupPermissions = ({ id, classes }) => {
  return (
    <Mutation mutation={UPDATE_GROUP}>
      {editGroup => (
        <Mutation mutation={EDIT_GROUP_PERMISSIONS}>
          {edit => (
            <Query
              query={GET_GROUP}
              variables={{ id }}
              fetchPolicy="network-only"
              onError={console.log}
            >
              {({ data = {}, loading }) => {
                let group = {};
                if (loading) {
                  return (
                    <Paper className={classes.paper}>
                      <Typography color="textPrimary" variant="h5">
                        Loading...
                      </Typography>
                    </Paper>
                  );
                }
                if (!loading) {
                  group = data.getGroupById;
                }
                const permission =
                  data && data.getGroupById && data.getGroupById.permissions
                    ? data.getGroupById.permissions.find(p => p.name === 'guest')
                    : {};
                const canPost = permission.createPost || false;

                return (
                  <Paper className={classes.paper}>
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          value="postingPermissions"
                          checked={canPost}
                          onChange={e => {
                            const { checked } = e.target;
                            edit({
                              variables: {
                                groupId: group.id,
                                permissions: [
                                  { name: 'guest', createPost: checked },
                                  { name: 'owner', createPost: checked },
                                ],
                              },
                            });
                          }}
                        />
                      }
                      label={`Do you want members of ${group.name} to be able to compose posts to other members from the app or website? Trainers and managers will always be able to do so. This does not affect a member's ability to reply and respond to messages.`}
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          value="sms"
                          checked={group.sms}
                          onChange={e => {
                            const { checked } = e.target;
                            editGroup({
                              variables: {
                                id,
                                sms: checked,
                              },
                            });
                          }}
                        />
                      }
                      label="Allow SMS notifications for members"
                    />
                  </Paper>
                );
              }}
            </Query>
          )}
        </Mutation>
      )}
    </Mutation>
  );
};

export default withStyles(styles)(GroupPermissions);
