import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { graphql } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import {
  Loader,
  Notification,
  SubmitButton,
  CenteredPaper,
  PaperTitle,
  ConfirmationDialog,
} from 'shared';
import { GET_ALL_USERS, GET_USER, UPDATE_USER } from 'queries';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    // display: "flex",
    width: 225,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
});

class UserNotifications extends Component {
  state = {
    firstName: '',
    surname: '',
    email: '',
    userName: '',
    phoneNumber: '',
    birthDate: '',
    pushNotification: false,
    textNotification: false,
    emailNotification: false,
    isContentManager: false,
    userUpdateNots: false,
    commentNots: false,
    previewNots: false,
    raceNots: false,
    ustRaceResultNots: false,
    ratingNots: false,
    breedingNots: false,
    formAlertNots: false,
    auctionAlertNots: false,
    preraceNots: false,
    ustWorkoutNots: false,
    icsNots: false,
    turfTraxNots: false,
    totalPerformanceDataNots: false,
    address1: '',
    address2: '',
    country: '',
    district: '',
    town: '',
    postCode: '',
    deviceToken: '',
    // marketing: false
  };

  submitHandler = event => {
    const { id } = this.props.match.params;

    event.preventDefault();
    this.props
      .mutate({
        variables: {
          ...this.state,
          id,
          // birthDate: birthDate ? moment(birthDate).format("DD/MM/YYYY") : null
        },
        refetchQueries: [{ query: GET_ALL_USERS }],
      })
      .then(this.props.data.refetch())
      .then(() => this.props.history.push('/users'))
      .catch(res => {
        this.setState({ error: localStorage.getItem('error') });
      })
      .then(setTimeout(() => this.setState({ error: null })), 2000);
  };

  handleSwitch = input => e => {
    this.setState({ [input]: e.target.checked });
  };

  componentDidMount() {
    this.setState(this.props.data.getUserById);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data.getUserById !== this.props.data.getUserById) {
      this.setState(this.props.data.getUserById);
    }
  }

  render() {
    const { classes } = this.props;
    const { loading, error } = this.props.data;
    if (loading) return <Loader />;
    if (error) return <Notification message={error.message} variant="error" />;
    const {
      firstName,
      surname,
      pushNotification,
      textNotification,
      emailNotification,
      userUpdateNots,
      commentNots,
      previewNots,
      raceNots,
      ustRaceResultNots,
      ratingNots,
      breedingNots,
      formAlertNots,
      auctionAlertNots,
      preraceNots,
      ustWorkoutNots,
      icsNots,
      turfTraxNots,
      totalPerformanceDataNots,
    } = this.state;

    return (
      <form onSubmit={this.submitHandler}>
        <CenteredPaper>
          <PaperTitle>{`Edit User Notifications: ${firstName} ${surname}`}</PaperTitle>
          <Grid
            container
            // className={classes.root}
            direction="row"
            justify="space-between"
            alignItems="center"
            spacing={24}
          >
            <Grid item xs={12}>
              <div style={{ marginTop: '30px' }}>Delivery type</div>
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={pushNotification}
                    value="pushNotification"
                    onChange={this.handleSwitch('pushNotification')}
                  />
                }
                label="Push Notification"
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Switch
                    value="textNotification"
                    checked={textNotification}
                    onChange={this.handleSwitch('textNotification')}
                  />
                }
                label="Text Notification"
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Switch
                    value="emailNotification"
                    checked={emailNotification}
                    onChange={this.handleSwitch('emailNotification')}
                  />
                }
                label="Email Notification"
              />
            </Grid>
            <Grid item xs={12}>
              <div style={{ marginTop: '30px' }}>Subject</div>
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Switch
                    value="userUpdateNots"
                    checked={userUpdateNots}
                    onChange={this.handleSwitch('userUpdateNots')}
                  />
                }
                label="Manager, Trainer & Member Updates"
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Switch
                    value="commentNots"
                    checked={commentNots}
                    onChange={this.handleSwitch('commentNots')}
                  />
                }
                label="Comments & Replies"
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Switch
                    value="previewNots"
                    checked={previewNots}
                    onChange={this.handleSwitch('previewNots')}
                  />
                }
                label="Race Previews & Reviews (UK&IRE)"
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Switch
                    value="raceNots"
                    checked={raceNots}
                    onChange={this.handleSwitch('raceNots')}
                  />
                }
                label="Future Races, Race Videos"
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Switch
                    value="ustRaceResultNots"
                    checked={ustRaceResultNots}
                    onChange={this.handleSwitch('ustRaceResultNots')}
                  />
                }
                label="Race Results (US)"
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Switch
                    value="ratingNots"
                    checked={ratingNots}
                    onChange={this.handleSwitch('ratingNots')}
                  />
                }
                label="Rating Changes (UK&IRE)"
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Switch
                    value="breedingNots"
                    checked={breedingNots}
                    onChange={this.handleSwitch('breedingNots')}
                  />
                }
                label="Breeding Alerts"
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Switch
                    value="formAlertNots"
                    checked={formAlertNots}
                    onChange={this.handleSwitch('formAlertNots')}
                  />
                }
                label="Form Alerts (UK&US)"
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Switch
                    value="auctionAlertNots"
                    checked={auctionAlertNots}
                    onChange={this.handleSwitch('auctionAlertNots')}
                  />
                }
                label="Auction Alerts (FR, UK&IRE)"
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Switch
                    value="preraceNots"
                    checked={preraceNots}
                    onChange={this.handleSwitch('preraceNots')}
                  />
                }
                label="Pre-race Notifications"
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Switch
                    value="ustWorkoutNots"
                    checked={ustWorkoutNots}
                    onChange={this.handleSwitch('ustWorkoutNots')}
                  />
                }
                label="Workouts (US)"
              />
            </Grid>
            <Grid item xs={12}>
              <div style={{ marginTop: '30px' }}>Deprecated</div>
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Switch
                    value="icsNots"
                    checked={icsNots}
                    onChange={this.handleSwitch('icsNots')}
                  />
                }
                label="ICS"
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Switch
                    value="turfTraxNots"
                    checked={turfTraxNots}
                    onChange={this.handleSwitch('turfTraxNots')}
                  />
                }
                label="TurfTrax"
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Switch
                    value="totalPerformanceDataNots"
                    checked={totalPerformanceDataNots}
                    onChange={this.handleSwitch('totalPerformanceDataNots')}
                  />
                }
                label="Total Perfomance Data"
              />
            </Grid>
            <Grid item xs={12} sm={9}>
              <SubmitButton>Update User</SubmitButton>
            </Grid>          
          </Grid>
        </CenteredPaper>
        {this.state.error ? <Notification message={this.state.error} variant="error" /> : null}
        {this.state.copied ? <Notification message={this.state.copied} variant="success" /> : null}
      </form>
    );
  }
}

export default graphql(UPDATE_USER)(
  graphql(GET_USER, {
    options: props => {
      return {
        variables: { id: props.match.params.id },
      };
    },
  })(withRouter(withStyles(styles)(UserNotifications))),
);
