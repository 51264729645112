import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { graphql, withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import MaterialTable from 'material-table';
import { CsvBuilder } from 'filefy';
import {
  GET_GROUP_MEMBERS,
  REMOVE_USER,
  DOWNGRADE_USER,
  CANCEL_INVITATION,
  RESEND_INVITATION,
} from 'queries';
import { Notification, TableLoader, CDforList } from 'shared';
import UserGroupHorses from './UserGroupHorses';

class GroupMembers extends Component {
  state = {
    open: false,
    id: null,
    cancelInvitationOpen: false,
    cancelInvitationId: null,
    name: null,
    email: null,
  };

  handleCsvExport = (columns, data) => {
    const rowsArr = [];
    for (let i = 0; i < data.length; i += 1) {
      const user = data[i];
      if (user.horses.length > 0) {
        user.horses.forEach(h => {
          rowsArr.push({
            id: user.id,
            name: user.name,
            email: user.email,
            groupRole: user.groupRole,
            horseRoles: h.role,
            horsesQ: user.horsesQ,
            horseId: h.horse.id,
            horseName: h.horse.name,
            lastLogin: user.lastLogin,
            lastSeenBy: user.lastSeenBy,
          });
        });
      } else {
        rowsArr.push({
          id: user.id,
          name: user.name,
          email: user.email,
          groupRole: user.groupRole,
          horseRoles: null,
          horsesQ: user.horsesQ,
          lastLogin: user.lastLogin,
          lastSeenBy: user.lastSeenBy,
        });
      }
    }
    const csvColumns = columns.map((columnDef) => columnDef.title);
    const csvData = rowsArr.map(rowData => columns.map(columnDef => rowData[columnDef.field]));
    const builder = new CsvBuilder(`Members_${moment().format('YYYY-MM-DDTHHmm')}.csv`);
    builder.setDelimeter(',').setColumns(csvColumns).addRows(csvData).exportFile();
  };

  render() {
    const {
      data: { loading, error, getGroupById },
      history,
    } = this.props;
    const { open, id, cancelInvitationOpen, cancelInvitationId, name, email } = this.state;
    if (loading) return <TableLoader />;
    if (error) return <Notification message={error.message} variant="error" />;

    const { members, invitations } = getGroupById;

    const invitationRows = invitations.map(user => ({
        id: user.id,
        name: `${user.firstName} ${user.surname}`,
        groupRole: 'Pending Invitation',
        email: user.email,
        horses: user.horses,
        horsesQ: user.horses.length,
        lastLogin: 'NEVER',
        lastSeenBy: 'NEVER',
      }),
    );
    
    const memberRows = members.map(trmMember => {
      const horseRoles = trmMember.horses.map(h => h.role);
      const uniqueHorseRoles = Array.from(new Set(horseRoles))
      return {
        id: trmMember.user.id,
        name: `${trmMember.user.firstName} ${trmMember.user.surname}`,
        groupRole: trmMember.role,
        horseRoles: uniqueHorseRoles.join(', '),
        email: trmMember.user.email,
        horses: trmMember.horses,
        horsesQ: trmMember.horses.length,
        lastLogin: trmMember.user.lastLogin
          ? moment(parseInt(trmMember.user.lastLogin, 10)).format('DD/MM/YYYY HH:mm')
          : 'NEVER',
        lastSeenBy: trmMember.user.lastSeenBy
          ? moment(parseInt(trmMember.user.lastSeenBy, 10)).format('DD/MM/YYYY HH:mm')
          : 'NEVER',
      }
    })

    const tableData = [
      ...memberRows,
      ...invitationRows,
    ]

    return (
      <>
        <CDforList
          open={cancelInvitationOpen}
          title={`Are you sure you want to delete the invitation for ${name} ${email}?`}
          mutationQuery={CANCEL_INVITATION}
          options={{
            variables: {
              id: cancelInvitationId,
            },
            refetchQueries: [{ query: GET_GROUP_MEMBERS, variables: { id: this.props.id } }],
          }}
          handleClose={() => {
            this.setState({ cancelInvitationOpen: false });
          }}
        />
        <CDforList
          open={open}
          title="Do you really want to remove this user from group?"
          mutationQuery={REMOVE_USER}
          options={{
            variables: {
              id,
              groupId: this.props.id,
            },
            refetchQueries: [{ query: GET_GROUP_MEMBERS, variables: { id: this.props.id } }],
          }}
          handleClose={() => {
            this.setState({ open: false });
          }}
        />
        <MaterialTable
          actions={[
            {
              icon: 'add_circle',
              tooltip: 'Set Shares',
              onClick: (event, rowData) => {
                this.props.history.push(`members/${rowData.id}/set-shares`);
              },
            },
            rowData =>
              rowData.role === 'Pending Invitation'
                ? {
                    icon: 'refresh',
                    tooltip: 'Resend the invitation',
                    onClick: (event, rowData) => {
                      const { client } = this.props;
                      client.mutate({
                        mutation: RESEND_INVITATION,
                        variables: {
                          id: rowData.id,
                        },
                        refetchQueries: [
                          {
                            query: GET_GROUP_MEMBERS,
                            variables: { id: this.props.id },
                          },
                        ],
                      });
                    },
                  }
                : null,
            rowData =>
              rowData.role === 'Pending Invitation'
                ? {
                    icon: 'cancel',
                    tooltip: 'Cancel the invitation',
                    onClick: (event, rowData) => {
                      this.setState({
                        cancelInvitationId: rowData.id,
                        name: rowData.name,
                        email: rowData.email,
                        cancelInvitationOpen: true,
                      });
                    },
                  }
                : null,

            rowData =>
              rowData.role === 'Pending Invitation'
                ? null
                : {
                    icon: 'account_circle',
                    tooltip: 'Show User',
                    onClick: (event, rowData) => {
                      history.push(`/users/${rowData.id}/view/info`);
                    },
                  },

            rowData =>
              rowData.role === 'Pending Invitation' || rowData.role === 'org-manager'
                ? null
                : {
                    icon: 'delete_forever',
                    tooltip: 'Remove user from the group',
                    onClick: (event, rowData) => {
                      console.log(rowData.id);
                      this.setState({ id: rowData.id, open: true });
                    },
                  },
            rowData =>
              rowData.role === 'Pending Invitation'
                ? null
                : {
                    icon: 'arrow_downward',
                    tooltip: 'Downgrade the role',
                    onClick: (event, rowData) => {
                      const { client } = this.props;
                      client.mutate({
                        mutation: DOWNGRADE_USER,
                        variables: {
                          id: rowData.id,
                          groupId: this.props.id,
                        },
                        refetchQueries: [
                          {
                            query: GET_GROUP_MEMBERS,
                            variables: { id: this.props.id },
                          },
                        ],
                      });
                    },
                  },
            {
              isFreeAction: true,
              icon: 'cloud_upload',
              tooltip: 'Add members',
              onClick: (event, rowData) => {
                history.push('../bulk-upload');
              },
            },
            {
              isFreeAction: true,
              icon: 'add_circle',
              tooltip: 'Add Member to Group',
              onClick: () => {
                history.push('../addMember');
              },
            },
          ]}
          options={{
            columnsButton: true,
            pageSize: 10,
            actionsColumnIndex: -1,
            showEmptyDataSourceMessage: true,
            grouping: true,
            exportButton: true,
            exportAllData: true,
            exportCsv: (col, data) => this.handleCsvExport(col, data),
          }}
          columns={[
            {
              title: 'ID',
              field: 'id',
              defaultSort: 'desc',
              grouping: false,
            },
            {
              title: 'Name',
              field: 'name',
              grouping: false,
            },
            {
              title: 'Email',
              field: 'email',
              grouping: false,
            },
            {
              title: 'Group Role',
              field: 'groupRole',
              cellStyle: data => {
                if (data === 'manager') {
                  return {
                    fontWeight: 'bold',
                    textTransform: 'capitalize',
                  };
                }
                return {
                  textTransform: 'capitalize',
                };
              },
            },
            {
              title: 'Horse Roles',
              field: 'horseRoles',
              cellStyle: () => ({ textTransform: 'capitalize' })
            },
            {
              title: 'Horses',
              field: 'horsesQ',
            },
            {
              title: 'HorseID',
              cellStyle: { display: 'none' },
              headerStyle: { display: 'none' },
              field: 'horseId',
            },
            {
              title: 'HorseName',
              cellStyle: { display: 'none' },
              headerStyle: { display: 'none' },
              field: 'horseName',
            },
            {
              title: 'Last Login',
              field: 'lastLogin',
            },
            {
              title: 'Last Seen By',
              field: 'lastSeenBy',
            },
          ]}
          detailPanel={rowData => {
            return rowData.horses.map((i, index) => {
              return (
                <UserGroupHorses key={index} name={i.horse.name} role={i.role} shares={i.shares} />
              );
            });
          }}
          data={tableData}
          title="Members"
        />
      </>
    );
  }
}

GroupMembers.propTypes = {
  data: PropTypes.object,
  history: PropTypes.object,
};

export default graphql(REMOVE_USER)(
  graphql(GET_GROUP_MEMBERS, {
    options: props => {
      return {
        variables: { id: props.id },
      };
    },
  })(withApollo(withRouter(GroupMembers))),
);
