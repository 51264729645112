import React from "react";
import { UserCost } from "./UserCost";
import { Table } from "../Utils/Table";

export const UsersTab = ({
  annualCostUser,
  setAnnualCostUsers,
  agreedNumberUser,
  setAgreedNumberUser,
  tableData,
  totalUserSubs,
  avgUserSubsPerDay,
  userDifference,
  userDifferenceAmount,
  totalUsers,
  agreedUserCost
}) => {
  const tableHeadings = [
    { title: "", field: "rowId", width: "4%" },
    { title: "Horse ID ", field: "horseId" },
    { title: "Horse Name", field: "horseName" },
    { title: "User Name", field: "userName" },
    { title: "User Email", field: "email" },
    { title: "Date horse added to user", field: "createdAt" },
    { title: "Date horse removed from user", field: "deletedAt" },
    { title: "No of days", field: "daysInPeriod" },
    { title: "No of days %", field: "daysPercentage" }
  ];

  const data = tableData.map((row, index) => ({
    ...row,
    rowId: index + 1,
    daysPercentage: `${row.percentageInPeriod} %`,
    horseId: row.horseId ?? "-",
    horseName: row.horseName ?? "-",
    createdAt: row.createdAt ?? "-",
    deletedAt: row.deletedAt ?? "-"
  }));

  return (
    <>
      <UserCost
        annualCostUser={annualCostUser}
        setAnnualCostUsers={setAnnualCostUsers}
        agreedNumberUser={agreedNumberUser}
        setAgreedNumberUser={setAgreedNumberUser}
        totalUserSubs={totalUserSubs}
        avgUserSubsPerDay={avgUserSubsPerDay}
        userDifference={userDifference}
        userDifferenceAmount={userDifferenceAmount}
        totalUsers={totalUsers}
        agreedUserCost={agreedUserCost}
      />
      <Table tableHeadings={tableHeadings} tableData={data} />
    </>
  );
};
