import React from "react";
import { Table } from "../Utils/Table";

export const HistoricHorsesTab = ({ tableData }) => {
  const tableHeadings = [
    { title: "", field: "rowId", width: "4%" },
    { title: "Horse Name", field: "name" },
    { title: "User", field: "userEmail" },
    { title: "Action", field: "action" },
    { title: "Date", field: "createdAt" }
  ];

  const data = tableData.map((row, index) => ({
    ...row,
    rowId: index + 1
  }));

  return (
    <Table tableHeadings={tableHeadings} tableData={data} />
  );
};
