import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { graphql, Mutation } from 'react-apollo';
import axios from 'axios';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import {
  UPDATE_ORGANIZATION,
  GET_ORGANIZATION,
  GET_ALL_ORGANIZATIONS,
  DELETE_ORGANIZATION,
} from 'queries';
import {
  Loader,
  Notification,
  SubmitButton,
  CenteredPaper,
  PaperTitle,
  ConfirmationDialog,
} from 'shared';
import queryString from 'query-string';


registerPlugin(FilePondPluginImagePreview);
const token = localStorage.getItem('token');

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    // display: "flex",
    width: 225,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  skipButton: {
    display: 'flex',
    margin: '10px auto',
    color: '#fff',
    width: '100%',
    maxWidth: '300px',
    background: '#f18a9b',
    borderColor: '#f18a9b',
    '&:hover': {
      background: '#fff',
      color: '#142339',
      borderColor: '#142339',
    },
  },
});

class Edit extends Component {
  state = {
    name: '',
    sms: false,
  };

  componentDidMount() {
    const { data = {} } = this.props;
    const { getOrganizationById = {} } = data;
    const { name = '', sms = false } = getOrganizationById;
    this.setState({ name, sms });
  }

  componentDidUpdate(prevProps) {
    const { data } = this.props;
    if (data.getOrganizationById !== prevProps.data.getOrganizationById) {
      this.setState({ name: data.getOrganizationById.name, sms: data.getOrganizationById.sms });
    }
  }

  submitHandler = (event, mutate) => {
    const { match, data, history, location } = this.props;
    const { id } = match.params;
    const { name, files } = this.state;
    event.preventDefault();
    if (files && files.length > 0) {
      const formData = new FormData();
      files.map((file, index) => formData.append(index, file));
      this.setState({ sending: true });
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}/upload`,
        headers: {
          Authorization: `JWT ${token}`,
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      }).then(response => {
        mutate({
          variables: {
            ...this.state,
            logoImage: response.data[0],
            id,
          },
          refetchQueries: [{ query: GET_ALL_ORGANIZATIONS }],
        })
          .then(data.refetch())
          .then(() => {
            const parsedLocation = queryString.parse(location.search);
            if (parsedLocation.flow) {
              history.push(`/organizations/${id}/addManager?flow=true`);
            } else {
              history.push(`/organizations/`);
            }
          })
          .catch(() => {
            this.setState({ error: localStorage.getItem('error') });
          })
          .then(setTimeout(() => this.setState({ error: null })), 2000);
      });
    } else {
      mutate({ variables: { id, name }, refetchQueries: [{ query: GET_ALL_ORGANIZATIONS }] })
        .then(data.refetch())
        .then(() => {
          const parsedLocation = queryString.parse(location.search);
          if (parsedLocation.flow) {
            history.push(`/organizations/${id}/addManager?flow=true`);
          } else {
            history.push(`/organizations/`);
          }
        })
        .catch(() => {
          this.setState({ error: localStorage.getItem('error') });
        })
        .then(setTimeout(() => this.setState({ error: null })), 2000);
    }
  };

  handleChange = input => e => {
    this.setState({ [input]: e.target.value });
  };

  handleSwitch = e => {
    this.setState({ sms: e.target.checked });
  };

  render() {
    const { data, location, classes } = this.props;
    const { loading, error, getOrganizationById } = data;
    if (loading) return <Loader />;
    if (error) return <Notification message={error.message} variant="error" />;
    const { name, files, error: stateError, copied } = this.state;
    const parsedLocation = queryString.parse(location.search);
    return (
      <Mutation mutation={UPDATE_ORGANIZATION}>
        {mutate => (
          <form onSubmit={e => this.submitHandler(e, mutate)}>
            <CenteredPaper>
              <PaperTitle>{`Edit Organization: ${getOrganizationById.name}`}</PaperTitle>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                spacing={24}
              >
                <Grid item xs={12}>
                  <TextField
                    required
                    label="Name"
                    value={name}
                    onChange={this.handleChange('name')}
                    // className={classes.textField}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FilePond
                    instantUpload={false}
                    allowMultiple={false}
                    files={files}
                    labelIdle={
                      'Drag & Drop your logo or <span class="filepond--label-action"> Browse </span>'
                    }
                    onupdatefiles={fileItems => {
                      this.setState({
                        files: fileItems.map(fileItem => fileItem.file),
                      });
                    }}
                  />
                </Grid>
                {/* <Grid item xs={12}> */}
                {/* <FormControlLabel */}
                {/* control={ */}
                {/* <Switch */}
                {/* value="sms" */}
                {/* checked={sms} */}
                {/* onChange={this.handleSwitch} */}
                {/* /> */}
                {/* } */}
                {/* label="Enable sms notifications" */}
                {/* /> */}
                {/* </Grid> */}
                <Grid item xs={12} sm={9}>
                  <SubmitButton>Update</SubmitButton>
                  {parsedLocation.flow ? (
                    <Button
                      variant="outlined"
                      color="primary"
                      className={classes.button}
                      onClick={() => this.props.history.push(`/organizations/${getOrganizationById.id}/addManager?flow=true`)}
                    >
                      Continue without logo
                    </Button>
                  ) : null}
                </Grid>
                {parsedLocation.flow ? null : (
                  <Grid item xs={12} sm={3}>
                    <ConfirmationDialog
                      title="Do you really want to delete this organization?"
                      mutationQuery={DELETE_ORGANIZATION}
                      refetchQ={GET_ALL_ORGANIZATIONS}
                      id={getOrganizationById.id}
                      redirectPath="/organizations"
                    />
                  </Grid>
                )}
              </Grid>
            </CenteredPaper>
            {stateError ? <Notification message={stateError} variant="error" /> : null}
            {copied ? <Notification message={copied} variant="success" /> : null}
          </form>
        )}
      </Mutation>
    );
  }
}

Edit.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  data: PropTypes.object,
};

export const OrganizationEdit = graphql(GET_ORGANIZATION, {
  options: props => {
    return {
      variables: { id: props.match.params.id },
    };
  },
})(withRouter(withStyles(styles)(Edit)));
