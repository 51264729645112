import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { withRouter, Link } from 'react-router-dom';
import { Notification } from 'shared';
import { calculateHorseAge } from 'shared';

const styles = theme => ({
  info: {
    zIndex: -3,
    marginTop: 20,
    marginBottom: 20,
    paddingBottom: 10,
    margin: 35,
    fontSize: '16px',
  },
});

function HorseInfo(props) {
  const { id, isSouthernHemisphere, foalingDate, timeformHorse, fgHorse, ustHorse, trainerName, ownerName, linkedAt, linkedBy, createdAt, createdBy, location } = props.data;

  const serviceHorse = timeformHorse || fgHorse || ustHorse;
  
  if (!serviceHorse)
  return (
    <Notification
      message="This menu is only available for linked horses"
      variant="error"
    />
  );

  const horseCode = serviceHorse.horseCode || serviceHorse.fgId || serviceHorse.ustId;
  const horseAge = calculateHorseAge(foalingDate, isSouthernHemisphere);
  const wins = serviceHorse.wins;
  const places = serviceHorse.places;
  const damName = serviceHorse.damName;
  const sireName = serviceHorse.sireName;
  const damSireName = serviceHorse.damSireName;
  const ownerFullName = serviceHorse.ownerFullName;
  const trainerFullName = serviceHorse.trainerFullName;
  const productionCommentFlat = serviceHorse.productionCommentFlat;
  const productionCommentJump = serviceHorse.productionCommentJump;
  const performanceCount = serviceHorse.performanceCount;

  const { classes } = props;
  return (
    <div className={classes.info}>
      <Typography variant="body2" gutterBottom>
        ID: {id}
      </Typography>
      <hr />
      <Typography variant="body2" gutterBottom>
        External ID: {horseCode}
      </Typography>
      <hr />
      <Typography variant="body2" gutterBottom>
        Age: {horseAge}
      </Typography>
      <hr />
      <Typography variant="body2" gutterBottom>
        Dam: {damName}
      </Typography>
      <hr />
      <Typography variant="body2" gutterBottom>
        Sire: {sireName}
      </Typography>
      <hr />
      <Typography variant="body2" gutterBottom>
        Dam Sire: {damSireName}
      </Typography>
      <hr />
      <Typography variant="body2" gutterBottom>
        Owner: {ownerName ? <b>{ownerName}</b> : ownerFullName}
      </Typography>
      <hr />
      <Typography variant="body2" gutterBottom>
        Location: {location ? location : location}
      </Typography>
      <hr />
      <Typography variant="body2" gutterBottom>
        Trainer: {trainerName ? <b>{trainerName}</b> : trainerFullName}
      </Typography>
      <hr />
      <Typography variant="body2" gutterBottom>
        Flat comment: {productionCommentFlat}
      </Typography>
      <hr />
      <Typography variant="body2" gutterBottom>
        Jump comment: {productionCommentJump}
      </Typography>
      <hr />
      <Typography variant="body2" gutterBottom>
        Wins: {wins}
      </Typography>
      <hr />
      <Typography variant="body2" gutterBottom>
        Places: {places}
      </Typography>
      <hr />
      <Typography variant="body2" gutterBottom>
        Performance: {performanceCount}
      </Typography>
      <hr />
      <Typography variant="body2" gutterBottom>
        Linked at: {linkedAt || '-'}
      </Typography>
      <hr />
      <Typography variant="body2" gutterBottom>
        Linked by:{' '}
        {linkedBy ? (
          <Link to={`/users/${linkedBy.id}/view/info`}>
            {linkedBy.userName} ({linkedBy.email})
          </Link>
        ) : (
          '-'
        )}
      </Typography>
      <hr />
      <Typography variant="body2" gutterBottom>
        Created at: {createdAt || '-'}
      </Typography>
      <hr />
      <Typography variant="body2" gutterBottom>
        Created by:{' '}
        {createdBy ? (
          <Link to={`/users/${createdBy.id}/view/info`}>
            {createdBy.userName} ({createdBy.email})
          </Link>
        ) : (
          '-'
        )}
      </Typography>
    </div>
  );
}

export default withRouter(withStyles(styles)(HorseInfo));
