import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { graphql } from 'react-apollo';
import queryString from 'query-string';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { SubmitButton, CenteredPaper, PaperTitle, Notification, Loader, Autosuggest } from 'shared';
import {
  GET_ALL_GROUPS,
  CREATE_GROUP,
  GET_ORGANIZATIONS_SELECTOR,
  GET_ALL_ORGANIZATIONS,
} from 'queries';
import styles from './styles';

class GroupCreate extends Component {
  state = {
    name: '',
    bhaApproved: false,
    existing: false,
    type: '',
    period: '',
    address1: '',
    address2: '',
    country: '',
    district: '',
    town: '',
    postCode: '',
    membershipPayedByUser: false,
    maxOwners: '',
    owners: '',
    managers: '',
    trainers: '',
    guests: '',
    trial: false,
  };

  submitHander = toHorses => {
    this.props
      .mutate({
        variables: {
          ...this.state,
          maxOwners: parseInt(this.state.maxOwners),
          owners: parseInt(this.state.owners),
          managers: parseInt(this.state.managers),
          trainers: parseInt(this.state.trainers),
          guests: parseInt(this.state.guests),
        },
        refetchQueries: [{ query: GET_ALL_GROUPS }, { query: GET_ALL_ORGANIZATIONS }],
      })
      .then((res) => {
        if (toHorses) {
          this.props.history.push(`/groups/${res.data.createGroup.id}/addHorse?flow=true`);
        } else {
          this.props.history.push('/groups');
        }
      })
      .catch(res => {
        this.setState({ error: localStorage.getItem('error') });
      })
      .then(setTimeout(() => this.setState({ error: null })), 2000);
  };

  handleChange = input => e => {
    this.setState({ [input]: e.target.value });
  };

  handleSwitch = input => e => {
    this.setState({ [input]: e.target.checked });
  };

  handleChangeAutosuggest = input => e => {
    this.setState({ [input]: e ? e.value : '' });
  };

  render() {
    const {
      error,
      name,
      organizationId,
      bhaApproved,
      existing,
      type,
      period,
      address1,
      address2,
      country,
      district,
      town,
      postCode,
      membershipPayedByUser,
      maxOwners,
      owners,
      managers,
      trainers,
      guests,
      trial,
    } = this.state;
    const { classes, location } = this.props;
    const { loading, error: gqlError, getAllOrganizations } = this.props.data;
    if (loading) return <Loader />;
    if (gqlError) return <Notification message={error.message} variant="error" />;
    const organizationOptions = getAllOrganizations.map(item => ({
      value: item.id,
      label: item.name,
    }));
    const parsedLocation = queryString.parse(location.search);
    const isFlow = !!parsedLocation.flow;
    return (
      <CenteredPaper>
        <PaperTitle>Create Group</PaperTitle>
        <form>
          <Grid container className={classes.root} spacing={24}>
            <Grid item xs={12} sm={12}>
              <Autosuggest
                options={organizationOptions}
                handleChange={this.handleChangeAutosuggest('organizationId')}
                placeholder="Search an organization...*"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                id="standard-select-period"
                select
                label="Period"
                value={period}
                className={classes.textField}
                onChange={this.handleChange('period')}
                margin="none"
              >
                <MenuItem value="Open Ended">Open Ended</MenuItem>
                <MenuItem value="Fixed Period">Fixed Period</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="standard-select-type"
                select
                label="Type"
                value={type}
                className={classes.textField}
                onChange={this.handleChange('type')}
                margin="none"
              >
                <MenuItem value="Syndicate">Syndicate</MenuItem>
                <MenuItem value="Partnership">Partnership</MenuItem>
                <MenuItem value="Sole owner">Sole owner</MenuItem>
                <MenuItem value="Company">Company</MenuItem>
                <MenuItem value="Racing club">Racing club</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                label="Name"
                value={name}
                onChange={this.handleChange('name')}
                className={classes.textField}
                margin="none"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                label="Address 1"
                value={address1}
                onChange={this.handleChange('address1')}
                className={classes.textField}
                margin="none"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                label="Address 2"
                value={address2}
                onChange={this.handleChange('address2')}
                className={classes.textField}
                margin="none"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                label="Country"
                value={country}
                onChange={this.handleChange('country')}
                className={classes.textField}
                margin="none"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                label="District"
                value={district}
                onChange={this.handleChange('district')}
                className={classes.textField}
                margin="none"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                label="Town"
                value={town}
                onChange={this.handleChange('town')}
                className={classes.textField}
                margin="none"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                label="Post Code"
                value={postCode}
                onChange={this.handleChange('postCode')}
                className={classes.textField}
                margin="none"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                required
                type="number"
                label="Max Owners"
                value={maxOwners}
                onChange={this.handleChange('maxOwners')}
                className={classes.textField}
                margin="none"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                type="number"
                label="Owners"
                value={owners}
                onChange={this.handleChange('owners')}
                className={classes.textField}
                margin="none"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                type="number"
                label="Managers"
                value={managers}
                onChange={this.handleChange('managers')}
                className={classes.textField}
                margin="none"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                type="number"
                label="Trainers"
                value={trainers}
                onChange={this.handleChange('trainers')}
                className={classes.textField}
                margin="none"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                type="number"
                label="Guests"
                value={guests}
                onChange={this.handleChange('guests')}
                className={classes.textField}
                margin="none"
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Grid container direction="row" justify="space-around" alignItems="center">
                <FormControlLabel
                  control={
                    <Switch
                      checked={bhaApproved}
                      value="bhaApproved"
                      onChange={this.handleSwitch('bhaApproved')}
                    />
                  }
                  label="Approved"
                />
                <FormControlLabel
                  control={
                    <Switch
                      value="existing"
                      checked={existing}
                      onChange={this.handleSwitch('existing')}
                    />
                  }
                  label="Existing"
                />
                <FormControlLabel
                  control={
                    <Switch value="trial" checked={trial} onChange={this.handleSwitch('trial')} />
                  }
                  label="Trial"
                />
                <FormControlLabel
                  control={
                    <Switch
                      value="membershipPayedByUser"
                      checked={membershipPayedByUser}
                      onChange={this.handleSwitch('membershipPayedByUser')}
                    />
                  }
                  label="Membership Paid By User"
                />
              </Grid>
            </Grid>
          </Grid>
          {
            isFlow ? (
              <div className={classes.buttonsContainer}>
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.button}
                  onClick={() => this.submitHander(false)}
                  disabled={!organizationId}
                >Save and return to admin panel</Button>
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.button}
                  onClick={() => this.submitHander(true)}
                  disabled={!organizationId}
                >Continue and add horses</Button>
              </div> ) : (
                <Button 
                  variant="outlined"
                  color="primary"
                  className={classes.button}
                  onClick={() => this.submitHander(false)}
                  disabled={!organizationId}
                >Create Group</Button>
            )
          }
          {error ? <Notification message={error} variant="error" /> : null}
        </form>
      </CenteredPaper>
    );
  }
}

export default graphql(CREATE_GROUP)(
  graphql(GET_ORGANIZATIONS_SELECTOR)(withRouter(withStyles(styles)(GroupCreate))),
);
