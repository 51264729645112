import MaterialTable from 'material-table';

import { GET_USER_GROUPS, REMOVE_USER } from 'queries';
import React from 'react';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { Notification, TableLoader } from 'shared';

function UserGroupsList(props) {
  const { loading, error, getUserById } = props.data;
  if (loading) return <TableLoader />;
  if (error) return <Notification message={error.message} variant="error" />;

  const data = getUserById.ownerships.map(g => ({
    id: g.group.id,
    name: g.group.name,
    type: g.group.type,
    role: g.role,
  }));

  return (
    <MaterialTable
      actions={[
        {
          icon: 'group_circle',
          tooltip: 'User Group',
          onClick: (event, rowData) => {
            props.history.push(`/groups/${rowData.id}/view/info`);
          },
        },
      ]}
      options={{
        columnsButton: true,
        pageSize: 10,
        actionsColumnIndex: -1,
        showEmptyDataSourceMessage: true,
        exportButton: true,
        exportAllData: true,
        pageSizeOptions: [5, 10, data.length],
      }}
      columns={[
        { title: 'ID', field: 'id' },
        { title: 'Name', field: 'name', defaultSort: 'asc' },
        { title: 'Type', field: 'type' },
        {
          title: 'Role',
          field: 'role',
          cellStyle: data => {
            if (data === 'manager') {
              return {
                fontWeight: 'bold',
                textTransform: 'capitalize',
              };
            }
            return {
              textTransform: 'capitalize',
            };
          },
        },
      ]}
      data={data}
      title="Groups"
    />
  );
}

export default graphql(REMOVE_USER)(
  graphql(GET_USER_GROUPS, {
    options: props => {
      return {
        variables: { id: props.id },
      };
    },
  })(withRouter(UserGroupsList)),
);
