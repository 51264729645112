import React, { Component } from 'react';
import { GET_GROUP } from 'queries';

import { withRouter, Link, Route } from 'react-router-dom';
import { graphql } from 'react-apollo';

import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';

import { Loader, Notification } from 'shared';

import { styles } from 'shared/ViewStyles';

import GroupInfo from './GroupInfo';
import GroupPermissions from './GroupPermissions';
import SetShares from './SetShares';
import GroupHorses from './GroupHorses';
import GroupMembers from './GroupMembers';
import GroupUpdates from './GroupUpdates';
import GroupNotes from './GroupNotes';


class GroupView extends Component {
  state = {
    value: '',
  };

  componentDidMount() {
    this.setState({
      value: this.props.history.location.pathname,
    });
  }

  componentDidUpdate() {
    if (this.state.value !== this.props.history.location.pathname)
      this.setState({
        value: this.props.history.location.pathname,
      });
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  editHandler = () => {
    this.props.history.push(`groups/${this.props.data.getGroupById.id}/view`);
  };

  render() {
    const { classes } = this.props;
    const { value } = this.state;
    const { loading, error, getGroupById } = this.props.data;

    if (loading) return <Loader />;

    if (error) return <Notification message={error.message} variant="error" />;

    const { id, name, updates } = getGroupById;
    const size = updates.size * updates.totalPages;
    return (
      <form onSubmit={this.submitHandler} className={classes.root}>
        <Paper className={classes.paper}>
          <Tabs
            value={value}
            onChange={this.handleChange}
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
            centered
          >
            <Tab
              tooltip="Remove Horse from Group"
              label={`${name}`}
              disableRipple
              component={Link}
              to="../edit"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            <Tab
              label="Info"
              disableRipple
              component={Link}
              to={`/groups/${id}/view/info`}
              value={`/groups/${id}/view/info`}
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            <Tab
              label="Horses"
              disableRipple
              component={Link}
              to={`/groups/${id}/view/horses`}
              value={`/groups/${id}/view/horses`}
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            <Tab
              label="Members"
              disableRipple
              component={Link}
              to={`/groups/${id}/view/members`}
              value={`/groups/${id}/view/members`}
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            <Tab
              label="Updates"
              disableRipple
              component={Link}
              to={`/groups/${id}/view/updates`}
              value={`/groups/${id}/view/updates`}
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            <Tab
              label="Permissions"
              disableRipple
              component={Link}
              to={`/groups/${id}/view/permissions`}
              value={`/groups/${id}/view/permissions`}
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            <Tab
              label="Notes"
              disableRipple
              component={Link}
              to={`/groups/${id}/view/notes`}
              value={`/groups/${id}/view/notes`}
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
          </Tabs>

          <Route
            exact
            path="/groups/:id/view/info"
            render={() => <GroupInfo data={getGroupById} />}
          />
          <Route
            exact
            path="/groups/:id/view/horses"
            render={() => (
              <div className={classes.table}>
                <GroupHorses id={id} />
              </div>
            )}
          />
          <Route
            exact
            path="/groups/:id/view/members"
            render={() => (
              <div className={classes.table}>
                <GroupMembers id={id} />
              </div>
            )}
          />
          <Route
            exact
            path="/groups/:id/view/updates"
            render={() => (
              <div className={classes.table}>
                <GroupUpdates id={id} size={size} />
              </div>
            )}
          />
          <Route
            exact
            path="/groups/:id/view/permissions"
            render={() => (
              <div className={classes.table}>
                <GroupPermissions id={id} />
              </div>
            )}
          />
          <Route
            exact
            path="/groups/:id/view/members/:userId/set-shares"
            render={() => (
              <div className={classes.table}>
                <SetShares id={id} />
              </div>
            )}
          />
          <Route
            exact
            path="/groups/:id/view/notes"
            render={() => (
              <div className={classes.table}>
                <GroupNotes id={id} />
              </div>
            )}
          />
        </Paper>
      </form>
    );
  }
}

export default graphql(GET_GROUP, {
  options: props => {
    const { id } = props.match.params;
    return {
      variables: { id },
    };
  },
})(withRouter(withStyles(styles)(GroupView)));
