import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { graphql } from 'react-apollo';
import TextField from '@material-ui/core/TextField';
import { GET_ALL_ORGANIZATIONS, CREATE_ORGANIZATION } from 'queries';
import { Notification, SubmitButton, CenteredPaper, PaperTitle } from 'shared';
import queryString from 'query-string';

class Create extends Component {
  state = {
    name: '',
  };

  submitHander = event => {
    event.preventDefault();
    this.props
      .mutate({
        variables: {
          ...this.state,
        },
        refetchQueries: [{ query: GET_ALL_ORGANIZATIONS }],
      })
      .then((res) => {
        const parsedLocation = queryString.parse(this.props.location.search);
        parsedLocation.flow ? 
          this.props.history.push(`/organizations/${res.data.createOrganization.id}/edit?flow=true`) 
          : this.props.history.push(`/organizations/`);
        
      })
      .catch(res => {
        this.setState({ error: localStorage.getItem('error') });
      })
      .then(setTimeout(() => this.setState({ error: null })), 2000);
  };

  handleChange = input => e => {
    this.setState({ [input]: e.target.value });
  };

  render() {
    const { error, name } = this.state;
    return (
      <>
        <CenteredPaper>
          <PaperTitle>Create Organization</PaperTitle>
          <form onSubmit={this.submitHander}>
            <TextField
              required
              label="Name"
              value={name}
              onChange={this.handleChange('name')}
              // className={classes.textField}
              fullWidth
              margin="normal"
            />
            <SubmitButton>Create</SubmitButton>
          </form>
        </CenteredPaper>
        {error ? <Notification message={error} variant="error" /> : null}
      </>
    );
  }
}

export const OrganizationCreate = graphql(CREATE_ORGANIZATION)(withRouter(Create));
