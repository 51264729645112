import React from 'react';
import { Grid } from '@material-ui/core';
import ReactAudioPlayer from 'react-audio-player';
import { Message } from 'shared/Message';

function Audio(props) {
  const { attachments, message } = props;
  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      style={{ padding: 16 }}
      spacing={16}
    >
      <Grid item xs={6}>
        <Message message={message} />
      </Grid>
      <Grid item xs={5}>
        <ReactAudioPlayer
          src={attachments[0]}
          controls
          style={{
            border: '1px solid black',
            borderRadius: '100px',
            width: '100%',
          }}
        />
      </Grid>
    </Grid>
  );
}

export const DetailsAudio = Audio;
