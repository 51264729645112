import { Typography } from '@material-ui/core';
import MaterialTable from 'material-table';

import { GET_HORSE_TIMEFORM_RESULT_DETAILS } from 'queries';
import React from 'react';
import { Query } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { Notification, TableLoader } from 'shared';

function HorseResultDetails(props) {
  const { id, course, date, race } = props.match.params;
  return (
    <Query
      query={GET_HORSE_TIMEFORM_RESULT_DETAILS}
      variables={{
        id,
        courseId: parseInt(course),
        meetingDate: date,
        raceNumber: parseInt(race),
      }}
    >
      {({ loading, error, data }) => {
        if (loading) return <TableLoader />;
        if (error) return <Notification message={error.message} variant="error" />;
        const { horses, video } = data.getHorseById.resultDetails;
        const hasVideo = video;
        return (
          <MaterialTable
            options={{
              columnsButton: true,
              pageSize: 10,
              actionsColumnIndex: -1,
              showEmptyDataSourceMessage: true,
              exportButton: true,
            }}
            actions={[
              hasVideo
                ? {
                    isFreeAction: true,
                    icon: 'info',
                    tooltip: 'Video is available',
                    // onClick: () => {
                    //   this.props.history.push("../addHorse");
                    // }
                  }
                : {
                    isFreeAction: true,
                    icon: 'info',
                    tooltip: 'Video is not available',
                    // onClick: () => {
                    //   this.props.history.push("../addHorse");
                    // }
                  },
            ]}
            columns={[
              {
                title: 'Position',
                field: 'position',
                defaultSort: 'asc',
              },
              {
                title: 'Horse name',
                field: 'horseName',
                cellStyle: data => {
                  if (data === props.name) {
                    return {
                      color: 'green',
                      fontWeight: 'bold',
                    };
                  }
                },
              },
              {
                title: 'Beaten',
                field: 'btn',
              },
              {
                title: 'Jockey Name',
                field: 'jockeyName',
              },
              {
                title: 'Trainer Name',
                field: 'trainerName',
              },
              {
                title: 'Rating',
                field: 'tfr',
              },
              {
                title: 'Age',
                field: 'horseAge',
              },
              {
                title: 'Starting Price',
                field: 'sp',
              },
              {
                title: 'Weight carried',
                field: 'wgt',
              },
              {
                title: 'Silk',
                render: rowData => (
                  <img
                    alt={`${rowData.id}`}
                    src={`https://images.timeform.com/silks/${rowData.silkCode}.png`}
                    // styles={{ margin: 10 }}
                  />
                ),
              },
            ]}
            detailPanel={rowData => {
              return (
                <Typography variant="subtitle2" style={{ margin: '16px', width: 800 }}>
                  <b>TimeForm Verdict:</b> <br /> {rowData.timeformVerdict}
                </Typography>
              );
            }}
            data={horses}
            title="Race Result Details"
          />
        );
      }}
    </Query>
  );
}

export default withRouter(HorseResultDetails);
