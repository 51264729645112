/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { graphql, withApollo } from 'react-apollo';
import queryString from 'query-string';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import { Grid, Typography } from '@material-ui/core';
import { Loader, CenteredPaper, PaperTitle, Notification, Autosuggest } from 'shared';
import { countries, currencies, services } from 'shared/data';
import DateFormattedInput from 'shared/FormattedInput';
import {
  ADD_HORSE,
  GET_GROUP_HORSES,
  SEARCH_HORSE,
  SEARCH_FG_HORSE,
  SEARCH_FG_INPUT_HORSE,
  SEARCH_UST_HORSE,
  SEARCH_UST_INPUT_HORSE,
} from 'queries';
import { getCountryFromName, parseUSTHorseColors, parseUSTHorseGender } from 'shared/utils';
import Selector from 'shared/Selector';
import styles from './styles';

class AddHorse extends Component {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    searchHorse: '',
    horseOptions: [],
    searching: false,
    adding: false,

    name: '',
    gender: '',
    colour: '',
    secondColour: '',
    foalingDate: '',
    timeformId: '',
    fgId: '',
    ustId: '',
    damName: '',
    damSireName: '',
    ownerFullName: '',
    sireName: '',
    trainerFullName: '',
    stud: '',
    location: '',
    saleLocation: '',
    purchasePrice: '',
    purchaseCurrency: '',
    isSouthernHemisphere: false,

    hc: '',
    sc: '',
    dc: '',
    dsc: '',

    service: null,
  };

  submitHander = toMembers => {
    const {
      match: {
        params: { id },
      },
      mutate,
      history,
    } = this.props;
    const {
      name,
      gender,
      colour,
      secondColour,
      foalingDate,
      service,
      timeformId,
      fgId,
      ustId,
      damName,
      damSireName,
      ownerFullName,
      sireName,
      trainerFullName,
      stud,
      location,
      saleLocation,
      purchasePrice,
      purchaseCurrency,
      isSouthernHemisphere
    } = this.state;
    this.setState({ adding: true })
    mutate({
      variables: {
        name,
        gender,
        colour,
        secondColour,
        service,
        timeformId,
        fgId,
        ustId,
        damName,
        damSireName,
        ownerFullName,
        sireName,
        trainerFullName,
        stud,
        location,
        id,
        foalingDate,
        saleLocation,
        purchasePrice: parseInt(purchasePrice, 10),
        purchaseCurrency: purchaseCurrency || null,
        isSouthernHemisphere,
      },
      refetchQueries: [{ query: GET_GROUP_HORSES, variables: { id } }],
    })
      .then(res => {
        this.setState({ adding: false })
        if (toMembers) {
          history.push(`/groups/${id}/bulk-upload?flow=true`);
        } else {
          history.push(`/groups/${id}/view/horses`);
        }
      })
      .catch(res => {
        this.setState({
          adding: false,
          error: localStorage.getItem('error'),
        });
      })
      .then(setTimeout(() => this.setState({ error: null })), 2000);
  };

  submitAndReset = () => {
    const {
      match: {
        params: { id },
      },
      mutate,
    } = this.props;
    const {
      name,
      gender,
      colour,
      secondColour,
      foalingDate,
      timeformId,
      damName,
      damSireName,
      ownerFullName,
      sireName,
      trainerFullName,
      stud,
      location,
      saleLocation,
      purchasePrice,
      purchaseCurrency,
      service,
      isSouthernHemisphere
    } = this.state;
    // const { foalingDate } = this.state;
    mutate({
      variables: {
        name,
        gender,
        colour,
        secondColour,
        timeformId,
        damName,
        damSireName,
        ownerFullName,
        sireName,
        trainerFullName,
        stud,
        location,
        id,
        foalingDate,
        saleLocation,
        purchasePrice: parseInt(purchasePrice, 10),
        purchaseCurrency: purchaseCurrency || null,
        service,
        isSouthernHemisphere
      },
      refetchQueries: [{ query: GET_GROUP_HORSES, variables: { id } }],
    })
      .then(() =>
        this.setState({
          searchHorse: '',
          horseOptions: [],
          searching: false,

          name: '',
          gender: '',
          colour: '',
          secondColour: '',
          foalingDate: '',
          timeformId: '',
          fgId: '',
          ustId: '',
          damName: '',
          damSireName: '',
          ownerFullName: '',
          sireName: '',
          trainerFullName: '',
          stud: '',
          location: '',
          saleLocation: '',
          purchasePrice: '',
          purchaseCurrency: '',
          isSouthernHemisphere: false,

          hc: '',
          sc: '',
          dc: '',
          dsc: '',
        }),
      )
      .catch(res => {
        this.setState({
          error: localStorage.getItem('error'),
        });
      })
      .then(setTimeout(() => this.setState({ error: null })), 2000);
  };

  handleAutosuggestSearch = e => {
    const { client } = this.props;
    client
      .query({
        query: SEARCH_HORSE,
        variables: { name: e },
      })
      .then(res => {
        const horseOptions = res.data.searchHorse.map(horse => ({
          ...horse,
          value: horse.timeformId,
          label: horse.name,
          colour: horse.horseColour,
          gender: horse.horseGender,
        }));
        this.setState({ horseOptions });
      });
  };

  handleAutosuggestSearchFG = e => {
    const { client } = this.props;
    client
      .query({
        query: SEARCH_FG_INPUT_HORSE,
        variables: { name: e },
      })
      .then(res => {
        const horseOptions = res.data.searchFGInputHorse.map(horse => ({
          ...horse,
          value: horse.fgId,
          label: `${horse.name} ${horse.origin} ${horse.birthYear}`,
        }));
        this.setState({ horseOptions });
      });
  };

  handleAutosuggestSearchUST = e => {
    const { client } = this.props;
    client
      .query({
        query: SEARCH_UST_INPUT_HORSE,
        variables: { name: e },
      })
      .then(res => {
        const horseOptions = res.data.searchUSTInputHorse.map(horse => ({
          ...horse,
          value: horse.ustId,
          label: `${horse.name} ${moment(horse.foalingDate).year()}`,
        }));
        this.setState({ horseOptions });
      });
  };


  handleChangeAutosuggest = input => e => {
    if (e) {
      const hc = getCountryFromName(e.name);
      const dc = getCountryFromName(e.damName);
      const sc = getCountryFromName(e.sireName);
      const dsc = getCountryFromName(e.damSireName);
      const colours = e.colour.split(' or ');
      this.setState({
        [input]: e.value,
        name: e.name,
        hc,
        damName: e.damName,
        dc,
        damSireName: e.damSireName,
        dsc,
        sireName: e.sireName,
        sc,
        gender: e.gender.trim(' '),
        colour: colours[0].trim(' '),
        secondColour: colours[1] ? colours[1].trim(' ') : null,
        foalingDate: moment(e.foalingDate, 'YYYY-MM-DD').format('DD/MM/YYYY'),
        timeformId: e.timeformId,
        ownerFullName: e.ownerFullName,
        trainerFullName: e.trainerFullName,
        searching: true,
        saleLocation: e.saleLocation,
        purchasePrice: e.purchasePrice,
        isSouthernHemisphere: e.isSouthernHemisphere,
      });
    } else {
      this.setState({ searching: false });
    }
  };

  handleChangeAutosuggestFG = input => e => {
    const { client } = this.props;
    if (e) {
      client
        .query({
          query: SEARCH_FG_HORSE,
          variables: { fgId: e.fgId },
        })
        .then(res => {
          const fgHorse = res.data.searchFGHorse;
          const hc = getCountryFromName(fgHorse.name);
          const dc = getCountryFromName(fgHorse.damName);
          const sc = getCountryFromName(fgHorse.sireName);
          const dsc = getCountryFromName(fgHorse.damSireName);
          this.setState({
            [input]: e.value,
            name: fgHorse.name,
            hc,
            damName: fgHorse.damName,
            dc,
            damSireName: fgHorse.damSireName,
            dsc,
            sireName: fgHorse.sireName,
            sc,
            gender: fgHorse.gender.trim(' '),
            colour: fgHorse.colour,
            foalingDate: moment(fgHorse.foalingDate, 'YYYY-MM-DD').format('DD/MM/YYYY'),
            fgId: fgHorse.fgId,
            ownerFullName: fgHorse.ownerFullName,
            trainerFullName: fgHorse.trainerFullName,
            searching: true,
            saleLocation: e.saleLocation,
            purchasePrice: e.purchasePrice,
            isSouthernHemisphere: e.isSouthernHemisphere,
          });
        });
    } else {
      this.setState({ searching: false });
    }
  };

  handleChangeAutosuggestUST = input => e => {
    const { client } = this.props;
    if (e) {
      client
      .query({
        query: SEARCH_UST_HORSE,
        variables: { ustId: e.ustId },
      })
      .then(res => {
        const ustHorse = res.data.searchUSTHorse;
        const hc = getCountryFromName(ustHorse.name);
        const dc = getCountryFromName(ustHorse.damName);
        const sc = getCountryFromName(ustHorse.sireName);
        const dsc = getCountryFromName(ustHorse.damSireName);
        const colours = parseUSTHorseColors(ustHorse.horseColour)
        const gender = parseUSTHorseGender(ustHorse.horseGender)
        this.setState({
          [input]: e.value,
          name: ustHorse.name,
          hc,
          damName: ustHorse.damName,
          dc,
          damSireName: ustHorse.damSireName,
          dsc,
          sireName: ustHorse.sireName,
          sc,
          gender,
          colour: colours[0],
          secondColour: colours[1] ? colours[1] : null,
          foalingDate: moment(ustHorse.foalingDate).format('DD/MM/YYYY'),
          ustId: ustHorse.ustId,
          ownerFullName: ustHorse.ownerFullName,
          trainerFullName: ustHorse.trainerFullName,
          searching: true,
          saleLocation: e.saleLocation,
          purchasePrice: e.purchasePrice,
          isSouthernHemisphere: e.isSouthernHemisphere,
        });
      });
    } else {
      this.setState({ searching: false });
    }
  };

  handleChange = input => e => {
    const { colour } = this.state;
    const { value } = e.target;
    switch (input) {
      case 'name':
        this.setState(state => ({
          name: value,
          hc: value ? state.hc : '',
        }));
        break;
      case 'sireName':
        this.setState(state => ({
          sireName: value,
          sc: value ? state.sc : '',
        }));
        break;
      case 'damName':
        this.setState(state => ({
          damName: value,
          dc: value ? state.dc : '',
        }));
        break;
      case 'damSireName':
        this.setState(state => ({
          damSireName: value,
          dsc: value ? state.dsc : '',
        }));
        break;
      case 'secondColour':
        if (colour === e.target.value) {
          this.setState({ error: "It's the first horse colour, please, choose another" });
        } else {
          this.setState({ secondColour: e.target.value });
        }
        break;
      default:
        this.setState({ [input]: e.target.value });
    }
  };

  handleChangeCountry = input => e => {
    const { value } = e.target;
    const { name, damName, sireName, damSireName } = this.state;
    const parsedName = name.split(' (')[0];
    const parsedSireName = sireName.split(' (')[0];
    const parsedDamName = damName.split(' (')[0];
    const parsedDamSireName = damSireName.split(' (')[0];
    switch (input) {
      case 'hc':
        this.setState({ name: name ? `${parsedName} (${value})` : '' });
        break;
      case 'sc':
        this.setState({
          sireName: sireName ? `${parsedSireName} (${value})` : '',
        });
        break;
      case 'dc':
        this.setState({
          damName: damName ? `${parsedDamName} (${value})` : '',
        });
        break;
      case 'dsc':
        this.setState({
          damSireName: damSireName ? `${parsedDamSireName} (${value})` : '',
        });
        break;
      default:
        return;
    }
    this.setState({ [input]: value });
  };

  handleChangeService = input => e => {
    this.setState({
      [input]: e.target.value,
      searchHorse: '',
      horseOptions: [],
      searching: false,
      name: '',
      gender: '',
      colour: '',
      secondColour: '',
      foalingDate: '',
      timeformId: '',
      fgId: '',
      ustId: '',
      damName: '',
      damSireName: '',
      ownerFullName: '',
      sireName: '',
      trainerFullName: '',
      stud: '',
      location: '',
      saleLocation: '',
      purchasePrice: '',
      purchaseCurrency: '',
      isSouthernHemisphere: false,
      hc: '',
      sc: '',
      dc: '',
      dsc: '',
    });
  };

  render() {
    const {
      searching,
      horseOptions,

      name,
      gender,
      colour,
      secondColour,
      foalingDate,
      timeformId,
      fgId,
      ustId,
      damName,
      damSireName,
      ownerFullName,
      sireName,
      trainerFullName,
      stud,
      location,
      saleLocation,
      purchasePrice,
      purchaseCurrency,
      isSouthernHemisphere,
      hc,
      sc,
      dc,
      dsc,

      service,

      error,
    } = this.state;
    const { classes } = this.props;
    const loc = this.props.location;

    const parsedLocation = queryString.parse(loc.search);
    const isFlow = !!parsedLocation.flow;
    return this.state.adding ? <Loader/> : (
      <form>
        <CenteredPaper>
          <PaperTitle>Add Horse to Group</PaperTitle>
          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12} sm={12}>
              <TextField
                id="standard-select-period"
                select
                label="Choose Service"
                value={service}
                className={classes.textField}
                onChange={this.handleChangeService('service')}
                style={{ margin: '0' }}
              >
                {Object.keys(services).map(key => (
                  <MenuItem value={key}>{services[key]}</MenuItem>
                ))}
              </TextField>
            </Grid>
            {service ? (
              service === 'TIMEFORM' ? (
                <Grid item xs={12} sm={12}>
                  <Autosuggest
                    options={horseOptions}
                    handleChange={this.handleChangeAutosuggest('timeformId')}
                    onInputChange={this.handleAutosuggestSearch}
                    placeholder="Search a horse on Timeform"
                  />
                </Grid>
              ) : service === 'FG' ? (
                <Grid item xs={12} sm={12}>
                  <Autosuggest
                    options={horseOptions}
                    handleChange={this.handleChangeAutosuggestFG('france-galop')}
                    onInputChange={this.handleAutosuggestSearchFG}
                    placeholder="Search a horse on France Galop"
                  />
                </Grid>
              ) : (
                <Grid item xs={12} sm={12}>
                  <Autosuggest
                    options={horseOptions}
                    handleChange={this.handleChangeAutosuggestUST('us-timeform')}
                    onInputChange={this.handleAutosuggestSearchUST}
                    placeholder="Search a horse on US Timeform"
                  />
                </Grid>
              )
            ) : null}
            <Typography
              variant="subtitle2"
              style={{
                textAlign: 'center',
                width: '100%',
                marginTop: 20,
                marginBottom: -10,
              }}
            >
              OR
            </Typography>
            <Grid item xs={6}>
              <TextField
                disabled={searching}
                required
                label="Horse Name"
                value={name}
                onChange={this.handleChange('name')}
                fullWidth
              />
            </Grid>
            <Grid item xs={6} className={classes.grid}>
              <Selector label="Horse country of origin">
                <Select
                  disabled={searching || !name}
                  value={hc}
                  onChange={this.handleChangeCountry('hc')}
                  input={<Input name="hc" />}
                >
                  {countries.map(country => (
                    <MenuItem value={country.key}>{country.label}</MenuItem>
                  ))}
                </Select>
              </Selector>
            </Grid>
            <Grid item xs={6}>
              <TextField
                disabled={searching}
                label="Dam Name"
                value={damName}
                onChange={this.handleChange('damName')}
                fullWidth
              />
            </Grid>
            <Grid item xs={6} className={classes.grid}>
              <Selector label="Dam's country of origin">
                <Select
                  disabled={searching || !damName}
                  value={dc}
                  onChange={this.handleChangeCountry('dc')}
                  input={<Input name="dc" />}
                >
                  {countries.map(country => (
                    <MenuItem value={country.key}>{country.label}</MenuItem>
                  ))}
                </Select>
              </Selector>
            </Grid>
            <Grid item xs={6}>
              <TextField
                disabled={searching}
                label="Dam Sire Name"
                value={damSireName}
                onChange={this.handleChange('damSireName')}
                fullWidth
              />
            </Grid>
            <Grid item xs={6} className={classes.grid}>
              <Selector label="Dam Sire's country of origin">
                <Select
                  disabled={searching || !damSireName}
                  value={dsc}
                  onChange={this.handleChangeCountry('dsc')}
                  input={<Input name="dsc" />}
                >
                  {countries.map(country => (
                    <MenuItem value={country.key}>{country.label}</MenuItem>
                  ))}
                </Select>
              </Selector>
            </Grid>
            <Grid item xs={6}>
              <TextField
                disabled={searching}
                label="Sire Name"
                value={sireName}
                onChange={this.handleChange('sireName')}
                fullWidth
              />
            </Grid>
            <Grid item xs={6} className={classes.grid}>
              <Selector label="Sire's country of origin">
                <Select
                  disabled={searching || !sireName}
                  value={sc}
                  onChange={this.handleChangeCountry('sc')}
                  input={<Input name="sc" />}
                >
                  {countries.map(country => (
                    <MenuItem value={country.key}>{country.label}</MenuItem>
                  ))}
                </Select>
              </Selector>
            </Grid>

            <Grid item xs={6}>
              <TextField
                disabled={searching}
                label="Trainer Name"
                value={trainerFullName}
                onChange={this.handleChange('trainerFullName')}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                disabled={searching}
                label="Owner Name"
                value={ownerFullName}
                onChange={this.handleChange('ownerFullName')}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                disabled={searching}
                label="Stud"
                value={stud}
                onChange={this.handleChange('stud')}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                disabled={searching}
                label="Location"
                value={location}
                onChange={this.handleChange('location')}
                fullWidth
              />
            </Grid>
            {/* 
                Horses country of origin (compulsory field with drop down validations as are on the site)

                Sire's country of origin (compulsory field with drop down validations as are on the site)

                Dam's Country of Origin (compulsory field with drop down validations as are on the site)

                Dam Sire's Country of Origin (compulsory field with drop down validations as are on the site)
            */}

            <Grid item xs={12} sm={6} className={classes.grid}>
              <Selector label="Gender">
                <Select
                  disabled={searching}
                  value={gender}
                  onChange={this.handleChange('gender')}
                  input={<Input name="gender" />}
                >
                  <MenuItem value="g">Gelding</MenuItem>
                  <MenuItem value="m">Mare</MenuItem>
                  <MenuItem value="c">Colt</MenuItem>
                  <MenuItem value="f">Filly</MenuItem>
                  <MenuItem value="b">Broodmare</MenuItem>
                  <MenuItem value="r">Rig</MenuItem>
                  <MenuItem value="h">Horse</MenuItem>
                </Select>
              </Selector>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.grid}>
              <Selector label="Colour">
                <Select
                  disabled={searching}
                  value={colour}
                  onChange={this.handleChange('colour')}
                  input={<Input name="colour" />}
                >
                  <MenuItem value="ch">Chestnut</MenuItem>
                  <MenuItem value="gr">Grey</MenuItem>
                  <MenuItem value="b">Bay</MenuItem>
                  <MenuItem value="br">Brown</MenuItem>
                  <MenuItem value="bl">Black</MenuItem>
                  <MenuItem value="wh">White</MenuItem>
                  <MenuItem value="pt">Coloured</MenuItem>
                  <MenuItem value="ro">Roa</MenuItem>
                  <MenuItem value="dkb">Dark Bay</MenuItem>
                  <MenuItem value="pal">Palomino</MenuItem>
                </Select>
              </Selector>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.grid}>
              <Selector label="Second Colour">
                <Select
                  disabled={searching}
                  value={secondColour}
                  onChange={this.handleChange('secondColour')}
                  input={<Input name="secondColour" />}
                >
                  <MenuItem value={null}>No colour</MenuItem>
                  <MenuItem value="ch">Chestnut</MenuItem>
                  <MenuItem value="gr">Grey</MenuItem>
                  <MenuItem value="b">Bay</MenuItem>
                  <MenuItem value="br">Brown</MenuItem>
                  <MenuItem value="bl">Black</MenuItem>
                  <MenuItem value="wh">White</MenuItem>
                  <MenuItem value="pt">Coloured</MenuItem>
                  <MenuItem value="ro">Roa</MenuItem>
                  <MenuItem value="dkb">Dark Bay</MenuItem>
                  <MenuItem value="pal">Palomino</MenuItem>
                </Select>
              </Selector>
            </Grid>
            <Grid item xs={6}>
              <TextField
                disabled
                label={
                  service === 'UST'
                    ? 'US Timeform ID'
                    : service === 'FG'
                    ? 'Num Sire'
                    : 'Timeform ID'
                }
                value={service === 'UST' ? ustId : service === 'FG' ? fgId : timeformId}
                onChange={this.handleChange(
                  service === 'UST' ? ustId : service === 'FG' ? fgId : timeformId,
                )}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                disabled={searching}
                label="Foaling Date (DD/MM/YYYY)"
                value={foalingDate}
                onChange={this.handleChange('foalingDate')}
                fullWidth
                InputProps={{
                  inputComponent: DateFormattedInput,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.grid}>
              <Selector label="Is this a Southern Hemisphere horse?">
                <Select
                  disabled={searching}
                  value={isSouthernHemisphere}
                  onChange={this.handleChange('isSouthernHemisphere')}
                  input={<Input name="isSouthernHemisphere" />}
                >
                  <MenuItem value={null || false}>No</MenuItem>
                  <MenuItem value={true}>Yes</MenuItem>
                </Select>
              </Selector>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Sale Location"
                value={saleLocation}
                onChange={this.handleChange('saleLocation')}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                type="number"
                label="Purchase Price"
                value={purchasePrice}
                onChange={this.handleChange('purchasePrice')}
                fullWidth
              />
            </Grid>
            <Grid item xs={2}>
              <Selector label="Currency" classes={{ formControl: classes.formControl }}>
                <Select
                  value={purchaseCurrency}
                  onChange={this.handleChange('purchaseCurrency')}
                  input={<Input name="purchaseCurrency" />}
                >
                  {Object.keys(currencies).map(key => (
                    <MenuItem value={key}>{currencies[key]}</MenuItem>
                  ))}
                </Select>
              </Selector>
            </Grid>
            {isFlow ? (
              <div className={classes.buttonsContainer}>
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.button}
                  onClick={() => this.submitHander(false)}
                >
                  Save and return to dashboard
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.button}
                  onClick={() => this.submitAndReset()}
                >
                  Save and add another horse
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.button}
                  onClick={() => this.submitHander(true)}
                >
                  Save and add member
                </Button>
              </div>
            ) : (
              <Button
                variant="outlined"
                color="primary"
                className={classes.button}
                onClick={() => this.submitHander(false)}
              >
                Add Horse
              </Button>
            )}
          </Grid>
        </CenteredPaper>
        {error ? <Notification message={error} variant="error" /> : null}
      </form>
    );
  }
}
AddHorse.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  client: PropTypes.object,
  classes: PropTypes.object,
  mutate: PropTypes.func,
};

export default graphql(ADD_HORSE)(withApollo(withRouter(withStyles(styles)(AddHorse))));
