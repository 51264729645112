import React, { useState, useEffect } from 'react';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import MaterialTable from 'material-table';
import moment from 'moment';

import {
  GET_HORSE_SERVICES,
  GET_HORSE_TIMEFORM_FUTURE_RACES,
  GET_HORSE_UST_FUTURE_RACES
} from 'queries';

function HorseFutureRaces(props) {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    props.client
      .query({
        query: GET_HORSE_SERVICES,
        variables: { id: props.id },
      })
      .then(({ data }) => {
        const isLinkedToTimeform = /\d{12}/.test(data.getHorseById.timeformId);
        // const isLinkedToFG = /\d{8}/.test(data.getHorseById.fgId);
        const isLinkedToUST = /(?=^[A-Z0-9]{8}$)(?=[A-Z]{0,}[0-9][A-Z]{0,})/.test(data.getHorseById.ustId);
        return Promise.resolve({
          isLinkedToTimeform,
          // isLinkedToFG,
          isLinkedToUST,
        })
      })
      .then(({
        isLinkedToTimeform,
        // isLinkedToFG,
        isLinkedToUST,
      }) => {
        let query;
        if (isLinkedToTimeform) query = GET_HORSE_TIMEFORM_FUTURE_RACES;
        // if (isLinkedToFG) query = GET_HORSE_FG_FUTURE_RACES;
        if (isLinkedToUST) query = GET_HORSE_UST_FUTURE_RACES;
        if (query) return props.client
          .query({ query, variables: { id: props.id },})
          .then(res => {
            if (isLinkedToTimeform) setTableData(res.data.getHorseById.futureRaces);
            // if (isLinkedToFG) setTableData(res.data.getHorseById.futureFGRaces);
            if (isLinkedToUST) setTableData(res.data.getHorseById.futureUSTRaces);
          });
      })
  }, []);

  return (
    <MaterialTable
      actions={[
        {
          icon: 'info',
          tooltip: 'Show details',
          onClick: (event, rowData) => {
            props.history.push(
              `/horses/${props.id}/view/future-races/details/${rowData.courseId}/${rowData.raceNumber}/${rowData.meetingDate}`,
            );
          },
        },
      ]}
      options={{
        columnsButton: true,
        pageSize: 10,
        showEmptyDataSourceMessage: true,
        exportButton: true,
      }}
      columns={[
        {
          title: 'Meeting Date',
          field: 'meetingDate',
          type: 'date',
          defaultSort: 'desc',
          render: rowData => `${moment(rowData.meetingDate).format('Do MMMM YYYY')}`,
        },
        {
          title: 'Start Time',
          field: 'startTimeLocalScheduled',
          render: rowData =>
            `${moment(parseInt(rowData.startTimeLocalScheduled)).format('hh:mm a')}`,
        },
        {
          title: 'Course Name',
          field: 'courseName',
        },
        {
          title: 'Distance',
          field: 'distanceText',
        },
        {
          title: 'Prize fund',
          field: 'prizeFund',
        },
        {
          title: 'Race type',
          field: 'raceType',
        },
        {
          title: 'Race number',
          field: 'raceNumber',
        },
        {
          title: 'Surface',
          field: 'surfaceName',
        },
        {
          title: 'Race Title',
          field: 'raceTitle',
        },
        {
          title: 'Draw',
          field: 'draw',
        },
        {
          title: 'Eq',
          field: 'eq',
        },
        {
          title: 'Going',
          field: 'going',
        },
      ]}
      data={tableData}
      title="Future Races"
    />
  );
}

export default withApollo(withRouter(HorseFutureRaces));
