import React from 'react';
import * as PropTypes from 'prop-types';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { useQuery } from 'react-apollo';
import { GET_ALL_HORSES_HISTORY } from 'queries';
import MaterialTable from 'material-table';
import { Grid } from '@material-ui/core';
import { mainSiteUrl } from 'shared/data';

const HorseHistory = ({ history }) => {
  const { data, loading } = useQuery(GET_ALL_HORSES_HISTORY);

  const tableData = [];
  if (data && data.getAllHorses) {
    data.getAllHorses.forEach(h => {
      const daysNum = moment(h.deletedAt || undefined).diff(moment(h.createdAt), 'days');
      tableData.push({
        ...h,
        deletedAt: h.deletedAt || moment().format('YYYY-MM-DD'),
        groupName: h.group ? h.group.name : 'Not in a group',
        membersNum: h.members.filter(m => !m.userGroup.user.isContentManager).length,
        sms: h.group ? h.group.sms : false,
        daysNum,
        daysPercent: ((daysNum / 365) * 100).toFixed(1),
        linkedByUser: h.linkedBy ? h.linkedBy.userName : '-',
        createdByUser: h.createdBy ? h.createdBy.userName : '-',
      });
    });
  }
  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <MaterialTable
        actions={[
          {
            icon: 'visibility_circle',
            tooltip: 'Show Horse',
            onClick: (event, rowData) => {
              history.push(`/horses/${rowData.id}/view/info`);
            },
          },
          {
            icon: 'group_circle',
            tooltip: 'Horse Group',
            onClick: (event, rowData) => {
              history.push(`groups/${rowData.groupId}/view/horses`);
            },
          },
          {
            icon: 'web_circle',
            tooltip: 'Open Horse page at website',
            onClick: (event, rowData) => {
              window.open(`${mainSiteUrl}/horse/${rowData.slug}`);
            },
          },
        ]}
        options={{
          actionsColumnIndex: -1,
          grouping: true,
          columnsButton: true,
          pageSize: 20,
          exportButton: true,
          exportAllData: true,
          filtering: true,
          sorting: true,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [5, 10, 20, tableData.length],
          loadingType: 'linear',
        }}
        isLoading={loading}
        columns={[
          { title: 'ID', field: 'id', type: 'numeric', defaultSort: 'desc' },
          { title: 'Name', field: 'name' },
          {
            title: 'Group Name',
            field: 'groupName',
            cellStyle: r => {
              if (r === 'Not in a group') {
                return {
                  color: 'red',
                  textTransform: 'uppercase',
                };
              }
              return undefined;
            },
          },
          {
            title: 'No. of members, ex content managers',
            field: 'membersNum',
          },
          {
            title: 'Date loaded',
            field: 'createdAt',
          },
          {
            title: 'Date finished',
            field: 'deletedAt',
          },
          {
            title: 'Breeding updates',
            field: 'breedingUpdates',
            type: 'boolean',
          },
          {
            title: 'SMS notifications',
            field: 'sms',
            type: 'boolean',
          },
          {
            title: 'Timeform Linked',
            field: 'isLinkedToTimeform',
            type: 'boolean',
          },
          {
            title: 'Date Linked To Timeform',
            field: 'linkedAt',
          },
          {
            title: 'Linked To Timeform By',
            field: 'linkedByUser',
          },
          {
            title: 'Added By',
            field: 'createdByUser',
          },
          {
            title: 'Number of days',
            field: 'daysNum',
          },
          {
            title: 'Number of days as percent',
            field: 'daysPercent',
          },
        ]}
        data={tableData}
        title="Horses"
      />
    </Grid>
  );
};

HorseHistory.propTypes = {
  history: PropTypes.object,
};

export default withRouter(HorseHistory);
