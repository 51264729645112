import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import styles from './styles';

function GroupInfo(props) {
  const {
    id,
    bhaApproved,
    existing,
    type,
    period,
    address1,
    country,
    district,
    town,
    postCode,
    membershipPayedByUser,
  } = props.data;
  const { classes } = props;
  return (
    <div className={classes.info}>
      <Typography variant="body2" gutterBottom>
        ID: {id}
      </Typography>
      <hr />
      <Typography variant="body2" gutterBottom>
        Type: {type}
      </Typography>
      <hr />
      <Typography variant="body2" gutterBottom>
        Period: {period}
      </Typography>
      <hr />
      <Typography variant="body2" gutterBottom>
        Located in {country}, {district}, {town} [{postCode}]
      </Typography>
      <hr />
      <Typography variant="body2" gutterBottom>
        Address: {address1}{' '}
      </Typography>
      <hr />
      <Typography variant="body2" gutterBottom>
        Status: {existing ? 'Existing' : 'Not Existing'}
      </Typography>
      <hr />
      <Typography variant="body2" gutterBottom>
        {bhaApproved
          ? 'Approved by British Horse Association'
          : 'Not approved by British Horse Association'}
      </Typography>
      <hr />
      <Typography variant="body2" gutterBottom>
        {membershipPayedByUser ? 'Membership paid by user' : 'Membership not paid by user'}
      </Typography>
    </div>
  );
}

export default withStyles(styles)(GroupInfo);
