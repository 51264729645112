import React from 'react';
import { Link, withRouter } from 'react-router-dom';

import * as PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExitIcon from '@material-ui/icons/ExitToApp';
import HomeIcon from '@material-ui/icons/Home';
import styles from './styles';

class AppBarDrawer extends React.Component {
  state = {
    open: false,
  };

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  logoutHandler = () => {
    localStorage.removeItem('token');
    this.props.history.push(`login`);
  };

  render() {
    const { classes, theme } = this.props;
    const { open } = this.state;
    const title = this.props.location.pathname.split('/')[1].replace(/-/i, ' ') || 'home';
    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={classNames(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar style={{ border: 'none' }} disableGutters={!this.state.open}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerOpen}
              className={classNames(classes.menuButton, {
                [classes.hide]: open,
              })}
            >
              <MenuIcon />
            </IconButton>
            <Typography style={{ textTransform: 'capitalize' }} variant="h6" color="inherit" noWrap>
              {title}
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          className={classNames(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: classNames({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
          open={open}
        >
          <div className={classes.toolbar}>
            <IconButton onClick={this.handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </div>
          <Divider />
          <List>
            <ListItem button component={Link} to="/">
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="Home" />
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem component={Link} to="/users" button>
              <ListItemIcon className={classes.icons}>
                <i style={{ padding: 4, width: '25px' }} className="fas fa-user-alt" />
              </ListItemIcon>
              <ListItemText primary="Users" />
            </ListItem>
            <ListItem button component={Link} to="/groups">
              <ListItemIcon>
                <i style={{ padding: 3, width: '25px' }} className="fas fa-users" />
              </ListItemIcon>
              <ListItemText primary="Groups" />
            </ListItem>
            <ListItem button component={Link} to="/horses">
              <ListItemIcon>
                <i style={{ padding: 4, width: '25px' }} className="fas fa-horse" />
              </ListItemIcon>
              <ListItemText primary="Horses" />
            </ListItem>
            <ListItem button component={Link} to="/organizations">
              <ListItemIcon>
                <i style={{ padding: 4, width: '25px' }} className="fas fa-sitemap" />
              </ListItemIcon>
              <ListItemText primary="Organizations" />
            </ListItem>
            <ListItem button component={Link} to="/horse-history">
              <ListItemIcon>
                <i style={{ padding: 4, width: '25px' }} className="fas fa-history" />
              </ListItemIcon>
              <ListItemText primary="History" />
            </ListItem>
            <ListItem button component={Link} to="/accounting">
              <ListItemIcon>
                <i style={{ padding: 4, width: '25px' }} className="fas fa-calculator" />
              </ListItemIcon>
              <ListItemText primary="Accounting" />
            </ListItem>
            <ListItem button component={Link} to="/deleted-horses">
              <ListItemIcon>
                <i style={{ padding: 4, width: '25px' }} className="fas fa-minus-circle" />
              </ListItemIcon>
              <ListItemText primary="Deleted Horses" />
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem button component={Link} to="/notes">
              <ListItemIcon>
                <i style={{ padding: 4, width: '25px' }} className="fas fa-sticky-note" />
              </ListItemIcon>
              <ListItemText primary="Notes" />
            </ListItem>
            <ListItem button component={Link} to="/performance/managerView">
              <ListItemIcon>
                <i style={{ padding: 4, width: '25px' }} className="fas fa-tachometer-alt" />
              </ListItemIcon>
              <ListItemText primary="Performance" />
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem button key="Logout" onClick={this.logoutHandler}>
              <ListItemIcon>
                <ExitIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
        </Drawer>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <div>{this.props.children}</div>
        </main>
      </div>
    );
  }
}

AppBarDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(withRouter(AppBarDrawer));
