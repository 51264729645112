import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';

const styles = {
  paper: {
    height: 667,
    width: 900,
    backgroundColor: 'white',
    paddingTop: 80,
  },
  root: {
    flexGrow: 1,
    marginTop: 4,
  },
  gif: {
    width: 900,
  },
};

class Tableload extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <img
            className={classes.gif}
            src="https://s3-eu-west-1.amazonaws.com/trm-koa/assets/colourised-float-logo-2.gif"
            alt="Loading"
          />
        </Paper>
      </div>
    );
  }
}

export const TableLoader = withStyles(styles)(Tableload);
