import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import { useQuery } from "react-apollo";
import { withRouter } from "react-router-dom";
import DateFnsUtils from "@date-io/date-fns";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { withStyles } from "@material-ui/core/styles";

import { GET_HORSES_PERFORMANCE } from "queries";

const styles = {
  periodContainer: {
    display: "flex",
    justifyContent: "start",
    alignItems: "end",
    marginTop: "20px",
  },

  periodContainerTitle: {
    margin: "0 10px",
  },
};

function HorseView(props) {
  const { classes } = props;
  const [tableData, setTableData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const { data, loading, error, refetch } = useQuery(GET_HORSES_PERFORMANCE, {
    variables: {
      startDate,
      endDate,
    },
  });

  useEffect(() => {
    if (data && data.getAllHorses) {
      const dataToSet = data.getAllHorses.map((h) => {
        return {
          name: h.name,
          orgName: h.group.organization.name,
          updates: h.updates.length,
          totalManagerUpdates: h.totalManagerUpdates,
          lastPostDate: h.lastManagerTrainerUpdate
            ? h.lastManagerTrainerUpdate.createdAt
            : "-",
          lastPostAuthor: h.lastManagerTrainerUpdate
            ? `${h.lastManagerTrainerUpdate.author.firstName} ${h.lastManagerTrainerUpdate.author.surname}`
            : "-",
          lastPostLikes: h.lastManagerTrainerUpdate
            ? h.lastManagerTrainerUpdate.likes.length
            : "-",
          lastPostComments: h.lastManagerTrainerUpdate
            ? h.lastManagerTrainerUpdate.comments.length
            : "-",
          lastPostFileType: h.lastManagerTrainerUpdate
            ? h.lastManagerTrainerUpdate.comments.fileType
            : "-",
        };
      });
      setTableData(dataToSet);
    }
  }, [data]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className={classes.periodContainer}>
        <p className={classes.periodContainerTitle}>Selected Period: </p>
        <DateTimePicker
          label="Start Date"
          variant="inline"
          value={startDate}
          onChange={setStartDate}
          format="yyyy/MM/dd HH:mm"
        />
        <DateTimePicker
          label="End Date"
          variant="inline"
          value={endDate}
          onChange={setEndDate}
          format="yyyy/MM/dd HH:mm"
        />
      </div>
      <MaterialTable
        columns={[
          {
            title: "Name",
            field: "name",
          },
          {
            title: "Organisation",
            field: "orgName",
          },
          {
            title: "Posts",
            field: "updates",
          },
          {
            title: "Posts in Period",
            field: "totalManagerUpdates",
          },
          {
            title: "Last Post Date",
            field: "lastPostDate",
          },
          {
            title: "Last Post Author",
            field: "lastPostAuthor",
          },
          {
            title: "Last Post Likes",
            field: "lastPostLikes",
          },
          {
            title: "Last Post Comments",
            field: "lastPostComments",
          },
          {
            title: "Last Post File Type",
            field: "lastPostFileType",
          },
        ]}
        options={{
          pageSize: 10,
          pageSizeOptions: [5, 10, 20, 50, 100],
          exportButton: true,
        }}
        title="Horse View"
        data={tableData}
      />
    </MuiPickersUtilsProvider>
  );
}

export default withStyles(styles)(HorseView);
