import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { Mutation } from 'react-apollo';
import { REMOVE_USER } from 'queries';

const styles = theme => ({
  button: {
    // background: "#142339",
    // borderColor: "#142339",
    // float: "right",
    marginLeft: 1,
    '&:hover': {
      color: '#ec5c74',
      borderRadius: 100,
      cursor: 'pointer',
    },
  },
  dialog: {
    marginLeft: 73,
  },
});

class DeleteFromGroupPopup extends React.Component {
  render() {
    const { classes, history, open, handleClose, userId, groupId } = this.props;
    return (
      <div>
        {/* <DeleteForever
          onClick={this.handleClickOpen}
          className={classes.button}
        /> */}
        <Dialog
          className={classes.dialog}
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            It seems like the user has no shares. Do you want to remove this user from this group
            completely?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You won't be able to undo it.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} autoFocus>
              Cancel
            </Button>
            <Mutation mutation={REMOVE_USER} variables={{ id: userId, groupId }}>
              {(remove, { loading, error }) => (
                <Button
                  onClick={e => {
                    e.preventDefault();
                    remove();
                    handleClose();
                    if (!loading && !error) {
                      history.push(`/groups/${groupId}/view/members`);
                    }
                  }}
                  color="secondary"
                >
                  Delete
                </Button>
              )}
            </Mutation>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(DeleteFromGroupPopup));
