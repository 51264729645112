import React from 'react';
import ReactPlayer from 'react-player';
import { Grid } from '@material-ui/core';
import { Message } from 'shared/Message';

function Video(props) {
  const { attachments, message } = props;
  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      style={{ padding: 16 }}
      spacing={16}
    >
      <Grid item xs={6}>
        <Message message={message} />
      </Grid>
      <Grid item xs={6}>
        <ReactPlayer url={attachments[0]} controls width="100%" height="67%" />
      </Grid>
    </Grid>
  );
}

export const DetailsVideo = Video;
