import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import MaterialTable from 'material-table';

import { CDforList, Notification, TableLoader } from 'shared';
import { GET_ORGANIZATION_MANAGERS, REMOVE_ORG_MANAGER } from 'queries';

class List extends Component {
  state = {
    open: false,
    id: null,
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { loading, error, getOrganizationById } = this.props.data;
    if (loading) return <TableLoader />;
    if (error) return <Notification message={error.message} variant="error" />;
    const data = getOrganizationById.managers.map(m => ({
      id: m.id,
      userId: m.user.id,
      name: `${m.user.firstName} ${m.user.surname}`,
    }));
    return (
      <>
        <CDforList
          open={this.state.open}
          title="Do you really want to remove this manager from the organisation?"
          mutationQuery={REMOVE_ORG_MANAGER}
          handleClose={this.handleClose}
          options={{ variables: { orgId: this.props.id, userId: this.state.id } }}
        />
        <MaterialTable
          actions={[
            {
              isFreeAction: true,
              icon: 'group_circle',
              tooltip: 'Add manager',
              onClick: () => {
                this.props.history.push('../addManager');
              },
            },
            {
              icon: 'delete_forever',
              tooltip: 'Remove manager from the organisation',
              onClick: (event, rowData) => {
                this.setState({ id: rowData.userId, open: true });
              },
            },
          ]}
          options={{
            columnsButton: true,
            pageSize: 10,
            exportButton: true,
            exportAllData: true,
            actionsColumnIndex: -1,
            showEmptyDataSourceMessage: true,
          }}
          columns={[
            { title: 'ID', field: 'id' },
            { title: 'Name', field: 'name', defaultSort: 'asc' },
          ]}
          data={data}
          onRowClick={(e, rowData) =>
            this.props.history.push(`/users/${rowData.userId}/view/groups`)}
          title="Managers"
        />
      </>
    );
  }
}

export const OrganizationManagers = graphql(GET_ORGANIZATION_MANAGERS, {
  options: props => {
    return {
      variables: { id: props.id },
    };
  },
})(withRouter(List));
