import React from 'react';
import { useQuery } from 'react-apollo';
import { Grid } from '@material-ui/core';
import MaterialTable from 'material-table';
import { GET_DELETED_HORSES } from 'queries';
import { Loader, Notification } from 'shared';

const DeletedHorsesList = () => {
  const { data, error, loading } = useQuery(GET_DELETED_HORSES);
  if (loading) return <Loader />;
  if (error) return <Notification message={error.message} variant="error" />;
  const tableData = data.getAllDeletedHorses.map(h => ({
    ...h,
    groupName: h.group ? h.group.name : 'Not in a group',
    deletedBy: h.deletedBy ? h.deletedBy.email : '-',
    createdBy: h.createdBy ? h.createdBy.email : '-'
  }));
  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <MaterialTable
        options={{
          columnsButton: true,
          pageSize: 10,
          exportButton: true,
          exportAllData: true,
          pageSizeOptions: [5, 10, 20, tableData.length],
        }}
        columns={[
          { title: 'ID', field: 'id', type: 'numeric' },
          { title: 'Name', field: 'name' },
          {
            title: 'Group Name',
            field: 'groupName',
          },
          {
            title: 'Created',
            field: 'createdAt',
          },
          {
            title: 'Created By',
            field: 'createdBy',
          },
          {
            title: 'Deleted',
            field: 'deletedAt',
          },
          {
            title: 'Deleted By',
            field: 'deletedBy',
          },
        ]}
        data={tableData}
        title="Deleted Horses"
      />
    </Grid>
  );
};

export default DeletedHorsesList;
