import React from "react";
import MaterialTable from "material-table";
import { Paper } from "@material-ui/core";

import { CustomTablePagination } from "./TablePagination";

export const Table = ({ tableHeadings, tableData }) => {
  return (
    <MaterialTable
      style={{ boxShadow: "0", border: "none" }}
      components={{
        Container: props => <Paper {...props} elevation={0} />,
        Pagination: props => {
          return <CustomTablePagination {...props} />;
        }
      }}
      localization={{
        body: {
          emptyDataSourceMessage: (
            <p style={{ fontFamily: "Gill Sans" }}>No records to display</p>
          )
        }
      }}
      options={{
        draggable: true,
        columnsButton: false,
        pageSize: 25,
        exportButton: false,
        exportAllData: false,
        pageSizeOptions: [50, 100, 150, tableData.length],
        search: false,
        toolbar: false,
        grouping: true,
        headerStyle: {
          border: "none",
          fontFamily: "Gill Sans",
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: "16px",
          lineHeight: "19px",
          color: "#13233A",
          paddingTop: "20px"
        },
        cellStyle: {
          border: "0px",
          paddingTop: "20px",
          fontWeight: 400,
          fontFamily: "Gill Sans",
          fontStyle: "normal",
          lineHeight: "18px"
        }
      }}
      columns={tableHeadings}
      data={tableData}
    />
  );
};
